import { useEffect, useState } from 'react'

import type { TripleDotsMessageProps } from './types'

export const TripleDotsMessage = ({ children }: TripleDotsMessageProps) => {
    const [counter, setCounter] = useState(0)
    useEffect(() => {
        const intervalId = setInterval(() => setCounter((counter + 1) % 4), 200)

        return () => clearInterval(intervalId)
    }, [counter, setCounter])

    return (
        <>
            {children}
            {'.'.repeat(counter)}
        </>
    )
}
