import Color from 'colorjs.io'
import { range } from 'lodash'

import { HUE_COLS, HUE_ROWS, LUM_ROWS, SAT_COLS } from '../pages/schedule/components/ColorInput/Palette/constants'

import { mapLinearly } from './mathUtil'

export const contrastValue = (foregroundColor: Color, backgroundColor: Color) => {
    // Deliberately using APCA method here and NOT the WCAG method.
    //
    // From https://colorjs.io/docs/contrast:
    // [...] Because of its widespread use, color.js provides this [WGAC comparison] method, mainly to aid comparison.
    // Note though that it has been criticized for numerous false positive and false negative results, particularly in
    // dark mode. In a study of the legibility of white and black text against 8,000 random coloured backgrounds
    // (Waller), WCAG 2.1 performed poorly compared to APCA.
    return backgroundColor.contrast(foregroundColor, 'APCA')
}

export const bestTextColorOnBackground = (backgroundColor: Color) => {
    const whiteTextContrast = contrastValue(new Color('white'), backgroundColor)
    const blackTextContrast = contrastValue(new Color('black'), backgroundColor)

    return Math.abs(whiteTextContrast) > Math.abs(blackTextContrast) ? 'white' : 'black'
}

export const MIN_LUM = 78
export const MAX_LUM = 87

export const MIN_BW_LUM = 70
export const MAX_BW_LUM = 90

export const MIN_SAT = 35
export const MAX_SAT = 60

export const colorToHex = (color: Color) => color.to('sRGB').toString({ format: 'hex' }).replace(/^#/, '')
export const hexToColor = (hex: string) => new Color(`#${hex}`).to('hsl')

// Legacy color computation code
function hashCode(s: string) {
    let h = 0

    for (let i = 0; i < s.length; i++) {
        h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
    }

    return h
}

export function colorByType(subjectName: string): string {
    const lower = subjectName.toLowerCase()
    const hash = ((hashCode(lower) % 360) + 360) % 360

    return `hsl(${hash}, 33%, 76%)`
}

// [hue row][hue col][sat col][lum row]
export const getPalette = () =>
    range(HUE_ROWS).flatMap((hueRow) =>
        range(HUE_COLS).flatMap((hueCol) =>
            range(SAT_COLS).flatMap((satCol) =>
                range(LUM_ROWS).flatMap((lumRow) => {
                    let h, s, l
                    if (hueCol === HUE_COLS - 1 && hueRow === HUE_ROWS - 1) {
                        h = 0
                        s = 0
                        const lumSatSquare = lumRow * SAT_COLS + satCol
                        const numLumSatSquares = LUM_ROWS * SAT_COLS
                        l = mapLinearly(lumSatSquare, 0, numLumSatSquares, MIN_BW_LUM, MAX_BW_LUM)
                    } else {
                        // H
                        const numHueSquares = HUE_ROWS * HUE_COLS - 1 // -1 for gray scale
                        const hSquare = hueRow * HUE_COLS + hueCol
                        h = (hSquare / numHueSquares) * 360
                        s = mapLinearly(satCol, 0, SAT_COLS - 1, MAX_SAT, MIN_SAT)
                        l = mapLinearly(lumRow, 0, LUM_ROWS - 1, MIN_LUM, MAX_LUM)
                    }

                    const paletteColor = colorToHex(new Color('HSL', [h, s, l]))

                    return {
                        paletteColor,
                        row: hueRow * LUM_ROWS + lumRow,
                        col: hueCol * SAT_COLS + satCol
                    }
                })
            )
        )
    )
