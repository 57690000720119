import { useMergeRefs } from '@floating-ui/react';
import type { HTMLProps, ReactElement, Ref } from 'react';
import { cloneElement, forwardRef } from 'react';

import { useFloatingContext } from '../FloatingProvider';

type ReactElementWithRef = {
    ref?: Ref<unknown> | undefined;
} & ReactElement;

type FloatingTargetProps = {
    children: ReactElementWithRef;
    targetProps?: HTMLProps<HTMLElement> | undefined;
};

const FloatingTarget = forwardRef<unknown, FloatingTargetProps>(
    ({ children, targetProps }, forwardedRef) => {
        const { refs, getReferenceProps } = useFloatingContext();

        if (!refs?.setReference) {
            throw new Error('Target ref is not defined');
        }

        const mergedRefs = useMergeRefs([
            refs.setReference,
            // Merge potential existing ref on target element
            ...(children.ref ? [children.ref] : []),
            // Merge potential forwarded ref
            ...(forwardedRef ? [forwardedRef] : []),
        ]);

        // Attach the ref to the first child element
        return cloneElement(children, {
            ref: mergedRefs,
            ...getReferenceProps(targetProps),
        });
    }
);

export default FloatingTarget;
