import { IProblem, IProblemWithScore } from 'common-api'
import { TFunction } from 'i18next'

import { StudentGroupId, TeacherId } from '../../../../commonTypes'
import { ScheduleAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { isProblemRelatedToStudentGroup, ProblemSeverity, scoreSeverity } from '../../../../utils/problems'
import { isClassStudentGroup } from '../../../../utils/studentGroupUtil'

import { problemMessageText, problemTypeLabel } from './ProblemUtils'
import { ProblemListProblem } from './types'

export const formatAsPercent = (f: number) => `${(f * 100).toFixed(4)}%`

export const isVirtualProblem = (p: IProblem) => IProblem.isEndTimeProblem(p)

const getClassLabels = (problem: IProblem, schedule: ScheduleAccessor, t: TFunction) => {
    const classLabels = schedule
        .getStudentGroups()
        .filter(isClassStudentGroup)
        .filter((csg) => isProblemRelatedToStudentGroup(schedule, problem, csg))
        .map((csg) => csg.getDisplayName())

    return classLabels.length > 0 ? classLabels : [t('NoClass')]
}

export const toProblemListProblem = (
    schedule: ScheduleAccessor,
    pws: IProblemWithScore,
    t: TFunction
): ProblemListProblem => ({
    severity: scoreSeverity(pws.score),
    isVirtual: isVirtualProblem(pws.problem),
    messageText: problemMessageText(schedule, pws.problem, t),
    problemTypes: [problemTypeLabel(pws.problem, t)],
    klassIds: getClassLabels(pws.problem, schedule, t),
    teacherIds: []
})

export const isBlocker = (p: ProblemListProblem) => p.severity === ProblemSeverity.BLOCKER
export const isWarning = (p: ProblemListProblem) => p.severity === ProblemSeverity.WARNING

export const numBlockers = (ps: ProblemListProblem[]) => ps.filter(isBlocker).length
export const numWarnings = (ps: ProblemListProblem[]) => ps.filter(isWarning).length

// Convenience method
export const createBlocker = (
    messageText: string,
    isVirtual = false,
    problemTypes: string[] = [],
    klassIds: StudentGroupId[] = [],
    teacherIds: TeacherId[] = []
) => ({
    severity: ProblemSeverity.BLOCKER,
    isVirtual,
    messageText,
    problemTypes,
    klassIds,
    teacherIds
})
