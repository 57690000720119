import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'

import type * as blockedTimeControlActions from './actions'
import { BlockedTimeControlActionTypes } from './types'

export const startBlockedTimeSelection = () => action(BlockedTimeControlActionTypes.START_BLOCKED_TIME_SELECTION)
export const cancelBlockedTimeSelection = () => action(BlockedTimeControlActionTypes.CANCEL_BLOCKED_TIME_SELECTION)

export type BlockedTimeControlAction = ActionType<typeof blockedTimeControlActions>
