/** Constant reference to `undefined` that we expect to get minified and therefore reduce total code size */
var __undefined = undefined;
var AutoSchedulingJobManagementService = /** @class */ (function () {
    function AutoSchedulingJobManagementService(bridge) {
        this.bridge = bridge;
    }
    AutoSchedulingJobManagementService.prototype.getAutoSchedulerState = function (scheduleId) {
        return this.bridge.call("AutoSchedulingJobManagementService", "getAutoSchedulerState", "GET", "/schedule/{scheduleId}/autoSchedulerState", __undefined, __undefined, __undefined, [
            scheduleId,
        ], __undefined, __undefined);
    };
    AutoSchedulingJobManagementService.prototype.setAutoSchedulerState = function (scheduleId, autoSchedulerState) {
        return this.bridge.call("AutoSchedulingJobManagementService", "setAutoSchedulerState", "PUT", "/schedule/{scheduleId}/autoSchedulerState", autoSchedulerState, __undefined, __undefined, [
            scheduleId,
        ], __undefined, __undefined);
    };
    return AutoSchedulingJobManagementService;
}());
export { AutoSchedulingJobManagementService };
