import { TimePicker } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'

import { dayjsFromTimeOfDay, timeOfDayFromDayjs } from '../../../utils/DayAndTimeUtil'

import type { OpeningHoursInputProps } from './types'

const { RangePicker } = TimePicker

const defaultStartValue = dayjs().set('hour', 8).set('minute', 0)
const defaultEndValue = dayjs().set('hour', 17).set('minute', 0)

export const OpeningHoursInput = ({ onChange, value }: OpeningHoursInputProps) => {
    const onChangeWrapper = ([newStartTime, newEndTime]: [Dayjs | null, Dayjs | null]) => {
        if (typeof onChange === 'function') {
            onChange({
                startTime: timeOfDayFromDayjs(dayjs(newStartTime)),
                endTime: timeOfDayFromDayjs(dayjs(newEndTime))
            })
        }
    }

    return (
        <RangePicker
            format="HH:mm"
            hideDisabledOptions
            disabledTime={() => ({
                disabledHours: () => [0, 1, 2, 3, 4, 5, 19, 20, 21, 22, 23]
            })}
            style={{ width: '13em' }}
            minuteStep={5}
            value={
                value === undefined
                    ? [defaultStartValue, defaultEndValue]
                    : [dayjsFromTimeOfDay(value.startTime), dayjsFromTimeOfDay(value.endTime)]
            }
            onChange={(newValue) => {
                onChangeWrapper(newValue === null ? [null, null] : newValue)
            }}
        />
    )
}
