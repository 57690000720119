import { Flex, Select } from 'antd'
import { range, without } from 'lodash'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WeekSelectionPresetId } from '../../commonTypes'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { IncludeExcludeMenuButton } from './IncludeExcludeMenuButton'
import { IncludeExcludeState } from './IncludeExcludeMenuButton/types'
import { WeekSelectionInputProps } from './types'
import { WeekExceptionSelect } from './WeekExceptionSelect'

const { Option } = Select

export const WeekSelectionInput2 = ({ value, onChange }: WeekSelectionInputProps) => {
    const { t } = useTranslation()
    const includes = value?.includes || []
    const excludes = value?.excludes || []
    const [showIncludes, setShowIncludes] = useState(includes.length > 0)
    const [showExcludes, setShowExcludes] = useState(excludes.length > 0)
    const schedule = useLocalSchedule()

    const weekSelectionPresetId =
        value?.weekSelectionPresetId || schedule.getWeekSelectionPresets()[0].weekSelectionPresetId

    const onChangeWrapper = (
        newWeekSelectionPresetId: WeekSelectionPresetId = weekSelectionPresetId,
        newIncludes: number[] = includes,
        newExcludes: number[] = excludes
    ): void => {
        if (typeof onChange === 'function') {
            onChange({
                weekSelectionPresetId: newWeekSelectionPresetId,
                includes: newIncludes,
                excludes: newExcludes
            })
        }
    }

    const inPreset = schedule.findWeekSelectionPreset(weekSelectionPresetId).weeks.toSorted((w1, w2) => w1 - w2)

    const onIncludeExcludeChange = (newVal: IncludeExcludeState) => {
        setShowIncludes(newVal.includesVisible)
        if (!newVal.includesVisible) {
            onChangeWrapper(undefined, [], undefined)
        }

        setShowExcludes(newVal.excludesVisible)
        if (!newVal.excludesVisible) {
            onChangeWrapper(undefined, undefined, [])
        }
    }

    const presetSelect = (
        <Flex style={{ width: '100%' }} gap={8}>
            <Select
                onChange={(value) => {
                    setShowIncludes(false)
                    setShowExcludes(false)
                    onChangeWrapper(value, [], [])
                }}
                value={weekSelectionPresetId}
            >
                {schedule.getWeekSelectionPresets().map((preset) => (
                    <Option key={preset.weekSelectionPresetId}>{preset.displayName}</Option>
                ))}
            </Select>
            <IncludeExcludeMenuButton
                onChange={onIncludeExcludeChange}
                value={{ includesVisible: showIncludes, excludesVisible: showExcludes }}
            />
        </Flex>
    )

    const excludesInput = (
        <WeekExceptionSelect
            selectedWeeks={excludes}
            weekOptions={inPreset}
            onChange={(newExcludes) => onChangeWrapper(undefined, undefined, newExcludes)}
        />
    )

    const includesInput = (
        <WeekExceptionSelect
            selectedWeeks={includes}
            weekOptions={without(range(1, 52), ...inPreset)}
            onChange={(newIncludes) => onChangeWrapper(undefined, newIncludes, undefined)}
        />
    )

    const includeExclude = (label: string, input: ReactNode) => (
        <Flex style={{ alignItems: 'center', gap: 8, width: '100%' }}>
            <div>...{label}</div>
            <div style={{ flex: 1 }}>{input}</div>
        </Flex>
    )

    return (
        <Flex style={{ width: '100%', alignItems: 'center', gap: 8 }} vertical>
            {presetSelect}
            {showExcludes && includeExclude(t('Except'), excludesInput)}
            {showIncludes && includeExclude(t('With'), includesInput)}
        </Flex>
    )
}
