import { Global } from '@emotion/core'
import { ConfigProvider, Result } from 'antd'
import { StrictMode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { Navigate } from 'react-router'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import type { Store } from 'redux'

import AlertProvider from './components/AlertProvider'
import { Root } from './components/layout/Root'
import { CanteenLoadIndexPage } from './pages/canteen-load'
import CourseRoundsIndexPage from './pages/course-rounds'
import CoursesIndexPage from './pages/courses'
import CoursesEditTable from './pages/courses/components/CoursesTable/CoursesEditTable'
import ImportExportIndexPage from './pages/import-export'
import { LecturesIndexPage } from './pages/lectures'
import { PeriodsIndexPage } from './pages/periods'
import { RoomsIndexPage } from './pages/rooms'
import RoomsEditTable from './pages/rooms/RoomsTable/RoomsEditTable'
import { RoyalScheduleImportIndexPage } from './pages/rs-import'
import ScheduleIndexPage from './pages/schedule'
import { ScheduleParametersIndexPage } from './pages/schedule-parameters'
import StudentGroupsIndexPage from './pages/student-groups'
import StudentGroupsEditTable from './pages/student-groups/components/StudentGroupTable/StudentGroupEditTable'
import SubjectsIndexPage from './pages/subjects'
import SubjectsEditTable from './pages/subjects/components/SubjectsTable/SubjectsEditTable'
import { TeachersPage } from './pages/teachers'
import { TeacherEditTable } from './pages/teachers/components/TeachersTable/TeacherEditTable'
import TeachingAssignments from './pages/teaching-assignments'
import './sentry'
import type { ApplicationState } from './store'
import { antTweaks } from './styles/ant-tweaks'
import './styles/reset.css'

interface MainProps {
    store: Store<ApplicationState>
}

export const Main = ({ store }: MainProps) => {
    const { t } = useTranslation()

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <Result title={t('Errors.TryAgain')} subTitle={t('Errors.PleaseReload')} />,
            children: [
                { index: true, element: <Navigate to="/schedule" replace /> },
                { path: 'schedule', element: <ScheduleIndexPage /> },
                { path: 'teaching-assignments', element: <TeachingAssignments /> },
                {
                    path: 'student-groups',
                    element: <StudentGroupsIndexPage />,
                    children: [{ path: 'edit', element: <StudentGroupsEditTable /> }]
                },

                { path: 'student-groups', element: <StudentGroupsIndexPage /> },
                {
                    path: 'teachers',
                    element: <TeachersPage />,
                    children: [{ path: 'edit', element: <TeacherEditTable /> }]
                },
                { path: 'periods', element: <PeriodsIndexPage /> },
                {
                    path: 'subjects',
                    element: <SubjectsIndexPage />,
                    children: [{ path: 'edit', element: <SubjectsEditTable /> }]
                },
                {
                    path: 'courses',
                    element: <CoursesIndexPage />,
                    children: [{ path: 'edit', element: <CoursesEditTable /> }]
                },
                { path: 'course-rounds', element: <CourseRoundsIndexPage /> },
                { path: 'lectures', element: <LecturesIndexPage /> },
                {
                    path: 'rooms',
                    element: <RoomsIndexPage />,
                    children: [{ path: 'edit', element: <RoomsEditTable /> }]
                },
                { path: 'reports/canteen-load', element: <CanteenLoadIndexPage /> },
                { path: 'schedule-parameters', element: <ScheduleParametersIndexPage /> },
                { path: 'import-export', element: <ImportExportIndexPage /> },
                { path: 'rs-import', element: <RoyalScheduleImportIndexPage /> }
            ]
        }
    ])

    return (
        <StrictMode>
            <Provider store={store}>
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: 'Manrope',
                            colorPrimary: '#545eb6',
                            colorInfo: '#545eb6',
                            colorSuccess: '#00762a',
                            colorWarning: '#ff9a2c',
                            colorError: '#ce001c',
                            green: 'red'
                        }
                    }}
                >
                    <AlertProvider>
                        <DndProvider backend={HTML5Backend}>
                            <Global styles={antTweaks} />
                            <RouterProvider router={router} />
                        </DndProvider>
                    </AlertProvider>
                </ConfigProvider>
            </Provider>
        </StrictMode>
    )
}
