import { useRef, useEffect } from 'react'

export default function useResizeObserver(callback: (val: ResizeObserverEntry) => void) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const resizeObserver = new ResizeObserver((entries) => {
            callback(entries[0])
        })

        resizeObserver.observe(ref.current)

        return () => {
            resizeObserver.disconnect()
        }
    }, [callback])

    return ref
}
