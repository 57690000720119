import { Button, Result, Spin } from 'antd'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import type { ApplicationState } from '../store'
import { initLogin } from '../store/auth/actions'
import type { AuthState } from '../store/auth/types'
import {
    isAuthenticated,
    isAuthenticatedWithoutOrganization,
    isAuthenticatedWithoutSchedulerRole,
    isUnauthenticated,
    isUserKickedOut
} from '../store/auth/types'
import { AppError } from '../utils/errorutil'

export const AuthenticatedOnly = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const authState = useSelector<ApplicationState, AuthState>((state) => state.authState)

    useEffect(() => {
        dispatch(initLogin())
    }, [dispatch])

    // Authenticated
    if (isUnauthenticated(authState)) {
        return <Result status="warning" icon={<Spin />} title={t('Authenticating')} />
    }

    // Authenticated without organization?
    if (isAuthenticatedWithoutOrganization(authState)) {
        return <Result status="warning" title={t('NoProfileSelected')} />
    }

    // No SCHEDULER role?
    if (isAuthenticatedWithoutSchedulerRole(authState)) {
        return <Result status="warning" title={t('NoSchedulerRole')} />
    }

    // Authenticated
    if (isAuthenticated(authState)) {
        return children
    }

    // Session expired
    if (isUserKickedOut(authState)) {
        return (
            <Result
                status="warning"
                title={t('SessionExpired')}
                extra={
                    <Button type="primary" onClick={() => dispatch(initLogin())}>
                        {t('LogIn')}
                    </Button>
                }
            />
        )
    }

    // Apparently we've missed some case
    throw new AppError('Unexpected auth state.', { authState })
}
