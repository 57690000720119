import { Alert, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { MAX_LISTING_CLAUSES, textualListing } from '../../../textUtil'

import classes from './style.module.css'
import { BackgroundChangesAlertProps } from './types'

export const BackgroundChangesAlert = <T,>({
    diff,
    changedData,
    DiffPopupComponent,
    namesList
}: BackgroundChangesAlertProps<T>) => {
    const displayNames = textualListing(namesList, 'sal')
    const { t } = useTranslation()
    const changeParts = [
        ...(diff.updated.length !== 0 ? [t('BackgroundChanges.Updated')] : []),
        ...(diff.created.length !== 0 ? [t('BackgroundChanges.Added')] : []),
        ...(diff.deleted.length !== 0 ? [t('BackgroundChanges.Removed')] : [])
    ]
    const changeVerbsStr = textualListing(changeParts, '', t('BackgroundChanges.Or'))

    // We only want to show a tooltip that describes the details of the changes if there's a need for it. If the entire
    // list of teachers is spelled out, and there are only additions or only deletions, the sentence is sufficiently
    // descriptive on its own.
    const showDescriptionTooltip =
        changeParts.length >= 2 || diff.updated.length !== 0 || changedData.length > MAX_LISTING_CLAUSES

    const changeVerbs = showDescriptionTooltip ? (
        <Tooltip title={DiffPopupComponent} rootClassName={classes.updateDescriptionTooltip}>
            <span style={{ textDecoration: 'underline black dashed' }}>{changeVerbsStr}</span>
        </Tooltip>
    ) : (
        changeVerbsStr
    )

    const description = (
        <>
            {`${displayNames} ${t('BackgroundChanges.Has')} `}
            {changeVerbs}
            {t('BackgroundChanges.SinceEditModeOpened')}
        </>
    )

    return (
        <Alert
            type="warning"
            message={t('BackgroundChanges.BackgroundUpdatesTitle')}
            description={description}
            showIcon
            banner
        />
    )
}
