import { useTranslation } from 'react-i18next'

import type { ReservedTimeId, RoomId } from '../../../commonTypes'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { orThrow } from '../../../utils/collections'
import { ManyToManySelector } from '../../many-to-many-selector/ManyToManySelector'
import { ReservedTimesListTooltip } from '../ReservedTimesListTooltip'

import type { ReservedTimeRoomSelectorProps } from './types'

export const ReservedTimeRoomSelector = ({ value, onChange }: ReservedTimeRoomSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const namesByRoomId = new Map(schedule.getRooms().map((r) => [r.getRoomId(), r.getName()]))

    return (
        <ManyToManySelector<ReservedTimeId, RoomId>
            value={value}
            onChange={onChange}
            mode="multiple"
            preexistingOptionValues={schedule.getRooms().map((r) => r.getRoomId())}
            optionLabelFn={(rid) => orThrow(namesByRoomId.get(rid))}
            partialCoverSuffix={(reservedTimeIds) => (
                <ReservedTimesListTooltip
                    title={t('ThisRoomOnlyApplysToTheFollowingReservedTimes')}
                    reservedTimes={reservedTimeIds.map((reservedTimeId) => schedule.findReservedTime(reservedTimeId))}
                />
            )}
        />
    )
}
