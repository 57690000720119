import { Cell, Row } from '@silevis/reactgrid'
import { Flex } from 'antd'
import { ISubject } from 'common-api'
import { merge } from 'lodash'
import { Dispatch, SetStateAction, useRef } from 'react'

import EditTable from '../../../../../EditTable'
import { BackgroundChangesAlert } from '../../../../../EditTable/components/BackgroundChangesAlert'
import {
    DEFAULT_CONTENT_CELL,
    DEFAULT_HEADER_CELL,
    HEADER_ROW,
    MIN_ROWS_TO_SHOW
} from '../../../../../EditTable/constants'
import { useFocusedCell } from '../../../../../EditTable/hooks'
import { anyDiff, diff, generateEmptyRows, getIndexCell, handleClearTableContent } from '../../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { comparing } from '../../../../../utils/compareUtil'
import { GridActionsFooter } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/GridActionsFooter'
import { handleCopy } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handleCopy'
import { handlePaste } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handlePaste'

import SimpleSubjectListDiff from './components/SimpleSubjectListDiff'
import { parseSubjectRows, subjectCmp } from './data'
import { useSubjectGridColumns } from './hooks'
import { ALL_CONTENT_COLUMNS, SubjectGridMetaDataColumn, SubjectGridRow } from './types'
import { conjureSubjectsFromSubjects, generateEmptySubjectRow } from './utils'

type SubjectsEditTableProps = {
    subjects: SubjectGridRow[]
    setSubjects: Dispatch<SetStateAction<SubjectGridRow[]>>
    onReset: () => void
    onSave: () => void

    subjectsWhenEditingStarted: ISubject[]
}

export default function SubjectsEditTable({
    subjects,
    setSubjects,
    onReset,
    onSave,
    subjectsWhenEditingStarted
}: SubjectsEditTableProps) {
    const reactGridWrapper = useRef<HTMLDivElement>(null)

    const columns = useSubjectGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())

    const [focusedCell, setFocusedCell] = useFocusedCell()

    const backgroundUpdates = diff<ISubject>(subjectsWhenEditingStarted, liveSubjects, (s) => s.subjectId, subjectCmp)

    const parsedFromCurrentEditTable = parseSubjectRows(liveSubjects, subjects)
    const changesMade = anyDiff(diff(parsedFromCurrentEditTable, liveSubjects, (s) => s.subjectId, subjectCmp))

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - subjects.length)
    const newEmptyRows = generateEmptyRows(subjects.length, emptyRowsToAdd, generateEmptySubjectRow)
    const subjectGridData = [...subjects, ...newEmptyRows]

    const rows = [
        HEADER_ROW,
        ...subjectGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        const fromIndex = subjects.length
        const withEmptyRows = generateEmptyRows(fromIndex, numRows, generateEmptySubjectRow)

        setSubjects([...subjectGridData, ...withEmptyRows])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    //const validationProblems = subjectCellValidationErrors(subjectGridData)

    subjectGridData.forEach((subjectRow, subjectIndex) => {
        const rowIndex = subjectIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === SubjectGridMetaDataColumn.RowIndex) {
                cells.push(getIndexCell(rowIndex))
            } else {
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: subjectRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newSubjectGridData = [...subjectGridData]
                                    newSubjectGridData[subjectIndex][gridColumn] = newText
                                    setSubjects(newSubjectGridData)
                                },
                                validationProblems: []
                            }
                        },
                        {}
                    )
                )
            }
        })
    })

    const changedData = [
        ...backgroundUpdates.updated.map(([prev]) => prev),
        ...backgroundUpdates.created,
        ...backgroundUpdates.deleted
    ]

    return (
        <Flex vertical style={{ height: '100%' }} ref={reactGridWrapper}>
            <EditTable
                onAddMoreRows={onAddMoreRows}
                onCellFocused={setFocusedCell}
                rows={rows}
                cells={cells}
                columns={columns}
                onCopy={handleCopy}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "subject data coordinates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }

                    const newSubjects = handlePaste<SubjectGridRow>({
                        columns: ALL_CONTENT_COLUMNS,
                        currentRows: subjectGridData,
                        event: e,
                        generateEmptyRow: (dstRowIndex) => generateEmptySubjectRow(dstRowIndex),
                        indexLocation: targetCell
                    })

                    setSubjects(newSubjects)
                }}
            />
            {anyDiff(backgroundUpdates) ? (
                <BackgroundChangesAlert
                    diff={backgroundUpdates}
                    changedData={changedData}
                    DiffPopupComponent={<SimpleSubjectListDiff diff={backgroundUpdates} />}
                    namesList={changedData.map((subject) => subject.name).toSorted(comparing((n) => n.length))}
                />
            ) : null}
            <GridActionsFooter
                onReset={onReset}
                onSave={onSave}
                saveEnabled={changesMade}
                handleClearTableContent={() => {
                    handleClearTableContent({
                        rows: subjectGridData,
                        generateEmptyRow: generateEmptySubjectRow,
                        setData: setSubjects
                    })
                }}
            />
        </Flex>
    )
}
