import { Select, Tag, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { FilteringState } from '../../pages/schedule/components/FilterInput/types'
import SearchPopupList from '../SearchPopupList'

import classes from './style.module.css'

type SearchPopupProps = {
    onSearch: (searchText: string) => void
    filtering: FilteringState
    value: string[]
    onChangeValue?: (value: string[]) => void
    options: {
        label: string
        options: {
            value: string
            label: string
            displayLabel?: string
        }[]
    }[]
    onSelect?: (value: string, multiSelect: boolean) => void
    onDeselect?: (value: string, multiSelect: boolean) => void
    dropdownOpen: boolean
    onDropdownVisibleChange: (open: boolean) => void
}

function SearchPopup({
    onSearch,
    value,
    options,
    onChangeValue = () => {},
    filtering,
    onDeselect = () => {},
    onSelect = () => {},
    dropdownOpen,
    onDropdownVisibleChange
}: SearchPopupProps) {
    const { t } = useTranslation()

    return (
        <Select
            showSearch
            style={{ width: 'auto', flex: 1 }}
            placeholder={t('Search')}
            value={value}
            options={options}
            open={dropdownOpen}
            maxTagCount={5}
            mode="multiple"
            onSearch={onSearch}
            onSelect={(value) => {
                onSelect(value, false)
            }}
            onDeselect={(value) => {
                onDeselect(value, false)
            }}
            searchValue={filtering.filterText}
            onDropdownVisibleChange={(open) => {
                onSearch('')
                onDropdownVisibleChange(open)
            }}
            onChange={onChangeValue}
            tagRender={(props) => {
                const newOptions = options.find((optionGroup) =>
                    optionGroup.options.find((o2) => o2.value === props.value)
                )

                const option = newOptions?.options.find((optionGroup) => optionGroup.value === props.value)

                const tooltipLabel = option?.displayLabel

                return (
                    <Tooltip title={tooltipLabel}>
                        <Tag {...props} bordered={false} className={classes.tag}>
                            {props.label}
                        </Tag>
                    </Tooltip>
                )
            }}
            dropdownRender={() => (
                <div className={classes.searchPopup}>
                    {options.map((option) => (
                        <SearchPopupList
                            options={option.options}
                            currentValue={value}
                            onChangeValue={onChangeValue}
                            title={option.label}
                            onDeselect={onDeselect}
                            onSelect={onSelect}
                            filtering={filtering}
                            key={option.label}
                        />
                    ))}
                </div>
            )}
        />
    )
}

export default SearchPopup
