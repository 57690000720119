import { ITeacher } from 'common-api'
import { useTranslation } from 'react-i18next'

import { ChangeList } from '../../../../../../../EditTable/components/ChangeList'
import { DiffResult } from '../../../../../../../EditTable/utils'
import { teacherDisplayName } from '../util'

export type SimpleTeacherListDiffProps = {
    diff: DiffResult<ITeacher>
}

const propertyDiffLi = (label: string, oldValue: string, newValue: string) =>
    oldValue !== newValue && (
        <li>
            <span style={{ textWrap: 'nowrap' }}>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </span>
        </li>
    )

export const SimpleTeacherListDiff = ({ diff }: SimpleTeacherListDiffProps) => {
    const { t } = useTranslation()

    const hasDeletions = diff.deleted.length > 0
    const hasCreations = diff.created.length > 0
    const hasUpdates = diff.updated.length > 0

    // Show headers only if there's more than one section
    const showHeaders = (hasDeletions ? 1 : 0) + (hasCreations ? 1 : 0) + (hasUpdates ? 1 : 0) > 1

    return (
        <div>
            {hasCreations && (
                <ChangeList showHeader={showHeaders} header={t('TeacherListChanges.NewTeachers')}>
                    {diff.created.map((t) => (
                        <li key={t.teacherId}>{teacherDisplayName(t)}</li>
                    ))}
                </ChangeList>
            )}
            {hasUpdates && (
                <ChangeList showHeader={showHeaders} header={t('TeacherListChanges.UpdatedTeachers')}>
                    {diff.updated.map(([prev, next]) => (
                        <li key={prev.teacherId}>
                            {teacherDisplayName(prev)}
                            <ul>
                                {propertyDiffLi(t('Signature'), prev.teacherSchoolId, next.teacherSchoolId)}
                                {propertyDiffLi(t('FirstName'), prev.firstName, next.firstName)}
                                {propertyDiffLi(t('LastName'), prev.lastName, next.lastName)}
                                {propertyDiffLi(
                                    t('Employment'),
                                    `${prev.workPercentage} %`,
                                    `${next.workPercentage} %`
                                )}
                            </ul>
                        </li>
                    ))}
                </ChangeList>
            )}
            {hasDeletions && (
                <ChangeList showHeader={showHeaders} header={t('TeacherListChanges.DeletedTeachers')}>
                    {diff.deleted.map((t) => (
                        <li key={t.teacherId}>{teacherDisplayName(t)}</li>
                    ))}
                </ChangeList>
            )}
        </div>
    )
}
