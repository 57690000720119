import type { ICourse, ICourseRound, ISubject } from 'common-api'
import type { IScheduleServiceCourse } from 'meitner-api'

import type { CourseRoundAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { Endpoints } from '../../services/Endpoints'

export type CourseRoundWithDependencies = {
    courseRound: ICourseRound
    course: ICourse | undefined
    subject: ISubject
}

export const courseRoundToCourseRoundWithDependencies = (courseRound: CourseRoundAccessor) => ({
    courseRound: courseRound.getConjureObject(),
    course: courseRound.getCourse()?.getConjureObject(),
    subject: courseRound.getSubject().getConjureObject()
})

export const idForCourseRound = (crWithDeps: CourseRoundWithDependencies) =>
    crWithDeps.course?.courseId || crWithDeps.subject.subjectId

export const nameForCourseRound = (crWithDeps: CourseRoundWithDependencies) =>
    crWithDeps.course !== undefined ? crWithDeps.course.code : crWithDeps.subject.code

export const listDstCourses = async (targetScheduleId: string) =>
    Endpoints.meitnerApi
        .scheduleServiceListCourses({
            schedule_id: targetScheduleId,
            pagination: { page: 0, size: 0 }
        })
        .then((response) => response.schedule_courses)

// Translate fields common to both create and update requests
const translateCommonFields = (srcCourseRoundWithDeps: CourseRoundWithDependencies) => ({
    title: nameForCourseRound(srcCourseRoundWithDeps),
    description: '',
    external_id: idForCourseRound(srcCourseRoundWithDeps)
})

export const createDstCourse = async (
    targetScheduleId: string,
    srcCourseRoundWithCourseOrSubject: CourseRoundWithDependencies
) =>
    Endpoints.meitnerApi
        .scheduleServiceCreateCourse({
            ...translateCommonFields(srcCourseRoundWithCourseOrSubject),
            schedule_id: targetScheduleId,
            use_attendance: true,
            color: null
        })
        .then((response) => response.created.id!)

export const deleteDstCourse = async (courseId: string) =>
    Endpoints.meitnerApi.scheduleServiceDeleteCourse({
        id: courseId
    })

export const updateDstCourse = async (
    srcCourseRoundWithCourseOrSubject: CourseRoundWithDependencies,
    dstCourse: IScheduleServiceCourse
) =>
    Endpoints.meitnerApi
        .scheduleServiceUpdateCourse({
            ...dstCourse,
            ...translateCommonFields(srcCourseRoundWithCourseOrSubject)
        })
        .then((response) => response.updated.id!)
