import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { CourseRoundId } from '../../commonTypes'
import { CourseRoundDetails } from '../../components/course-rounds/CourseRoundDetails'
import TablePage from '../../components/PageContent'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { CourseRoundActionsPanel } from './components/CourseRoundActionsPanel'
import CourseRoundsTable from './components/CourseRoundsTable'

const CourseRoundsIndexPage = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<CourseRoundId[]>([])
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    // Some selected course rounds may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedCrId) => schedule.doesCourseRoundIdExist(selectedCrId))

    const selectedCourseRounds = validSelectedRowKeys.map((courseRoundId) => schedule.findCourseRound(courseRoundId))

    const detailsPanel = (
        <CourseRoundDetails
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            courseRounds={selectedCourseRounds}
        />
    )

    const actionsPanel = (
        <CourseRoundActionsPanel
            onDelete={() => setSelectedRowKeys([])}
            key={validSelectedRowKeys.join(',')}
            courseRounds={selectedCourseRounds}
        />
    )

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => setSelectedRowKeys([])}
            table={<CourseRoundsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
        />
    )
}

export default CourseRoundsIndexPage
