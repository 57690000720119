import { ExportOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { useNavigate } from 'react-router'

import { PublishHelpPanelProps } from './types'

export const PublishHelpPanel = (props: PublishHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>
                Publicera schemat gör du från Meitners adminpanel. För att föra över aktuellt schema till adminpanelen
                klickar du på <em>Överför</em> i menyn, väljer ett schema att föra över till, och klickar på överför.
            </p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    icon={<ExportOutlined />}
                    type="primary"
                    onClick={() => {
                        props.onNavigateAway()
                        navigate('/import-export')
                    }}
                >
                    Gå till Överför
                </Button>
            </Flex>
        </div>
    )
}
