import { Table } from 'antd'

import { TeacherId } from '../../../../commonTypes'
import { FilterableColumn } from '../../../../components/table-support/types'

import { TeacherRow } from './types'

type TeacherViewTableProps = {
    selectedRowKeys: TeacherId[]
    setSelectedRowKeys: (selectedRowKeys: TeacherId[]) => void
    dataSource: TeacherRow[]
    columns: FilterableColumn<TeacherRow>[]
}

export const TeacherViewTable = ({
    selectedRowKeys,
    setSelectedRowKeys,
    columns,
    dataSource
}: TeacherViewTableProps) => (
    <Table<TeacherRow>
        rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => {
                setSelectedRowKeys(newSelectedRowKeys as TeacherId[])
            }
        }}
        dataSource={dataSource}
        showSorterTooltip={false}
        columns={columns}
        size="small"
        rowKey={({ teacher }) => teacher.getTeacherId()}
        sticky
        onRow={({ teacher }) => ({
            onClick: () => {
                setSelectedRowKeys([teacher.getTeacherId()])
            }
        })}
        pagination={false}
    />
)
