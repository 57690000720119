import { CloseCircleFilled, WarningFilled } from '@ant-design/icons'
import styled from '@emotion/styled'

import brandColors from '../../../../styles/colors/brandColors'

import { formatAsPercent } from './utils'

const BarSummary = styled.summary`
    cursor: pointer;
    height: 2.5em;
    padding-top: 0.3em;
    color: #a4000f;

    padding-left: 0.5em;
    position: relative;
    z-index: 1;
`

const PercentageBar = styled.div<{ fillFactor: number; color: string; zIndex: number }>`
    position: absolute;
    background: ${(props) => props.color};
    top: 0.1em;
    left: 0;
    width: ${(props) => formatAsPercent(props.fillFactor)};
    height: 2.3em;
    z-index: ${(props) => props.zIndex};
`

const ErrorIcon = styled(CloseCircleFilled)`
    color: ${brandColors.blocker};
`

const WarningIcon = styled(WarningFilled)`
    color: ${brandColors.warning};
`

export { BarSummary, ErrorIcon, PercentageBar, WarningIcon }
