import type { TFunction } from 'i18next'

import type {
    CourseRoundAccessor,
    RoomAccessor,
    ScheduleAccessor,
    StudentGroupAccessor,
    TeacherAccessor
} from '../../../../schedule-access/scheduleAccessWrappers'
import { comparing } from '../../../../utils/compareUtil'
import { teacherDisplayName } from '../../../../utils/scheduleUtils'
import { isClassStudentGroup } from '../../../../utils/studentGroupUtil'

import type { AllOption, CourseRoundOption, RoomOption, StudentGroupOption, TeacherOption } from './types'
import {
    courseRoundOptionValue,
    findSelectedSchedule,
    roomOptionValue,
    studentGroupOptionValue,
    teacherOptionValue
} from './utils'

export function getTeacherOptions(
    schedule: ScheduleAccessor,
    currentlySelectedValues: string[],
    isUberSearch: boolean
): TeacherOption[] {
    return schedule
        .getTeachers()
        .map((t: TeacherAccessor) => ({
            value: teacherOptionValue(t.getTeacherId()),
            label: teacherDisplayName(t),
            selector: { teacherId: t.getTeacherId() },
            displayLabel: `${t.getFirstName()} ${t.getLastName()} (${t.getTeacherSchoolId()})`
        }))
        .filter((opt: TeacherOption) =>
            findSelectedSchedule({
                scheduleID: `teacher:${opt.selector.teacherId}`,
                selectedSchedules: currentlySelectedValues,
                isUberSearch
            })
        )
        .sort(comparing((opt) => opt.label))
}

export function getStudentGroupOptions(
    schedule: ScheduleAccessor,
    currentlySelectedValues: string[],
    isUberSearch: boolean
): StudentGroupOption[] {
    return schedule
        .getStudentGroups()
        .filter(isClassStudentGroup)
        .map((sg: StudentGroupAccessor) => ({
            value: studentGroupOptionValue(sg.getStudentGroupId()),
            label: sg.getDisplayName(),
            selector: { studentGroupId: sg.getStudentGroupId() }
        }))
        .filter((opt: StudentGroupOption) =>
            findSelectedSchedule({
                scheduleID: `student:${opt.selector.studentGroupId}`,
                selectedSchedules: currentlySelectedValues,
                isUberSearch
            })
        )
        .sort(comparing((opt) => opt.label))
}

export function getCourseRoundOptions(
    schedule: ScheduleAccessor,
    currentlySelectedValues: string[],
    isUberSearch: boolean
): CourseRoundOption[] {
    return schedule
        .getCourseRounds()
        .map((cr: CourseRoundAccessor) => ({
            value: courseRoundOptionValue(cr.getCourseRoundId()),
            label: cr.getDisplayName(),
            selector: { courseRoundId: cr.getCourseRoundId() }
        }))
        .filter((opt: CourseRoundOption) =>
            findSelectedSchedule({
                scheduleID: `courseRound:${opt.selector.courseRoundId}`,
                selectedSchedules: currentlySelectedValues,
                isUberSearch
            })
        )
        .sort(comparing((opt) => opt.label))
}

export function getRoomOptions(
    schedule: ScheduleAccessor,
    currentlySelectedValues: string[],
    isUberSearch: boolean
): RoomOption[] {
    return schedule
        .getRooms()
        .map((r: RoomAccessor) => ({
            value: roomOptionValue(r.getRoomId()),
            label: r.getName(),
            selector: { roomId: r.getRoomId() }
        }))
        .filter((opt: RoomOption) =>
            findSelectedSchedule({
                scheduleID: `room:${opt.selector.roomId}`,
                selectedSchedules: currentlySelectedValues,
                isUberSearch
            })
        )
        .sort(comparing((opt) => opt.label))
}

export function getAllOption(t: TFunction): AllOption {
    return {
        value: 'all',
        label: t('All'),
        selector: { markerField: true }
    }
}
