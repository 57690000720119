import { HelpPanelDivider } from '../HelpPanelDivider'

import { InlineToggleExplicitlyDoneButton } from './InlineToggleCompletionButton'
import { DataImportHelpPanelProps } from './types'

export const DataImportHelpPanel = (props: DataImportHelpPanelProps) => (
    <div>
        <p>
            Du kan importera lärare, grupper, rum etc. till Meitner Schema om du har det tillgängligt i tabellform i
            till exempel Excel.
        </p>

        {props.dataImportSubsteps.map((substep) => (
            <>
                <HelpPanelDivider />
                {substep.helpContent}
                <InlineToggleExplicitlyDoneButton stepUiItem={substep} />
            </>
        ))}
    </div>
)
