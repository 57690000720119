import { ICourse } from 'common-api'
import { useTranslation } from 'react-i18next'

import { SimpleListDiff } from '../../../../../../EditTable/components/SimpleListDiff'
import { DiffResult } from '../../../../../../EditTable/utils'

export type SimpleCourseListDiffProps = {
    diff: DiffResult<ICourse>
}

const SimpleCourseListDiff = ({ diff }: SimpleCourseListDiffProps) => {
    const { t } = useTranslation()

    return (
        <SimpleListDiff
            diff={diff}
            getDisplayName={(course) => course.name}
            getPropertyDiffs={(prev, next) => [
                {
                    label: t('Course'),
                    prevValue: prev.courseId,
                    nextValue: next.courseId
                },
                {
                    label: t('Name'),
                    prevValue: prev.name,
                    nextValue: next.name
                },
                {
                    label: t('Code'),
                    prevValue: prev.code,
                    nextValue: next.code
                },
                {
                    label: t('Subject'),
                    prevValue: prev.subjectId,
                    nextValue: next.subjectId
                }
            ]}
            translations={{
                newItems: t('CourseListChanges.NewCourses'),
                updatedItems: t('CourseListChanges.UpdatedCourses'),
                deletedItems: t('CourseListChanges.DeletedCourses')
            }}
        />
    )
}

export default SimpleCourseListDiff
