import { toTranslate } from '../../../../../../../utils/miscUtil'

import { AlreadyAssignedListProps } from './types'

export const AlreadyAssignedList = (props: AlreadyAssignedListProps) => (
    <div>
        {toTranslate('Färg tilldelad till:')}
        <ul>
            {props.alreadyAssigned.map((lbl) => (
                <li key={lbl}>{lbl}</li>
            ))}
        </ul>
    </div>
)
