import { Flex, Table } from 'antd'

import type { LectureId } from '../../../../commonTypes'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import { useIsDummySchedule, useLocalSchedule } from '../../../../store/schedule/hooks'
import { combinedCmpFn, comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { useLectureColumns } from '../../columns'
import { NewLectureButton } from '../NewLectureButton'

import { EmptyLectureTablePlaceholder } from './EmptyLectureTablePlaceholder'
import type { LectureRow, LecturesTableProps } from './types'

const LecturesTable = ({ selectedRowKeys, setSelectedRowKeys }: LecturesTableProps) => {
    const filtering = useFiltering()
    const { columns, setVisibleColumns } = useLectureColumns(filtering)
    const schedule = useLocalSchedule()
    const isDummySchedule = useIsDummySchedule()

    const dataSourceUnfiltered = schedule.getLectures().map((lecture) => ({ lecture }))
    const dataSource = dataSourceUnfiltered.filter(rowFilter(filtering, columns)).sort(
        combinedCmpFn(
            comparing(({ lecture }) => lecture.getCourseRound().getDisplayName()),
            comparing(({ lecture }) => lecture.getLectureNum())
        )
    )

    return (
        <Table<LectureRow>
            loading={isDummySchedule}
            title={() => (
                <Flex gap={8}>
                    <FilterInput {...filtering} />
                    <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    <NewLectureButton onNewLecture={(newLectureId) => setSelectedRowKeys([newLectureId])} />
                </Flex>
            )}
            bordered
            rowSelection={{
                selectedRowKeys,
                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys as LectureId[])
            }}
            dataSource={dataSource}
            showSorterTooltip={false}
            columns={columns}
            locale={{
                emptyText: <EmptyLectureTablePlaceholder nonEmptyDataSource={dataSourceUnfiltered.length > 0} />
            }}
            size="small"
            rowKey={({ lecture }) => lecture.getLectureId()}
            sticky
            onRow={({ lecture }) => ({
                onClick: () => setSelectedRowKeys([lecture.getLectureId()])
            })}
            style={{ height: '100%' }}
            pagination={false}
        />
    )
}

export default LecturesTable
