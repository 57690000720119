import { Form, Input, InputNumber, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform } from 'common-api'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import type { TeacherId } from '../../commonTypes'
import useFormProps from '../../form/hooks/useFormProps'
import type { TeacherAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { comparing } from '../../utils/compareUtil'
import Button from '../Button'

import { MultiTeacherQualificationSelector } from './MultiTeacherQualificationSelector'

type TeacherDetailsProps = {
    teacherIds: TeacherId[]
}

type TeacherFormValues = {
    teacherSchoolIds: string[]
    firstNames: string[]
    lastNames: string[]
    qualifications: Map<TeacherId, string[]>
    workPercentages: number[]
}

const formValuesFromAccessors = (teachers: TeacherAccessor[]): TeacherFormValues => {
    const values: TeacherFormValues = {
        teacherSchoolIds: [],
        firstNames: [],
        lastNames: [],
        qualifications: new Map(
            teachers.map((t) => [t.getTeacherId(), t.getQualifications().map((s) => s.getSubjectId())])
        ),
        workPercentages: []
    }

    for (const accessor of teachers) {
        const conjureObj = accessor.getConjureObject()
        values.teacherSchoolIds.push(conjureObj.teacherSchoolId)
        values.firstNames.push(conjureObj.firstName || '')
        values.lastNames.push(conjureObj.lastName || '')
        values.workPercentages.push(conjureObj.workPercentage)
    }

    return values
}

const TeacherDetails = ({ teacherIds }: TeacherDetailsProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const [form] = useForm()
    const { antFormProps, buttonProps, container } = useFormProps()

    const teachers = teacherIds.map((tid) => schedule.findTeacher(tid))
    teachers.sort(comparing((t) => t.getTeacherSchoolId()))

    const saveTeachers = (formValues: TeacherFormValues) => {
        const transforms = teachers.map((teacher, index) =>
            IScheduleTransform.teacherTransform({
                newTeacher: {
                    ...cloneDeep(teacher.getConjureObject()),
                    teacherSchoolId: formValues.teacherSchoolIds[index],
                    firstName: formValues.firstNames[index],
                    lastName: formValues.lastNames[index],
                    workPercentage: formValues.workPercentages[index],
                    qualifications: formValues.qualifications.get(teacher.getTeacherId())!
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))
        message.success(t(teachers.length === 1 ? 'TeacherSaved' : 'TeachersSaved'))
    }

    return (
        <div ref={container}>
            <Form
                form={form}
                name="teacherForm"
                initialValues={formValuesFromAccessors(teachers)}
                onFinish={saveTeachers}
                {...antFormProps}
            >
                <Form.List name="teacherSchoolIds">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('Signature') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? 24 : 5
                                }}
                                {...field}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="firstNames">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('FirstName') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? 24 : 5
                                }}
                                {...field}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="lastNames">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('LastName') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? 24 : 5
                                }}
                                {...field}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.Item label={t('Qualifications')} name="qualifications">
                    <MultiTeacherQualificationSelector />
                </Form.Item>
                <Form.List name="workPercentages">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('Employment') : ' '}
                                style={{
                                    marginBottom: index === teachers.length - 1 ? 24 : 5
                                }}
                                {...field}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        ))
                    }
                </Form.List>

                <Form.Item {...buttonProps}>
                    <Button variant="primary" type="submit" fullWidth>
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default TeacherDetails
