import { Column } from '@silevis/reactgrid'

export enum SubjectGridMetaDataColumn {
    RowIndex = 0
}

export enum SubjectGridContentColumn {
    Name = 1,
    Code = 2
}

export const ALL_CONTENT_COLUMNS = [SubjectGridContentColumn.Name, SubjectGridContentColumn.Code]

export type SubjectGridRow = Record<SubjectGridContentColumn, string> & {
    [SubjectGridMetaDataColumn.RowIndex]: number
}

const ALL_COLUMNS = [SubjectGridMetaDataColumn.RowIndex, ...ALL_CONTENT_COLUMNS]

export const NUM_SUBJECT_COLUMNS = ALL_COLUMNS.length

export type SubjectGridColumnSpec = Column & {
    title: string
    gridColumn: SubjectGridMetaDataColumn | SubjectGridContentColumn
}
