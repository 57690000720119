export const RoomsImportHelpPanel = () => (
    <>
        <h2>Rum och utrustning</h2>
        <p>
            Klicka på Rum i menyn och klicka på import-knappen längst upp till höger. I tabellen som visas klistrar du
            in datan för alla rum och övrig utrustning.
        </p>
        <p>Beskrivning av kolumnerna kommer inom kort.</p>
    </>
)
