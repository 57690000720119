import { Flex, Table } from 'antd'
import { Outlet } from 'react-router'

import type { RoomId } from '../../../commonTypes'
import TableCard from '../../../components/TableCard'
import { VisibleColumnsSelector } from '../../../components/VisibleColumnsSelector'
import EditTableLoadingSpinner from '../../../EditTable/components/EditTableLoadingSpinner'
import { TableEditModeSwitch } from '../../../EditTable/components/TableEditModeSwitch'
import { useEditModeSwitch } from '../../../EditTable/components/TableEditModeSwitch/useEditModeSwitch'
import { useIsDummySchedule, useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'
import { rowFilter } from '../../../utils/filtering'
import { FilterInput } from '../../schedule/components/FilterInput'
import { useFiltering } from '../../schedule/components/FilterInput/hooks'
import { NewRoomButton } from '../NewRoomButton'

import { useRoomColumns } from './columns'
import type { RoomRow, RoomsTableProps } from './types'

const RoomsTable = ({ selectedRowKeys, setSelectedRowKeys }: RoomsTableProps) => {
    const { isEditModeToggled } = useEditModeSwitch()
    const filtering = useFiltering()
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useRoomColumns(filtering)

    const dataSourceUnfiltered = schedule.getRooms()
    const dataSource = dataSourceUnfiltered
        .map((room) => ({ room }))
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ room }) => room.getName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableCard
            title={
                <Flex gap={8}>
                    <FilterInput {...filtering} disabled={isEditModeToggled} />
                    <VisibleColumnsSelector
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        disabled={isEditModeToggled}
                    />
                    <TableEditModeSwitch disabled={isDummySchedule} onChange={handleModeChange} />
                    <NewRoomButton
                        onNewRoom={(newRoomId) => {
                            setSelectedRowKeys([newRoomId])
                        }}
                        disabled={isEditModeToggled}
                    />
                </Flex>
            }
        >
            {isEditModeToggled ? (
                <EditTableLoadingSpinner>
                    <Outlet />
                </EditTableLoadingSpinner>
            ) : (
                <Table<RoomRow>
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys) => {
                            setSelectedRowKeys(newSelectedRowKeys as RoomId[])
                        }
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    size="small"
                    rowKey={({ room }) => room.getRoomId()}
                    onRow={({ room }) => ({
                        onClick: () => {
                            setSelectedRowKeys([room.getRoomId()])
                        }
                    })}
                    pagination={false}
                    bordered
                    sticky
                />
            )}
        </TableCard>
    )
}

export default RoomsTable
