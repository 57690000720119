import { createRoot } from 'react-dom/client'

import configureStore from './configureStore'
import { Main } from './main'
import { Endpoints } from './services/Endpoints'
import * as serviceWorker from './serviceWorker'

import './styles/design-system/design-system.css'
import './styles/design-system/typescale.css'
import './styles/design-system/variables.css'
import './styles/fonts.css'

import './translations/i18n'

const initialState = window.INITIAL_REDUX_STATE

export const store = configureStore(initialState)
Endpoints.init()

const root = document.getElementById('root')
if (root !== null) {
    createRoot(root).render(<Main store={store} />)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
