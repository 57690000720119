import { PushpinFilled } from '@ant-design/icons'

import { RoomNameAndPinProps } from './types'

export const RoomNameAndPin = (props: RoomNameAndPinProps) => (
    <>
        <span style={{ whiteSpace: 'nowrap' }}>
            {props.roomName}
            {props.pinned && ' '}
            {props.pinned && <PushpinFilled style={{ fontSize: '80%' }} />}
        </span>
    </>
)
