import { Empty } from 'antd'

import type { EmptyTablePlaceholderProps } from './types'

export const EmptyTablePlaceholder = ({ description }: EmptyTablePlaceholderProps) => (
    <Empty
        style={{ margin: '2em 0' }}
        description={<span style={{ color: 'rgb(0, 0, 0, 0.45)' }}>{description}</span>}
    />
)
