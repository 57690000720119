import { clamp } from 'lodash'
import { useSessionStorage } from 'usehooks-ts'

const SPLITTER_PERCENTAGE_KEY = 'sidePanelPercentage'
const MIN_PERCENTAGE = 20
const MAX_PERCENTAGE = 45

function usePersistedSplitter(showSidePanel: boolean) {
    const [storedSplitterPercentage] = useSessionStorage(SPLITTER_PERCENTAGE_KEY, 30)

    function onResizeEnd(sizes: number[]) {
        const [left, right] = sizes
        const percentage = (right / (left + right)) * 100
        const clampedPercentage = clamp(percentage, MIN_PERCENTAGE, MAX_PERCENTAGE)
        sessionStorage.setItem(SPLITTER_PERCENTAGE_KEY, clampedPercentage.toString())
    }

    const splitterPanelProps = {
        defaultSize: `${storedSplitterPercentage}%`,
        min: `${MIN_PERCENTAGE}%`,
        max: `${MAX_PERCENTAGE}%`,
        size: showSidePanel ? undefined : '0'
    }

    return {
        splitterPanelProps,
        onResizeEnd
    }
}

export default usePersistedSplitter
