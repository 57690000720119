import { Flex } from 'antd'

import styled from '../../../utils/styled'
import { COMPLETED_COLOR, STARTED_COLOR, TODO_COLOR } from '../constants'
import { useChecklistUiItems } from '../hooks'
import { ChecklistStepUiItem } from '../ScheduleChecklistPanel/types'

export const ScheduleChecklistProgressBar = () => {
    const checklistItems = useChecklistUiItems(() => {})

    return (
        <Flex gap={1} align="stretch" style={{ height: '.9em' }}>
            {checklistItems.map((item) => (
                <ProgressBarStepBox key={item.step} stepUiItem={item} />
            ))}
        </Flex>
    )
}

const ProgressBarStepBox = styled.div<{ stepUiItem: ChecklistStepUiItem }>`
    background-color: ${(props) =>
        props.stepUiItem.isMarkedAsCompleted
            ? COMPLETED_COLOR
            : props.stepUiItem.isStartDetected
              ? STARTED_COLOR
              : TODO_COLOR};
    flex-grow: 1;

    &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`
