import { ISubject } from 'common-api'
import i18next from 'i18next'

import { ScheduleAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { ProblemListProblem } from '../../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../../schedule/components/ProblemsList/utils'
import { TableEditValidationProblem } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/types'

import { CourseGridContentColumn, CourseGridMetaDataColumn, CourseGridRow } from './types'
import { isEmptyCoursesRow } from './utils'

const checkForDuplicateCourseCode = (courseCodes: string[], courseRow: CourseGridRow): TableEditValidationProblem[] => {
    const courseCode = courseRow[CourseGridContentColumn.Code]
    const result: TableEditValidationProblem[] = []

    const duplicateCourseCodes = courseCodes.filter((code) => code.toLowerCase() === courseCode.toLowerCase())

    if (duplicateCourseCodes.length > 1) {
        result.push({
            location: {
                rowIndex: courseRow[CourseGridMetaDataColumn.RowIndex] + 1,
                colIndex: CourseGridContentColumn.Code
            },
            problem: createBlocker(i18next.t('Errors.DuplicateCourseCode'))
        })
    }

    return result
}

export const courseCellValidationErrors = (
    courses: CourseGridRow[],
    subjects: ISubject[]
): TableEditValidationProblem[] => {
    const result = []
    const courseCodes = courses.map((course) => course[CourseGridContentColumn.Code])

    for (const courseRow of courses) {
        if (isEmptyCoursesRow(courseRow)) {
            continue
        }

        const courseCode = courseRow[CourseGridContentColumn.Code]
        const courseName = courseRow[CourseGridContentColumn.Name]
        const courseSubject = courseRow[CourseGridContentColumn.Subject]

        // Validate required fields
        if (courseCode.length === 0) {
            result.push({
                location: {
                    rowIndex: courseRow[CourseGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseGridContentColumn.Code
                },
                problem: createBlocker(i18next.t('Errors.MissingCourseCode'))
            })
        }

        if (courseName.length === 0) {
            result.push({
                location: {
                    rowIndex: courseRow[CourseGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseGridContentColumn.Name
                },
                problem: createBlocker(i18next.t('Errors.MissingCourseName'))
            })
        }

        if (courseSubject.length === 0) {
            result.push({
                location: {
                    rowIndex: courseRow[CourseGridMetaDataColumn.RowIndex] + 1,
                    colIndex: CourseGridContentColumn.Subject
                },
                problem: createBlocker(i18next.t('Errors.MissingSubject'))
            })
        }

        if (courseSubject.length > 0) {
            const subject = subjects.find((s) => s.code === courseSubject)
            if (subject === undefined) {
                result.push({
                    location: {
                        rowIndex: courseRow[CourseGridMetaDataColumn.RowIndex] + 1,
                        colIndex: CourseGridContentColumn.Subject
                    },
                    problem: createBlocker(i18next.t('Errors.SubjectNotFound'))
                })
            }
        }

        // Check for duplicates only if we have a course code
        if (courseCode.length > 0) {
            const duplicateProblems = checkForDuplicateCourseCode(courseCodes, courseRow)
            result.push(...duplicateProblems)
        }
    }

    return result
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newCourseData: CourseGridRow[],
    subjects: ISubject[]
): ProblemListProblem[] => {
    // Check for duplicate course codes
    const courseCodeCounts = new Map<string, number[]>()
    const filterOutEmptyCourses = newCourseData.filter((courseRow) => !isEmptyCoursesRow(courseRow))

    filterOutEmptyCourses.forEach((courseRow, index) => {
        const code = courseRow[CourseGridContentColumn.Code]
        if (code !== '') {
            if (!courseCodeCounts.has(code)) {
                courseCodeCounts.set(code, [])
            }

            const rows = courseCodeCounts.get(code)
            if (rows) {
                rows.push(index)
            }
        }
    })

    const duplicateCourseErrors = [...courseCodeCounts.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(() => createBlocker(i18next.t('Errors.DuplicateCourseCode')))

    const subjectErrors = filterOutEmptyCourses.filter((courseRow) => {
        const subject = subjects.find((s) => s.code === courseRow[CourseGridContentColumn.Subject])

        return subject === undefined
    })

    const subjectErrorsList = subjectErrors.map((courseRow) => {
        return createBlocker(
            i18next.t('Errors.SubjectNotFoundWithCode', { code: courseRow[CourseGridContentColumn.Subject] })
        )
    })

    return [...duplicateCourseErrors, ...subjectErrorsList]
}
