import { IRoom } from 'common-api'

import { RoomAccessor, ScheduleAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { combinedCmpFn, comparing } from '../../../../utils/compareUtil'

import { RoomGridRow, RoomGridMetaDataColumn, RoomGridContentColumn } from './types'

export const sortedRoomsFromSchedule = (schedule: ScheduleAccessor) => {
    return schedule.getRooms().toSorted(combinedCmpFn(comparing((r) => r.getName())))
}

export const conjureRoomFromRooms = (rooms: RoomAccessor[]): IRoom[] => {
    return rooms.map((room) => room.getConjureObject())
}

export const isEmptyRoomsRow = (roomRow: RoomGridRow) =>
    [RoomGridContentColumn.Name, RoomGridContentColumn.Attributes].every((col) => roomRow[col].trim() === '')

export const generateEmptyRoomRow = (rowIndex: number): RoomGridRow => {
    return {
        [RoomGridMetaDataColumn.RowIndex]: rowIndex,
        [RoomGridContentColumn.Name]: '',
        [RoomGridContentColumn.Attributes]: ''
    }
}
