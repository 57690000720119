import { DiffResult } from '../utils'

import { ChangeList } from './ChangeList'

export type PropertyDiff = {
    label: string
    prevValue: string
    nextValue: string
}

export type SimpleListDiffProps<T> = {
    diff: DiffResult<T>
    getDisplayName: (item: T) => string
    getPropertyDiffs: (prev: T, next: T) => PropertyDiff[]
    translations: {
        newItems: string
        updatedItems: string
        deletedItems: string
    }
}

const propertyDiffLi = (diff: PropertyDiff) =>
    diff.prevValue !== diff.nextValue && (
        <li>
            <span style={{ textWrap: 'nowrap' }}>
                {diff.label}: <del>{diff.prevValue}</del> ⇒ {diff.nextValue}
            </span>
        </li>
    )

export const SimpleListDiff = <T,>({
    diff,
    getDisplayName,
    getPropertyDiffs,
    translations
}: SimpleListDiffProps<T>) => {
    const hasDeletions = diff.deleted.length > 0
    const hasCreations = diff.created.length > 0
    const hasUpdates = diff.updated.length > 0

    // Show headers only if there's more than one section
    const showHeaders = (hasDeletions ? 1 : 0) + (hasCreations ? 1 : 0) + (hasUpdates ? 1 : 0) > 1

    return (
        <div>
            {hasCreations ? (
                <ChangeList showHeader={showHeaders} header={translations.newItems}>
                    {diff.created.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>{getDisplayName(item)}</li>
                    ))}
                </ChangeList>
            ) : null}
            {hasUpdates ? (
                <ChangeList showHeader={showHeaders} header={translations.updatedItems}>
                    {diff.updated.map(([prev, next], index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>
                            {getDisplayName(prev)}
                            <ul>{getPropertyDiffs(prev, next).map((diff) => propertyDiffLi(diff))}</ul>
                        </li>
                    ))}
                </ChangeList>
            ) : null}
            {hasDeletions ? (
                <ChangeList showHeader={showHeaders} header={translations.deletedItems}>
                    {diff.deleted.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>{getDisplayName(item)}</li>
                    ))}
                </ChangeList>
            ) : null}
        </div>
    )
}
