import { DownOutlined, ScissorOutlined } from '@ant-design/icons'
import { Button, Dropdown, Flex, Select, Space } from 'antd'
import { IScheduleTransform } from 'common-api'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Week } from '../../../commonTypes'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { numberCmp } from '../../../utils/compareUtil'
import { AppError } from '../../../utils/errorutil'
import { extractLectureFromSeries } from '../utils'

import type { ExtractLectureFromSeriesButtonProps } from './types'

type SelectedWeek = 'no-week-selected' | Week

export const ExtractLectureFromSeriesButton = ({ lectureIds }: ExtractLectureFromSeriesButtonProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [selectedWeek, setSelectedWeek] = useState<SelectedWeek>('no-week-selected')
    const lectures = lectureIds.map((lid) => schedule.findLecture(lid))

    const commonWeeksForLectures = lectures
        .map((l) => l.getWeekSelection().getWeeks())
        .reduce((commonWeeks, thisLecturesWeeks) => commonWeeks.filter((w) => thisLecturesWeeks.includes(w)))

    const selectOptions = [
        { value: 'no-week-selected', label: <span style={{ fontStyle: 'italic' }}>{t('SelectWeek')}</span> },
        ...commonWeeksForLectures.toSorted(numberCmp).map((w) => ({ value: w, label: <span>v. {w}</span> }))
    ]

    const handleExtractFromSeries = () => {
        if (selectedWeek === 'no-week-selected') {
            throw new AppError('Expected actual week.')
        }

        const transforms = lectures.flatMap((l) => extractLectureFromSeries(l, selectedWeek))
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))
    }

    const contentStyle: React.CSSProperties = {
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow:
            '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        padding: '12px',
        width: '12em'
    }

    return (
        <Dropdown
            trigger={['click']}
            open={isMenuOpen}
            onOpenChange={(open) => setMenuOpen(open)}
            dropdownRender={() => (
                <Flex gap={8} vertical style={contentStyle}>
                    <div>{t('SelectWeekToExtract')}</div>
                    <Select value={selectedWeek} options={selectOptions} onChange={setSelectedWeek} />
                    <Button
                        disabled={selectedWeek === 'no-week-selected'}
                        icon={<ScissorOutlined />}
                        onClick={handleExtractFromSeries}
                        type="primary"
                    >
                        {t('Extract')}
                    </Button>
                </Flex>
            )}
        >
            <Button icon={<ScissorOutlined />}>
                <Space>
                    {t('ExtractFromSeries')}
                    <DownOutlined style={{ fontSize: '90%' }} />
                </Space>
            </Button>
        </Dropdown>
    )
}
