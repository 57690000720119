import { Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import type { RoomId } from '../../commonTypes'
import useFormProps from '../../form/hooks/useFormProps'
import type { RoomAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { comparing } from '../../utils/compareUtil'
import Button from '../Button'
import { MultiRoomAttributeSelector } from '../MultiRoomAttributeSelector'

type RoomDetailsProps = {
    rooms: RoomAccessor[]
}

type RoomFormValues = {
    roomIds: RoomId[]
    names: string[]
    attributes: Map<RoomId, string[]>
}

const formValuesFromAccessors = (rooms: RoomAccessor[]): RoomFormValues => ({
    roomIds: rooms.map((r) => r.getRoomId()),
    names: rooms.map((r) => r.getName()),
    attributes: new Map(rooms.map((r) => [r.getRoomId(), r.getAttributes()]))
})

export const RoomDetails = ({ rooms }: RoomDetailsProps) => {
    const dispatch = useDispatch()
    const [form] = useForm()
    const { t } = useTranslation()

    const { antFormProps, buttonProps, container } = useFormProps()

    rooms.sort(comparing((r) => r.getName()))

    const saveRooms = (formValues: RoomFormValues) => {
        const roomTransforms = formValues.roomIds.map((roomId, index) =>
            IScheduleTransform.roomTransform({
                newRoom: {
                    roomId,
                    name: formValues.names[index],
                    attributes: formValues.attributes.get(roomId)!
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(roomTransforms)))
        message.success(t(rooms.length === 1 ? 'RoomSaved' : 'RoomsSaved'))
    }

    return (
        <div ref={container}>
            <Form
                {...antFormProps}
                form={form}
                name="teacherForm"
                initialValues={formValuesFromAccessors(rooms)}
                onFinish={saveRooms}
                colon={false}
            >
                <Form.List name="roomIds">
                    {(fields) =>
                        fields.map((field) => (
                            <Form.Item {...field} hidden>
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="names">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                {...field}
                                label={index === 0 ? t('Names') : ' '}
                                style={{
                                    marginBottom: index === rooms.length - 1 ? undefined : '5px'
                                }}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.Item label={t('Attribute')} name="attributes">
                    <MultiRoomAttributeSelector />
                </Form.Item>
                <Form.Item {...buttonProps}>
                    <Button variant="primary" type="submit" fullWidth>
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
