import styled from '@emotion/styled'
import type { IDayAndTime } from 'common-api'
import { IProblem } from 'common-api'
import type * as React from 'react'
import type { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import type { LectureAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import type { ApplicationState } from '../../../../store'
import { useDevModeState } from '../../../../store/devmode/hooks'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { addMinutes } from '../../../../utils/DayAndTimeUtil'
import { isProblemRelatedToLecture } from '../../../../utils/problems'
import { ProblemCorner } from '../ProblemCorner'
import { isVirtualProblem, toProblemListProblem } from '../ProblemsList/utils'
import { TimestampCorner } from '../TimestampCorner'

type LectureProps = React.PropsWithChildren<{
    dayAndTime?: IDayAndTime
    lecture: LectureAccessor
    onClick: MouseEventHandler<HTMLDivElement>
}>

export const Lecture = ({ dayAndTime, children, lecture, onClick }: LectureProps) => {
    const devMode = useDevModeState()
    const { t } = useTranslation()
    const schedule = useLocalSchedule()
    const isSelected = useSelector<ApplicationState, boolean>((state) =>
        state.lectureSelection.selectedLectures.includes(lecture.getLectureId())
    )

    const problemsForThisLecture = schedule
        .getSchedulingProblems()
        .filter((pws) => devMode || !isVirtualProblem(pws.problem))
        .filter((pws) => isProblemRelatedToLecture(pws.problem, lecture.getLectureId()))
        .filter((pws) => !IProblem.isUnscheduledLectureProblem(pws.problem))
        .filter((pws) => lecture.getDayAndTime() !== undefined || !IProblem.isNoRoomSetProblem(pws.problem))

    const startTime = dayAndTime
    const endTime = dayAndTime && addMinutes(dayAndTime, lecture.getDurationInMinutes())

    return (
        <LectureBackground
            color={`#${lecture.getCourseRound().getSubject().getColor()}`}
            isSelected={isSelected}
            onClick={onClick}
            style={{ overflow: 'hidden' }}
        >
            <ProblemCorner problems={problemsForThisLecture.map((pws) => toProblemListProblem(schedule, pws, t))} />
            <LectureContent>
                {startTime && (
                    <TimestampCorner
                        pos="topleft"
                        dayAndTime={startTime}
                        pinned={lecture.getEventGroup().isTimeslotPinned()}
                    />
                )}
                {children}
                {endTime && <TimestampCorner pos="bottomright" dayAndTime={endTime} />}
            </LectureContent>
        </LectureBackground>
    )
}

interface LectureBackgroundProps {
    color: string
    isSelected: boolean
}

const LectureBackground = styled.div<LectureBackgroundProps>`
    height: 100%;
    border-radius: 3px;
    background: ${({ color }) => color};
    position: relative;
    border: 1px solid ${({ isSelected }) => (isSelected ? '#1890ff' : '#00000044')};
    box-shadow: ${({ isSelected }) => (isSelected ? '0 0 0 3px #1890ff' : 'none')};
`

const LectureContent = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0.7em;
    padding: 0.5em;
    padding-top: 2.3em;
`
