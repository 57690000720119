import { FileOutlined } from '@ant-design/icons'
import { message, Space } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { ISchedule, IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { newScheduleSelectorsLoadedDueToScheduleUpdate } from '../../../store/scheduleselector/actions'
import { toTranslate } from '../../../utils/miscUtil'

export const MeitnerScheduleJsonImportPanel = () => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    return (
        <Space direction="vertical" size="large">
            <Dragger
                accept=".json"
                name="file"
                showUploadList={false}
                beforeUpload={(file) => {
                    const reader = new FileReader()

                    reader.onload = (e) => {
                        if (e.target === null) {
                            message.error(t('Errors.ImportFailed'))

                            return
                        }

                        const json = JSON.parse(e.target.result as string) as ISchedule
                        dispatch(
                            locallyTriggeredScheduleTransform(
                                IScheduleTransform.scheduleUpdateTransform({ newSchedule: json })
                            )
                        )
                        dispatch(newScheduleSelectorsLoadedDueToScheduleUpdate(schedule.getScheduleId(), []))
                        message.success(t('Success.ImportComplete'))
                    }

                    reader.readAsText(file)

                    // Prevent actual upload to server
                    return false
                }}
            >
                <p className="ant-upload-drag-icon">
                    <FileOutlined />
                </p>
                <p className="ant-upload-text">{toTranslate('Klicka här för att välja JSON-fil att ladda upp')}</p>
                <p className="ant-upload-hint">
                    {toTranslate('Den importerade filen kommer ersätta all data i aktuellt schema.')}
                </p>
            </Dragger>
        </Space>
    )
}
