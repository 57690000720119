import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { de } from './locales/de'
import { en } from './locales/en'
import { sv } from './locales/sv'

export const defaultNS = 'translation'

export const resources = {
    sv,
    en,
    de
} as const

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'sv',
        detection: {
            order: ['localStorage', 'cookie', 'navigator'],
            caches: ['localStorage', 'cookie']
        },
        defaultNS,
        ns: [defaultNS],
        interpolation: {
            escapeValue: false
        }
    })

export default i18next
