import { detectOverflow } from '@floating-ui/react';
import type { SideObject, Middleware, Padding } from '@floating-ui/react';

const getPositionWithoutOverflow = (
    xPos: number,
    yPos: number,
    overflow: SideObject,
    padding: Padding = 0
) => {
    let newX = xPos;
    let newY = yPos;

    let overflowLeft = overflow.left;
    let overflowRight = overflow.right;
    let overflowTop = overflow.top;
    let overflowBottom = overflow.bottom;

    if (typeof padding === 'number') {
        overflowLeft = overflowLeft + padding;
        overflowRight = overflowRight + padding;
        overflowTop = overflowTop + padding;
        overflowBottom = overflowBottom + padding;
    }

    if (typeof padding === 'object') {
        overflowLeft = overflowLeft + (padding.left ?? 0);
        overflowRight = overflowRight + (padding.right ?? 0);
        overflowTop = overflowTop + (padding.top ?? 0);
        overflowBottom = overflowBottom + (padding.bottom ?? 0);
    }

    if (overflowLeft > 0) {
        newX = newX + overflowLeft;
    }

    if (overflowRight > 0) {
        newX = newX - overflowRight;
    }

    if (overflowTop > 0) {
        newY = newY + overflowTop;
    }

    if (overflowBottom > 0) {
        newY = newY - overflowBottom;
    }

    return {
        x: newX,
        y: newY,
    };
};

export type PreventOverflowOptions = {
    padding?: Padding;
};

const preventOverflow = (options?: PreventOverflowOptions) => {
    return {
        name: 'overflow',
        options,
        async fn(state) {
            const overflow = await detectOverflow(state);

            const { x, y } = getPositionWithoutOverflow(
                state.x,
                state.y,
                overflow,
                options?.padding
            );

            return {
                x,
                y,
            };
        },
    } satisfies Middleware;
};

export default preventOverflow;
