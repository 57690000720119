import { DevInfoAction } from './actions'
import { DEV_MODE_KEY, DEV_MODE_VALUE } from './constants'
import { DevInfoActionTypes, DevInfoState, SseConnectionState } from './types'

const initialState: DevInfoState = {
    devMode: localStorage.getItem(DEV_MODE_KEY) === DEV_MODE_VALUE,
    scheduleSseConnectionState: SseConnectionState.CLOSED,
    autoSchedulerSseConnectionState: SseConnectionState.CLOSED
}

export const debugReducer = (state = initialState, action: DevInfoAction): DevInfoState => {
    switch (action.type) {
        case DevInfoActionTypes.ACTIVATE_DEV_MODE:
            return { ...state, devMode: true }
        case DevInfoActionTypes.DEACTIVATE_DEV_MODE:
            return { ...state, devMode: false }
        case DevInfoActionTypes.SCHEDULE_SSE_CONNECTION_STATE_UPDATE:
            return { ...state, scheduleSseConnectionState: action.payload }
        case DevInfoActionTypes.AUTO_SCHEDULER_SSE_CONNECTION_STATE_UPDATE:
            return { ...state, autoSchedulerSseConnectionState: action.payload }
        default:
            return state
    }
}
