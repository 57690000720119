export const en = {
    translation: {
        ActualHours: 'Actual number of hours',
        Add: 'Add',
        AddLecture: 'Add lecture',
        AddSetting: 'Add setting',
        AddBreakSetting: 'Add break setting',
        AddStudentGroup: 'Add student group',
        AddSchedule: 'Add schedule',
        After: 'after',
        AfterLecture: 'After lecture',
        All: 'All',
        AllCourseOfferingsHaveATeacher: 'All course offerings have a teacher assigned.',
        AllowSkipInMatching: 'Allow skip in matching',
        AllVisible: 'All visible',
        And: 'and',
        Authenticating: 'Authenticating...',
        At: 'at',
        AT: 'HT',
        Atleast: 'at least',
        Attribute: 'Attribute',
        Automation: 'Automation',
        AWeeks: 'A-weeks',
        BackgroundChanges: {
            Updated: 'been updated',
            Added: 'been added',
            Removed: 'been removed',
            Or: 'or',
            Has: 'has',
            SinceEditModeOpened: ' since edit mode was opened. Please pay extra attention during preview.',
            BackgroundUpdatesTitle: 'Updates have been made in the background'
        },
        BackToAdmin: 'Back to admin',
        Before: 'before',
        BeforeLecture: 'Before lecture',
        Between: 'between',
        BlockedTime: 'Blocked time',
        BreakHasToBe: 'Break has to be at least',
        BreakSettings: 'Break settings',
        BreakShouldPreferablyBe: '...but preferably',
        BWeeks: 'B-weeks',
        Cancel: 'Cancel',
        CanteenLoadDuringLunch: 'Canteen load during lunch',
        CaseSensitive: 'Case sensitive',
        ChooseLanguage: 'Choose language: ',
        Class: 'Class',
        Classes: 'Classes',
        CourseOfferings: 'Course Offerings',
        CourseOfferingsWithoutTeachers: 'Course offerings without teachers',
        CourseOrSubject: {
            Subjects: 'Subjects',
            Courses: 'Courses',
            Course: 'course',
            Subject: 'subject'
        },
        OneMoreStudentGroup: 'one more student group',
        RemainingStudentGroups: '{{remaining}} other student groups',
        CourseRound: {
            Saved: 'Course offering saved',
            SavedMultiple: 'Course offerings saved',
            SelectSingleToEditName: 'Select a single course offering to edit the name.',
            SelectSingleToEditCourseOrSubject: 'Select a single course offering to change subject/course.',
            SelectSingleToEditStudentGroup: 'Select a single course offering to change student group.',
            SelectSingleToEditHours: 'Select a single course offering to change hours.',
            SelectSingleToEditBreakRules: 'Select a single course offering to change break rules.',
            SelectSingleToEditLectureDurations: 'Select a single course offering to change lecture durations.',
            SubjectOrCourse: 'Subject / Course',
            Terms: 'Terms',
            SchoolYear: 'School year',
            FallTerm: 'Fall term',
            SpringTerm: 'Spring term',
            TeacherOnlyForTheseOfferings: 'This teacher is only assigned to the following course offerings: ',
            Singular: 'course offering',
            Plural: 'course offerings'
        },
        Continue: 'Continue',
        Checklist: {
            Staff: 'Staff',
            RoomsAndEquipment: 'Rooms and equipment',
            Subjects: 'Subjects',
            Courses: 'Courses',
            CourseOfferings: 'Course offerings',
            SelectBaseSettings: 'Select base settings',
            SelectBaseSettingsDesc: 'Choose school type, break rules, lecture durations etc.',
            AdjustYearDataAndPeriods: 'Adjust school year data and periods',
            AdjustYearDataAndPeriodsDesc: 'Select days when school is open and create periods such as A/B weeks.',
            ImportData: 'Import data',
            ImportDataDesc: 'Ensure that teachers, student groups, rooms etc. exist in the schedule.',
            PerformTeacherAssignments: 'Perform teacher assignments',
            PerformTeacherAssignmentsDesc: 'Select which teachers will teach which course offerings.',
            GenerateLectures: 'Generate lectures',
            GenerateLecturesDesc: 'Ensure that lectures are created for all courses.',
            ScheduleLectures: 'Schedule lectures',
            ScheduleLecturesDesc: 'Ensure that all lectures have a time and room.',
            PublishSchedule: 'Publish schedule',
            PublishScheduleDesc: 'Transfer the schedule to the admin panel and publish it.'
        },
        Filter: 'Filter',
        HighlightSearchText: 'Highlight search text',
        Rooms: 'Rooms',
        ClassHasTooShortLunch: 'Class {{Class}} has no lunch on {{Day}}',
        Code: 'Code',
        CollapseAll: 'Collapse all',
        Columns: 'Columns',
        ColumnsView: {
            SelectDaysToShow: 'Select which days to show in the schedule',
            SelectViewMode: 'Select how you want to view your selected schedules',
            PerSchedule: 'Per schedule',
            Column: 'column',
            Columns: 'columns'
        },
        CourseListChanges: {
            NewCourses: 'New courses',
            UpdatedCourses: 'Updated courses',
            DeletedCourses: 'Deleted courses',
            NoNewCourses: 'No new courses',
            NoUpdatedCourses: 'No updated courses',
            NoRemovedCourses: 'No removed courses'
        },
        Condition: 'Condition',
        Course: 'Course',
        Courses: 'Courses',
        CourseCode: 'Course code',
        CourseOffering: 'Course offering',
        CourseOfferingHaveToo: 'Course offering have too',
        CourseOfferingHaveTooFewHours: 'Course offering have too few hours: ',
        Create: 'Create',
        DayAndTime: 'Day and time',
        Description: 'Description',
        Designation: 'Designation',
        Development: 'Development',
        DevelopmentButton: {
            DumpDaysTimes: 'Dump days and times',
            RemoveAllPins: 'Remove all pins for day/time',
            RemoveNonScheduledLectures: 'Remove non-scheduled lectures',
            RemoveTimeDay: 'Remove day / time from all lectures',
            RemoveTimeDayNonPinned: 'Remove day / time from all non-pinned lectures',
            TakeDayTimeOnLecturesOutsideBlock: 'Take day / time on lectures outside block'
        },
        Duration: 'Duration',
        Employment: 'Employment',
        EvenWeeks: 'Even weeks',
        Except: 'except',
        ExpandAll: 'Expand all',
        Export: 'Export',
        EditCourseRound: 'Edit course round',
        ExportSemester: 'Export periods',
        Few: 'few',
        FirstName: 'First name',
        GroupAfterType: 'Group after type',
        GroupAfterTeacher: 'Group after teacher',
        GroupAfterClass: 'Group after class',
        HaveToBe: 'has to be',
        Hours: 'Hours',
        HoursSmall: ' hours',
        HoursInPercentOfGoal: 'Hours in percent of goal',
        InRelationToLecture: 'In relation to lecture',
        InTimePeriod: 'in time period',
        Labels: 'Labels',
        Languages: {
            Swedish: 'Swedish',
            English: 'English',
            German: 'German'
        },
        LastName: 'Last name',
        Lecture: 'Lecture',
        LectureSmall: 'lecture',
        LectureIsToo: 'Lecture is too',
        LectureDuration: 'Lecture durations',
        LectureDurationOverride: {
            InheritFrom: 'Inherit settings from {{entityType}}',
            SetSpecificFor: 'Set specific for {{entityType}}'
        },
        LectureEndsTooLate: 'Lecture ends too late:',
        Lectures: 'Lectures',
        LessonDetails: 'Lesson details',
        LessonToo: 'Lesson too',
        Long: 'long',
        LogoName: 'Schedule',
        MainMenu: {
            CanteenLoad: 'Canteen load',
            Courses: 'Courses',
            Education: 'Education',
            Rooms: 'Rooms',
            Schedule: 'Schedule',
            ServiceDistribution: 'Service distribution',
            StudentGroups: 'Student groups',
            Teachers: 'Teachers',
            TeachingGroups: 'Course Offerings',
            TeachingHours: 'Teaching hours',
            Parameters: 'Parameters',
            Periods: 'Periods',
            Lectures: 'Lectures',
            Reports: 'Reports',
            Settings: 'Settings',
            SettingsSaved: 'Settings saved',
            ShowAll: 'Show all',
            SchoolType: 'School type',
            OpeningHours: 'Opening hours',
            LunchRules: 'Lunch rules'
        },
        Many: 'many',
        MarkedBlockedTime: 'Mark blocked time',
        MarkAsDone: 'Mark as done',
        MarkAsNotDone: 'Mark as not done',
        Minutes: 'minutes',
        MinutesLongBut: 'but',
        MinutesSmall: 'minutes',
        MinimumOf: 'Minimum of',
        Name: 'Name',
        Names: 'Names',
        NoBreakSettings: 'No break settings',
        NoClass: 'No class',
        NoCondition: 'No condition',
        NoLectures: 'No lectures',
        NoLecturesMatchFilter: 'No lectures match selected filters',
        NoRoom: 'No room',
        NoRoomSelected: 'No room selected for ',
        NoLessonSelected: 'No lesson selected',
        NoSubjects: 'No subjects',
        NoStudentGroups: 'No student groups',
        NoLabels: 'No labels',
        NoRooms: 'No rooms',
        NoCourses: 'No courses',
        NoCourseRounds: 'No course rounds',
        Non: 'non ',
        NonHypen: 'non-',
        NonOverlappingStudentGroups: 'Non-overlapping student groups',
        NotPartOfBlock: 'Not part of block',
        NotScheduled: 'Not scheduled: ',
        Number: 'No.',
        NumberOfDays: 'Number of days',
        NumberOfWeeks: 'Number of weeks',
        OddWeeks: 'Odd weeks',
        Ok: 'Ok',
        Other: 'Other',
        OverlappingGroup: 'overlapping group',
        OverlappingStudentGroups: 'Overlapping student groups',
        OverlapWith: 'overlap with',
        Remove: 'Remove',
        Reset: 'Reset',
        ReservedTime: 'Reserved time',
        ReservedTimes: 'Reserved times',
        RequiredRoomAttributes: 'Required room attributes',
        RoomAttributes: 'Attributes',
        Room: 'Room',
        RoomId: 'Id - Room',
        RoomChange: 'Room change',
        RoomsListTooltip: {
            Singular: 'room',
            Plural: 'rooms'
        },
        RoomChangeSmall: 'room change',
        RoomDoubleBooked: 'Room double booked: {{roomName}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
        RoomMissingAttributes: 'Room missing attribute {{missingAttributes}}',
        RoomScheduledOnBlockedTime:
            'Room scheduled on blocked time: {{roomName}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
        SameCourseMultipleTimesOnSameDay: 'Same course multiple times on same day: ',
        SameSubjectMultipleTimesOnSameDay: 'Same subject multiple times on same day: {{subjectName}} with {{sgName}}',
        Save: 'Save',
        SchoolYear: 'School year',
        SearchCourse: 'Search course',
        SearchCourseOffering: 'Search course offering',
        SearchRoom: 'Search room',
        StudentGroupId: 'Id - Group',
        SearchPlaceholders: {
            SearchSchedule: 'Search schedule',
            SearchStudentGroup: 'Search student group',
            SearchTeacher: 'Search teacher'
        },
        Short: 'short',
        ShouldPreferablyBe: 'should preferably be',
        ShowInheritedSettings: 'Show inherited settings',
        Signature: 'Signature',
        Square: 'Square',
        ST: 'VT',
        start: 'Start',
        stop: 'Stop',
        StudentGroup: 'Student group',
        StudentGroupListChanges: {
            NewStudentGroups: 'New student groups',
            UpdatedStudentGroups: 'Updated student groups',
            DeletedStudentGroups: 'Deleted student groups',
            NoNewStudentGroups: 'No new student groups',
            NoUpdatedStudentGroups: 'No updated student groups',
            NoDeletedStudentGroups: 'No deleted student groups'
        },
        StudentGroupsDoubleBooked: 'Student groups double booked: {{sgNames}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
        Students: 'Students',
        StudentsHasTooShortBreakOnDay: 'Students in {{sgName}} have too short break between lectures on {{day}}',
        Subject: 'Subject',
        SubjectChange: 'Subject change',
        SubjectChangeSmall: 'subject change',
        SubjectId: 'Id - Subject',
        SubjectListChanges: {
            NewSubjects: 'New subjects',
            UpdatedSubjects: 'Updated subjects',
            DeletedSubjects: 'Deleted subjects',
            NoNewSubjects: 'No new subjects',
            NoUpdatedSubjects: 'No updated subjects',
            NoDeletedSubjects: 'No deleted subjects'
        },
        Teacher: 'Teacher',
        Teachers: 'Teachers',
        TeacherDoubleBooked: 'Teacher double booked: {{teacherName}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
        TeacherFrameTimeExceeded: 'Teacher frame time exceeded: {{teacherSchoolId}} has {{frameTime}}',
        TeachingGroup: 'Course offering',
        TeachingGroups: 'Course offerings',
        TeacherHasNoLunchBreak: 'Teacher has no lunch break: {{teacherSchoolId}} on {{dayName}}',
        TeacherHasTooShortBreak: '{{teacherId}} has too short break between lectures',
        TeacherHasTooShortBreakOnDay: '{{teacherId}} has too short break between lectures on {{day}}',
        TeacherScheduledOnBlockedTime:
            'Teacher scheduled on blocked time: {{teacherSchoolId}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
        TheCourseOffering: 'the course offering',
        TheGroup: 'the group',
        TheGroups: 'the groups',
        ThisAttributeOnlyApplysToTheFollowingRooms: 'This attribute only applies to the following rooms: ',
        ThisAttributeOnlyApplysToTheFollowingLectures: 'This attribute only applys to the following lessons: ',
        ThisBlockOnlyApplysToTheFollowingLessons: 'This block only applys to the following lessons: ',
        ThisGroupOnlyOverlapsWithTheFollowingGroups: 'This group only overlaps with the following groups: ',
        ThisLabelOnlyApplysToTheFollowingGroups: 'This label only applys to the following groups: ',
        ThisSubjectOnlyApplysToTheFollowingTeachers: 'This subject only applies to the following teachers',
        TimePinned: 'Time pinned',
        WeekDay: 'Weekday',
        Yes: 'Yes',
        No: 'No',
        NoTeachers: 'No teachers',
        Preferably: 'preferably',
        Problems: 'Problems',
        Rows: 'rows',
        UnknownCourseRound: 'Unknown course round',
        ProblemTypeLabel: {
            EndTimeProblem: 'Lecture ends too late',
            TeacherConflictProblem: 'Teacher double booked',
            StudentGroupLunchProblem: 'Class has too short lunch break',
            TeacherBreakProblem: 'Teacher has too short break between lectures',
            StudentGroupBreakProblem: 'Students have too short break between lectures',
            RoomConflictProblem: 'Room double booked',
            RoomReservedTimeConflictProblem: 'Room scheduled during blocked time',
            RequiredRoomAttributeProblem: 'Room missing attribute',
            NoRoomSetProblem: 'Lecture missing room',
            UnscheduledLectureProblem: 'Lecture not scheduled',
            ScheduleOverrunProblem: 'Lecture ends too late',
            SameCourseMultipleTimesOnSameDayProblem: 'Same course multiple times on same day',
            SameSubjectMultipleTimesOnSameDayProblem: 'Same subject multiple times on same day',
            StudentGroupConflictProblem: 'Student group double booked',
            TeacherFrameTimeProblem: 'Teacher frame time exceeded',
            TeacherLunchProblem: 'Teacher has too short lunch break',
            TeacherReservedTimeConflictProblem: 'Teacher scheduled during blocked time',
            StudentGroupReservedTimeConflictProblem: 'Student group scheduled during blocked time',
            LectureDurationProblem: {
                TooLong: 'Lecture too long',
                TooShort: 'Lecture too short'
            },
            TotalCourseRoundDuration: {
                TooLong: 'Course round has too many hours',
                TooShort: 'Course round has too few hours'
            },
            UnassignedCourseRoundProblem: 'Course round missing teacher',
            UnAssignedCourseRoundProblemLabel: 'Undervisningsgrupp {{courseName}} saknar lärare',
            UnknownCourseRound: 'Unknown course round',
            TeacherNotQualifiedProblem: 'Teacher {{teacherName}} is not qualified to teach {{subjectCode}}',
            TeacherNotQualifiedProblemLabel: 'Teacher is not qualified',
            TeacherOverloadProblem: {
                Prefix: 'Teacher {{teacherName}} has too much teaching in ',
                FallSuffix: 'Fall term ({{hours}}h)',
                SpringSuffix: 'Spring term ({{hours}}h)',
                And: 'and on'
            },
            TeacherOverloadProblemLabel: 'Teachers have too many lessons',
            CourseRoundDurationProblem: '{{courseName}} has {{type}} hours'
        },
        Qualifications: 'Qualifications',
        TeacherSaved: 'Teacher saved',
        TeachersSaved: 'Teachers saved',
        CourseSaved: 'Course saved',
        CoursesSaved: 'Courses saved',
        StudentGroupSaved: 'Student group saved',
        StudentGroupsSaved: 'Student groups saved',
        SelectSingleStudentGroupToEditName: 'Select a single student group to edit the name.',
        SelectSingleStudentGroupToEditDescription: 'Select a single student group to edit the description.',
        LecturesReferencingSelectedRooms: '{{count}} lecture(s) reference the selected rooms',
        RoomRemoved: 'Room removed',
        RemoveRoom: 'Remove room',
        RemoveRooms: 'Remove rooms',
        AddRoom: 'Add room',
        NoRoomAttributes: 'No room attributes',
        ValidFor: 'Valid for:',
        WeekDays: {
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            Sunday: 'Sunday'
        },
        WeekDaysShort: {
            Monday: 'Mo',
            Tuesday: 'Tu',
            Wednesday: 'We',
            Thursday: 'Th',
            Friday: 'Fr',
            Saturday: 'Sa',
            Sunday: 'Su'
        },
        WholeSchoolYear: 'Whole school year',
        Weeks: 'Weeks',
        W: 'w.',
        Actions: 'Actions',
        AddPeriod: 'Add period',
        AddSubject: 'Add subject',
        AddCourse: 'Add course',
        AddCourseOffering: 'Add course offering',
        AddTeacher: 'Add teacher',
        ClearContent: 'Clear content',
        Edit: 'Edit',
        EditEvent: 'Edit event',
        NewSchedule: 'New schedule',
        NewRows: 'New rows',
        NoEventSelected: 'No event selected',
        PeriodName: 'Period name',
        RemoveCourse: 'Remove course',
        RemoveCourses: 'Remove courses',
        RemoveSubject: 'Remove subject',
        RemoveSubjects: 'Remove subjects',
        RemoveStudentGroup: 'Remove student group',
        RemoveStudentGroups: 'Remove student groups',
        RemoveTeacher: 'Remove teacher',
        ResetContent: 'Reset content',
        Search: 'Search',
        SubjectCode: 'Subject code',
        TableEditing: 'Table editing',
        Title: 'Title',
        TransferToAdmin: 'Transfer to Admin',
        TransferToSelectedSchedule: 'Export to selected schedule',
        TransferComplete: 'Transfer complete',
        TransferStatus: {
            Rooms: 'Transfer rooms',
            Teachers: 'Transfer teachers',
            Periods: 'Transfer periods',
            StudentGroups: 'Transfer student groups',
            Courses: 'Transfer courses',
            Lectures: 'Transfer lectures'
        },
        TeacherChanges: {
            NewTeachers: 'New teachers',
            UpdatedTeachers: 'Updated teachers',
            RemovedTeachers: 'Removed teachers',
            NoNewTeachers: 'No new teachers',
            NoUpdatedTeachers: 'No updated teachers',
            NoRemovedTeachers: 'No removed teachers',
            RemovedQualifications: 'Removed qualifications',
            AddedQualifications: 'Added qualifications'
        },
        Errors: {
            BackgroundChanges: 'Background changes',
            DuplicateSignature: 'Duplicate signature',
            ErrorsMustBeFixed: 'Following errors must be fixed',
            ImportFailed: 'Schedule import failed. Please contact Meitner Support.',
            InvalidPercentage: 'Invalid percentage format',
            LecturesReferencing: 'lecture(s) referencing',
            NoSuitableEventType: 'No suitable event type',
            PredefinedPeriodNotFound: {
                SchoolYear: 'Predefined period not found for school year.',
                Spring: 'Predefined period not found for spring.',
                Fall: 'Predefined period not found for fall.'
            },
            ReservedTimesReferencing: 'reserved times referencing period',
            StudentGroupReferencedInDistribution: 'Student group referenced in distribution ({{name}})',
            TeacherReferencedInDistribution: 'Teacher referenced in distribution ({{name}})',
            UnknownSubject: 'Unknown subject:',
            TryAgain: 'Try again',
            PleaseReload: 'Please reload the page and try again.',
            LecturesReferencingPeriod: '{{count}} lecture(s) reference the period',
            ReservedTimesReferencingPeriod: '{{count}} reserved times reference the period',
            LecturesReferencingCourseRound: '{{count}} lecture(s) reference the selected course rounds',
            ExistingLecturesForCourseRound: 'There are already lectures for some of the selected course rounds',
            DuplicateCourseCode: 'Course code already exists',
            MissingCourseCode: 'Course is missing course code',
            MissingCourseName: 'Course is missing name',
            MissingSubject: 'Course is missing subject',
            SubjectNotFound: 'Subject not found',
            SubjectNotFoundWithCode: 'Subject {{code}} not found'
        },
        Success: {
            Created: 'created',
            ImportComplete: 'Schema imported.',
            SubjectSaved: 'Subject saved',
            SubjectsSaved: 'Subjects saved',
            SubjectRemoved: 'Subject removed',
            SubjectsRemoved: 'Subjects removed'
        },
        SelectSingleSubject: {
            BreakRules: 'Select a single subject to edit break rules.',
            LectureDurations: 'Select a single subject to edit lecture durations.'
        },
        EntityTypes: {
            Subject: 'subject',
            Schedule: 'schedule'
        },
        DuplicateNamesInSubjectsCourses: 'Duplicate names in subjects/courses:',
        SelectScheduleToTransferTo: 'Select which schedule to transfer the current schedule to:',
        ImportFromRoyalSchedule: 'Import from RoyalSchedule',
        Semesters: 'Semesters',
        'Success.CourseRemoved': 'Course removed',
        'Errors.CourseRoundsReferencing': '{{count}} course round(s) reference the selected courses',
        TeacherListChanges: {
            NewTeachers: 'New teachers',
            UpdatedTeachers: 'Updated teachers',
            DeletedTeachers: 'Deleted teachers'
        },
        RoomListChanges: {
            NewRooms: 'New rooms',
            UpdatedRooms: 'Updated rooms',
            DeletedRooms: 'Deleted rooms',
            NoNewRooms: 'No new rooms',
            NoUpdatedRooms: 'No updated rooms',
            NoRemovedRooms: 'No removed rooms'
        },
        SkipCharacter: 'Skip character',
        LeaveUnchanged: 'Leave unchanged',
        SelectTwoOrMoreLectures: 'Select two or more lectures to create a block',
        AllLecturesAlreadyInBlock: 'All selected lectures are already in a block',
        CreateBlock: 'Create block',
        ExtractSingleLectureFromBlock: 'Extract only selected lecture from block',
        ExtractSelectedLecturesFromBlock: 'Extract only selected lectures from block',
        SplitBlock: 'Split block',
        MoveToDrawer: 'Move to drawer',
        RemoveLecture: 'Remove lecture',
        RemoveLectures: 'Remove lectures',
        WithSpecificWeeks: 'With specific weeks...',
        ExceptSpecificWeeks: 'Except specific weeks...',
        CourseRoundActions: {
            RemoveCourseRound: 'Remove course round',
            GenerateLectures: 'Generate lectures',
            ReferToSubject: 'Refer to subject instead of course'
        },
        ViewOptions: 'View options',
        LectureSaved: 'Lecture saved',
        LecturesSaved: 'Lectures saved',
        SelectLecturesFromSameBlockToEditDayAndTime: 'Select lectures from the same block to edit day and time',
        SelectSingleLectureToEditTeachers: 'Select a single lecture to edit teachers',
        SelectSingleLectureToEditRooms: 'Select a single lecture to edit rooms',
        SelectSingleLectureToEditWeekSettings: 'Select a single lecture to edit week settings',
        SelectSingleLectureToEditDuration: 'Select a single lecture to edit duration',
        SelectSingleLectureToEditBreakSettings: 'Select a single lecture to edit break settings',
        SelectSingleLectureToEditDurationSettings: 'Select a single lecture to edit duration settings',
        Without: 'without',
        With: 'with',
        SelectWeek: 'Select week…',
        SelectWeekToExtract: 'Select week to extract:',
        Extract: 'Extract',
        ExtractFromSeries: 'Extract from series',
        AddRequiredRoomAttributes: 'Add required room attributes',
        OpenMenu: 'Open menu',
        GraphicalView: 'Graphical view',
        Settings: 'Settings',
        Staff: 'Staff',
        Subjects: 'Subjects',
        Transfer: 'Transfer',
        Import: 'Import',
        Configuration: 'Configuration',
        OpenChecklist: 'Open checklist',
        LogIn: 'Log in',
        NoProfileSelected: 'No profile selected.',
        NoSchedulerRole: 'You do not have permission to access Meitner Schedule. Contact your administrator.',
        SessionExpired: 'You have been logged out.',
        InheritFromCourseRound: 'Inherit from course round',
        SetSpecificForLesson: 'Set specific for lesson',
        DuplicateLecture: 'Duplicate lecture',
        NoReservedTimeSelected: 'No reserved time selected',
        RemoveReservedTime: 'Remove reserved time',
        RemoveReservedTimes: 'Remove reserved times',
        Split: 'Split',
        SplitLecture: 'Split lecture',
        StudentGroupAppliesOnlyToTheseReservedTimes: 'This student group only applies to the following reserved times',
        TeacherAppliesOnlyToTheseReservedTimes: 'This teacher only applies to the following reserved times',
        ThisRoomOnlyApplysToTheFollowingReservedTimes: 'This room only applies to the following reserved times',
        NoEvent: 'No event selected',
        SelectSingleReservedTimeToChangeLength: 'Select a single reserved time to change length.',
        SelectSingleReservedTimeToChangeTitle: 'Select a single reserved time to change title',
        SelectSingleReservedTimeToChangeWeekSettings: 'Select a single reserved time to change week settings',
        LecturesInBlockSelected: '{{count}} of {{total}} lectures in the block selected.',
        SelectAllLecturesInBlock: 'Select all lectures in the block',
        SelectAllLecturesInBlockToEdit: 'to edit day and time.',
        RoomPinned: 'Room pinned',
        RoomUnpinned: 'Room unpinned',
        Loading: 'Loading',
        RoomSaved: 'Room saved',
        RoomsSaved: 'Rooms saved',
        ShowAll: 'Show all',
        ShowFewer: 'Show fewer',
        ShowLess: 'Show less',
        AddedQualifications: 'Added qualifications',
        ErrorsMustBeFixed: 'The following errors must be fixed',
        NewTeachers: 'New teachers',
        NoNewTeachers: 'No new teachers',
        NoRemovedTeachers: 'No removed teachers',
        NoUpdatedTeachers: 'No updated teachers',
        RemovedQualifications: 'Removed qualifications',
        RemovedTeachers: 'Removed teachers',
        UpdatedTeachers: 'Updated teachers',
        ElementarySchool: 'Elementary',
        HighSchool: 'High School',
        HiddenCoursesInElementaryMode:
            'There are courses registered in the schedule. In Elementary School mode, the course table is hidden and you cannot view or edit courses without first switching back to High School mode.',
        Validation: {
            SignatureAppears: 'Signature also appears on row {{rows}}',
            UnknownSubject: 'Unknown subject: {{subject}}',
            MalformedPercentage: 'Incorrectly formatted percentage',
            TeacherSignatureMultipleRows: 'Teacher signature {{signature}} appears on more than one row: {{rows}}',
            TeacherRemovedButReferenced:
                'Teacher {{teacher}} is removed, but is referenced in the following teaching groups: {{groups}}',
            RowUnknownSubject: 'Row {{row}}: Unknown subject: {{subject}}',
            RowMalformedPercentage: 'Row {{row}}: Incorrectly formatted percentage',
            RoomNameMultipleRows: 'Room name "{{name}}" appears in multiple rows: {{rows}}',
            CouldntRemoveStudentGroups:
                'Could not remove student group {{studentGroup}} because it is referenced in the following course round: {{courseRound}}',
            DuplicateStudentGroupNames: 'Student group name {{name}} appears in multiple rows: {{rows}}',
            StudentGroupMissingName: 'Student group missing name',
            StudentGroupAlreadyExists: 'Student group already exists',
            SubjectNameMultipleRows: 'Subject name "{{name}}" appears in multiple rows: {{rows}}'
        }
    },
    SchoolType: 'School type',
    Settings: 'Settings',
    SettingsSaved: 'Settings saved',
    OpeningHours: 'Opening hours',
    LunchRules: 'Lunch rules'
} as const
