import i18next from 'i18next'

import { TableEditValidationProblem } from '../../../../../EditTable/types'
import { ScheduleAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { textualListing } from '../../../../../textUtil'
import { toTranslate } from '../../../../../utils/miscUtil'
import { ProblemListProblem } from '../../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../../schedule/components/ProblemsList/utils'

import { SubjectGridRow, SubjectGridContentColumn, SubjectGridMetaDataColumn } from './types'
import { isEmptySubjectRow } from './utils'


const checkForDuplicateSubjects = (
    subjectNames: string[],
    subjectRow: SubjectGridRow
): TableEditValidationProblem | undefined => {
    const subjectName = subjectRow[SubjectGridContentColumn.Name]

    const duplicateSubjectNames = subjectNames.filter((name) => name.toLowerCase() === subjectName.toLowerCase())

    if (duplicateSubjectNames.length > 1) {
        return {
            location: {
                rowIndex: subjectRow[SubjectGridMetaDataColumn.RowIndex] + 1,
                colIndex: SubjectGridContentColumn.Name
            },
            problem: createBlocker(toTranslate('Ämne finns redan'))
        }
    }

    return undefined
}

export const subjectCellValidationErrors = (subjects: SubjectGridRow[]): TableEditValidationProblem[] => {
    const result = []
    const subjectNames = subjects.map((subject) => subject[SubjectGridContentColumn.Name])

    for (const subjectRow of subjects) {
        if (isEmptySubjectRow(subjectRow)) {
            continue
        }

        const subjectName = subjectRow[SubjectGridContentColumn.Name]

        if (subjectName.length === 0) {
            result.push({
                location: {
                    rowIndex: subjectRow[SubjectGridMetaDataColumn.RowIndex] + 1,
                    colIndex: SubjectGridContentColumn.Name
                },
                problem: createBlocker(toTranslate('Ämne saknar namn'))
            })

            return result
        }

        const duplicateProblem = checkForDuplicateSubjects(subjectNames, subjectRow)

        if (duplicateProblem !== undefined) {
            result.push(duplicateProblem)
        }
    }

    return result
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newSubjectData: SubjectGridRow[]
): ProblemListProblem[] => {
    // Check for duplicate subject names
    const subjectNameCounts = new Map<string, number[]>()
    newSubjectData.forEach((subjectRow, index) => {
        const name = subjectRow[SubjectGridContentColumn.Name]
        if (name !== '') {
            if (!subjectNameCounts.has(name)) {
                subjectNameCounts.set(name, [])
            }

            const rows = subjectNameCounts.get(name)
            if (rows) {
                rows.push(index)
            }
        }
    })

    const duplicateSubjectErrors = [...subjectNameCounts.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(([name, rows]) =>
            createBlocker(
                i18next.t('Validation.SubjectNameMultipleRows', {
                    name,
                    rows: textualListing(
                        rows.map((r) => `${r + 1}`),
                        i18next.t('Rows')
                    )
                })
            )
        )

    return [...duplicateSubjectErrors]
}
