import confetti from 'canvas-confetti'
import { ChecklistStep } from 'common-api'

import { ChecklistStepUiItem, VirtualChecklistStep } from './ScheduleChecklistPanel/types'

import PUBLISH = ChecklistStep.PUBLISH

export const firstNonCompletedTopLevelStep = (items: ChecklistStepUiItem[]) => {
    const firstNonCompleted = items.find((i) => !i.isMarkedAsCompleted)

    return (firstNonCompleted || items[0]).step
}

export const isCelebrationStep = (step: ChecklistStep | VirtualChecklistStep) => step === PUBLISH

export const celebrateClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    confetti({
        origin: {
            x: e.pageX / window.innerWidth,
            y: (e.screenY - window.screenY) / window.innerHeight
        },
        zIndex: 1000000
    })
}
