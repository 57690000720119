import classNames from 'classnames'
import { forwardRef } from 'react'
// TODO: Make this less dynamic and use specific values instead

import classes from '../style.module.css'
import type { DropdownButtonItemProps } from '../types'

const DropdownButtonItem = forwardRef<HTMLButtonElement, DropdownButtonItemProps>(
    ({ children, selected, focused, endDecorator, destructive, size = 'sm', ...props }, ref) => {
        function renderEndDecorator() {
            if (endDecorator === undefined) {
                return null
            }

            return <div className={classes.dropdownItem__endDecorator}>{endDecorator}</div>
        }

        return (
            <button
                {...props}
                ref={ref}
                type="button"
                className={classNames(classes.dropdown__item, [classes[`dropdown__item--size-${size}`]], {
                    [classes['dropdown__item--selected']]: selected,
                    [classes['dropdown__item--destructive']]: destructive,
                    [classes['dropdown__item--focused']]: focused
                })}
            >
                {children}

                {renderEndDecorator()}
            </button>
        )
    }
)

export default DropdownButtonItem
