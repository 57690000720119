import { Flex } from 'antd'
import { without } from 'lodash'

import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { addToMultimap, transformMapValues } from '../../../../utils/collections'
import { AppError } from '../../../../utils/errorutil'
import { ColorInput } from '../ColorInput'

import { MultiSubjectColorInputProps } from './types'


export const MultiSubjectColorInput = ({
    value = [],
    onChange = () => {},
    subjectIds = []
}: MultiSubjectColorInputProps) => {
    if (value.length !== subjectIds.length) {
        throw new AppError('Expected one label per value.')
    }

    const schedule = useLocalSchedule()

    // Maps hex color to the label of what it is already assigned to
    const assignedColors = new Map<string, string[]>()
    for (const subject of schedule.getSubjects()) {
        const subjectFormValue = value!.find((v, i) => subject.getSubjectId() === subjectIds[i])
        const currentColor = subjectFormValue || subject.getColor()
        addToMultimap(assignedColors, currentColor, subject.getCode())
    }

    const onNthChange = (i: number, newCol: string) => {
        const newValue = [...value]
        newValue[i] = newCol
        onChange(newValue)
    }

    return (
        <Flex gap={5} wrap="wrap">
            {value.map((color, i) => {
                const label = schedule.getSubjects().find(s => s.getSubjectId() === subjectIds[i])!.getCode()
                const assignedColorsWithoutThis = transformMapValues(assignedColors, (v) => without(v, label))

                return (
                    <ColorInput
                        key={i}
                        onChange={(newColor) => onNthChange(i, newColor)}
                        value={color}
                        assignedColors={assignedColorsWithoutThis}
                    >
                        {value?.length > 1 && label}
                    </ColorInput>
                )
            })}
        </Flex>
    )
}
