import { IRoom } from 'common-api'
import { useTranslation } from 'react-i18next'

import { SimpleListDiff } from '../../../../../EditTable/components/SimpleListDiff'
import { DiffResult } from '../../../../../EditTable/utils'

export type SimpleRoomListDiffProps = {
    diff: DiffResult<IRoom>
}

const SimpleRoomListDiff = ({ diff }: SimpleRoomListDiffProps) => {
    const { t } = useTranslation()

    return (
        <SimpleListDiff
            diff={diff}
            getDisplayName={(room) => room.name}
            getPropertyDiffs={(prev, next) => [
                {
                    label: t('RoomId'),
                    prevValue: prev.roomId,
                    nextValue: next.roomId
                },
                {
                    label: t('Room'),
                    prevValue: prev.name,
                    nextValue: next.name
                },
                {
                    label: t('RoomAttributes'),
                    prevValue: prev.attributes.toSorted((a1, a2) => a1.localeCompare(a2, 'sv')).join(','),
                    nextValue: next.attributes.toSorted((a1, a2) => a1.localeCompare(a2, 'sv')).join(',')
                }
            ]}
            translations={{
                newItems: t('RoomListChanges.NewRooms'),
                updatedItems: t('RoomListChanges.UpdatedRooms'),
                deletedItems: t('RoomListChanges.DeletedRooms')
            }}
        />
    )
}

export default SimpleRoomListDiff
