import classNames from 'classnames'
import { forwardRef } from 'react'

import Focusable from '../Focusable'

import classes from './style.module.css'
import type { IconButtonProps } from './types'

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ variant, size = 'md', pill = false, className, children, ...props }, ref) => {
        return (
            <Focusable>
                <button
                    type="button"
                    {...props}
                    ref={ref}
                    className={classNames(
                        classes.button,
                        className,
                        [classes[`button--variant-${variant}`]],
                        [classes[`button--size-${size}`]],
                        {
                            [classes['button--pill']]: pill
                        }
                    )}
                >
                    {children}
                </button>
            </Focusable>
        )
    }
)

export default IconButton
