import type { UseTransitionStylesProps } from '@floating-ui/react'

import type { FloatingRootProps } from './FloatingRoot'

enum TransformOrigin {
    TopLeft = 'top left',
    TopCenter = 'top center',
    TopRight = 'top right',
    BottomLeft = 'bottom left',
    BottomCenter = 'bottom center',
    BottomRight = 'bottom right',
    CenterLeft = 'center left',
    CenterRight = 'center right'
}

export const PlacementToTransformOrigin = {
    bottom: TransformOrigin.TopCenter,
    'bottom-start': TransformOrigin.TopLeft,
    'bottom-end': TransformOrigin.TopRight,
    top: TransformOrigin.BottomCenter,
    'top-start': TransformOrigin.BottomLeft,
    'top-end': TransformOrigin.BottomRight,
    left: TransformOrigin.CenterRight,
    'left-start': TransformOrigin.TopRight,
    'left-end': TransformOrigin.BottomRight,
    right: TransformOrigin.CenterLeft,
    'right-start': TransformOrigin.TopLeft,
    'right-end': TransformOrigin.BottomLeft
}

const getDefaultProps = (
    transitionOptions: UseTransitionStylesProps,
    positionMethod: FloatingRootProps['positionMethod'] = 'absolute'
): Partial<FloatingRootProps> => ({
    enableTransition: true,
    transitionOptions,
    positionMethod
})

const getScaleUpTransition = (exitAndInitialScale = 0.6) => {
    const scale = `scale(${exitAndInitialScale})`
    const opacity = 0

    const transition: UseTransitionStylesProps = {
        initial: ({ placement }) => ({
            transformOrigin: PlacementToTransformOrigin[placement],
            opacity,
            transform: scale
        }),
        open: ({ placement }) => ({
            transformOrigin: PlacementToTransformOrigin[placement],
            opacity: 1,
            transform: 'scale(1)'
        }),
        close: ({ placement }) => ({
            transformOrigin: PlacementToTransformOrigin[placement],
            opacity,
            transform: scale
        }),
        duration: 150
    }

    return getDefaultProps(transition)
}

const getSlideTransition = (offset = 10) => {
    const positive = `${offset}px`
    const negative = `-${offset}px`

    const transform = {
        top: `translateY(${positive})`,
        right: `translateX(${negative})`,
        bottom: `translateY(${negative})`,
        left: `translateX(${positive})`
    }

    const transition: UseTransitionStylesProps = {
        initial: ({ side }) => ({
            opacity: 0,
            transform: transform[side]
        }),
        close: ({ side }) => ({
            opacity: 0,
            transform: transform[side]
        }),
        duration: 150
    }

    return getDefaultProps(transition)
}

export { getScaleUpTransition, getSlideTransition }
