import { Flex, Table } from 'antd'
import { Outlet } from 'react-router'

import type { CourseId } from '../../../../commonTypes'
import TableCard from '../../../../components/TableCard'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import EditTableLoadingSpinner from '../../../../EditTable/components/EditTableLoadingSpinner'
import { TableEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch'
import { useEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch/useEditModeSwitch'
import { useIsDummySchedule, useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { NewCourseButton } from '../NewCourseButton'

import { useCourseColumns } from './columns'
import type { CourseRow, CoursesTableProps } from './types'

export const CoursesTable = ({ selectedRowKeys, setSelectedRowKeys }: CoursesTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useCourseColumns(filtering)
    const { isEditModeToggled } = useEditModeSwitch()
    const isDummySchedule = useIsDummySchedule()

    const dataSourceUnfiltered = schedule.getCourses().map((course) => ({ course }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ course }) => course.getName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableCard
            title={
                <Flex gap={8}>
                    <FilterInput {...filtering} disabled={isEditModeToggled} />
                    <VisibleColumnsSelector
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        disabled={isEditModeToggled}
                    />
                    <TableEditModeSwitch disabled={isDummySchedule} onChange={handleModeChange} />
                    <NewCourseButton
                        onNewCourse={(newCourseId) => {
                            setSelectedRowKeys([newCourseId])
                        }}
                        disabled={isEditModeToggled}
                    />
                </Flex>
            }
        >
            {isEditModeToggled ? (
                <EditTableLoadingSpinner>
                    <Outlet />
                </EditTableLoadingSpinner>
            ) : (
                <Table<CourseRow>
                    bordered
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRows) => {
                            setSelectedRowKeys(newSelectedRows as CourseId[])
                        }
                    }}
                    dataSource={dataSource}
                    columns={columns}
                    showSorterTooltip={false}
                    size="small"
                    rowKey={({ course }) => course.getCourseId()}
                    sticky
                    onRow={({ course }) => ({
                        onClick: () => {
                            setSelectedRowKeys([course.getCourseId()])
                        }
                    })}
                    style={{ height: '100%' }}
                    pagination={false}
                />
            )}
        </TableCard>
    )
}
