import { Column } from '@silevis/reactgrid'

export enum CourseGridMetaDataColumn {
    RowIndex = 0
}

export enum CourseGridContentColumn {
    Name = 1,
    Code = 2,
    Subject = 3
}

export const ALL_CONTENT_COLUMNS = [
    CourseGridContentColumn.Name,
    CourseGridContentColumn.Code,
    CourseGridContentColumn.Subject
]

export type CourseGridRow = Record<CourseGridContentColumn, string> & {
    [CourseGridMetaDataColumn.RowIndex]: number
}

const ALL_COLUMNS = [CourseGridMetaDataColumn.RowIndex, ...ALL_CONTENT_COLUMNS]

export const NUM_ROOM_COLUMNS = ALL_COLUMNS.length

export type CourseGridColumnSpec = Column & {
    title: string
    gridColumn: CourseGridMetaDataColumn | CourseGridContentColumn
}
