import { Cell, Row } from '@silevis/reactgrid'
import { Flex } from 'antd'
import { IStudentGroup } from 'common-api'
import { isEqual, merge } from 'lodash'
import { Dispatch, SetStateAction, useRef } from 'react'
import EditTable from '../../../../../EditTable'
import { BackgroundChangesAlert } from '../../../../../EditTable/components/BackgroundChangesAlert'
import { DEFAULT_CONTENT_CELL, HEADER_ROW, MIN_ROWS_TO_SHOW } from '../../../../../EditTable/constants'
import { useFocusedCell } from '../../../../../EditTable/hooks'
import {
    anyDiff,
    DEFAULT_HEADER_CELL,
    diff,
    generateEmptyRows,
    handleClearTableContent
} from '../../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { comparing } from '../../../../../utils/compareUtil'
import { GridActionsFooter } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/GridActionsFooter'
import { handleCopy } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handleCopy'
import { handlePaste } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/handlePaste'
import StudentGroupListDiff from './components/StudentGroupListDiff'
import { parseStudentGroupsRows, studentGroupCmp } from './data'
import { useStudentGroupsGridColumns } from './hooks'
import { ALL_CONTENT_COLUMNS, StudentGroupGridRow, StudentGroupsGridMetaDataColumn } from './types'
import { conjureStudentGroupFromStudentGroups, generateEmptyStudentGroupsRow } from './utils'
import { studentGroupCellValidationErrors } from './validation'

type StudenGroupEditGridProps = {
    studentGroups: StudentGroupGridRow[]
    setStudentGroups: Dispatch<SetStateAction<StudentGroupGridRow[]>>
    onReset: () => void
    onSave: () => void
    studentGroupsWhenEditingStarted: IStudentGroup[]
}

export default function StudentGroupEditGrid({
    studentGroups,
    setStudentGroups,
    onReset,
    onSave,
    studentGroupsWhenEditingStarted
}: StudenGroupEditGridProps) {
    const reactGridWrapper = useRef<HTMLDivElement>(null)
    const columns = useStudentGroupsGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveStudentGroups = conjureStudentGroupFromStudentGroups(schedule.getStudentGroups())

    const [focusedCell, setFocusedCell] = useFocusedCell()

    const backgroundUpdates = diff<IStudentGroup>(
        studentGroupsWhenEditingStarted,
        liveStudentGroups,
        (r) => r.displayName,
        studentGroupCmp
    )

    const parsedFromCurrentEditTable = parseStudentGroupsRows(liveStudentGroups, studentGroups)
    const changesMade = anyDiff(
        diff(parsedFromCurrentEditTable, liveStudentGroups, (r) => r.displayName, studentGroupCmp)
    )

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - studentGroups.length)
    const newEmptyRows = generateEmptyRows(studentGroups.length, emptyRowsToAdd, generateEmptyStudentGroupsRow)
    const studentGroupGridData = [...studentGroups, ...newEmptyRows]

    const rows = [
        HEADER_ROW,
        ...studentGroupGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        const fromIndex = studentGroups.length
        const withEmptyRows = generateEmptyRows(fromIndex, numRows, generateEmptyStudentGroupsRow)

        setStudentGroups([...studentGroupGridData, ...withEmptyRows])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    const validationProblems = studentGroupCellValidationErrors(studentGroups)

    studentGroupGridData.forEach((studentGroupRow, studentGroupIndex) => {
        const rowIndex = studentGroupIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === StudentGroupsGridMetaDataColumn.RowIndex) {
                cells.push(
                    merge({}, DEFAULT_HEADER_CELL, {
                        rowIndex,
                        colIndex,
                        props: {
                            value: `${rowIndex}`,
                            style: {
                                justifyContent: 'center'
                            }
                        }
                    })
                )
            } else {
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: studentGroupRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newStudentGroupGridData = [...studentGroupGridData]
                                    newStudentGroupGridData[studentGroupIndex][gridColumn] = newText
                                    setStudentGroups(newStudentGroupGridData)
                                },
                                validationProblems: validationProblems
                                    .filter((vp) => isEqual(vp.location, { rowIndex, colIndex }))
                                    .map((vp) => vp.problem)
                            }
                        },
                        {}
                    )
                )
            }
        })
    })

    const changedData = [
        ...backgroundUpdates.updated.map(([prev]) => prev),
        ...backgroundUpdates.created,
        ...backgroundUpdates.deleted
    ]

    return (
        <Flex vertical style={{ height: '100%' }} ref={reactGridWrapper}>
            <EditTable
                onAddMoreRows={onAddMoreRows}
                onCellFocused={setFocusedCell}
                rows={rows}
                cells={cells}
                columns={columns}
                onCopy={handleCopy}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "student group data coordinates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }

                    const newStudentGroups = handlePaste<StudentGroupGridRow>({
                        columns: ALL_CONTENT_COLUMNS,
                        currentRows: studentGroupGridData,
                        event: e,
                        generateEmptyRow: (dstRowIndex) => generateEmptyStudentGroupsRow(dstRowIndex),
                        indexLocation: targetCell
                    })

                    setStudentGroups(newStudentGroups)
                }}
            />
            {anyDiff(backgroundUpdates) ? (
                <BackgroundChangesAlert
                    diff={backgroundUpdates}
                    changedData={changedData}
                    DiffPopupComponent={<StudentGroupListDiff diff={backgroundUpdates} />}
                    namesList={changedData
                        .map((studentGroup) => studentGroup.displayName)
                        .toSorted(comparing((n) => n.length))}
                />
            ) : null}
            <GridActionsFooter
                onReset={onReset}
                onSave={onSave}
                saveEnabled={changesMade}
                handleClearTableContent={() => {
                    handleClearTableContent({
                        rows: studentGroupGridData,
                        generateEmptyRow: generateEmptyStudentGroupsRow,
                        setData: setStudentGroups
                    })
                }}
            />
        </Flex>
    )
}
