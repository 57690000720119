import { EditFilled, EditOutlined } from '@ant-design/icons'
import { Dropdown, Flex, MenuProps } from 'antd'

import { Checkmark } from '../Checkmark'

import { EditModeDropdownProps } from './types'

export const EditModeDropdown = ({ items, isEditActive }: EditModeDropdownProps) => {
    const dropdownMenuItems: MenuProps['items'] = items.map(({ isSelected, label, onClick }, i) => ({
        key: `${i}`,
        label: (
            <Flex gap={20} align="center" style={isSelected ? { fontWeight: 600, color: 'black' } : {}}>
                <div style={{ flex: 1 }}>{label}</div>
                <Checkmark selected={isSelected} style={{ color: '#1677ff' }} />
            </Flex>
        ),
        onClick
    }))

    const selectedKeys = items.flatMap((item, i) => (item.isSelected ? [`${i}`] : []))

    return (
        <Dropdown trigger={['click']} menu={{ selectedKeys, items: dropdownMenuItems }}>
            {isEditActive ? <EditFilled /> : <EditOutlined />}
        </Dropdown>
    )
}
