import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useColumnWidth } from '../../../../../EditTable/hooks'
import { CourseAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'

import {
    ALL_CONTENT_COLUMNS,
    CourseGridColumnSpec,
    CourseGridContentColumn,
    CourseGridMetaDataColumn,
    CourseGridRow
} from './types'
import { sortedCoursesFromSchedule } from './utils'

const useSortedCoursesFromSchedule = () => {
    const schedule = useLocalSchedule()

    return sortedCoursesFromSchedule(schedule)
}

const courseToCourseRow = (course: CourseAccessor, rowIndex: number): CourseGridRow => ({
    [CourseGridMetaDataColumn.RowIndex]: rowIndex,
    [CourseGridContentColumn.Name]: course.getName(),
    [CourseGridContentColumn.Code]: course.getCode(),
    [CourseGridContentColumn.Subject]: course.getSubject().getCode()
})

export const useLiveCoursesTableData = () => {
    const courses = useSortedCoursesFromSchedule()

    return courses.map((course, index) => courseToCourseRow(course, index))
}

export const useCoursesTableDataState = () => {
    const courseTableData = useLiveCoursesTableData()
    const [courseGridData, setCourses] = useState<CourseGridRow[]>(courseTableData)
    const resetCourses = () => {
        setCourses(courseTableData)
    }

    return { courseGridData, setCourses, resetCourses }
}

export const useCoursesGridColumns = (target: RefObject<HTMLDivElement> | null): CourseGridColumnSpec[] => {
    const { t } = useTranslation()
    const columnWidth = useColumnWidth(target, ALL_CONTENT_COLUMNS.length)

    return [
        {
            title: '',
            gridColumn: CourseGridMetaDataColumn.RowIndex,
            colIndex: 0,
            width: 60,
            minWidth: 60
        },
        {
            title: t('Name'),
            gridColumn: CourseGridContentColumn.Name,
            colIndex: 1,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: t('Code'),
            gridColumn: CourseGridContentColumn.Code,
            colIndex: 2,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: t('Subject'),
            gridColumn: CourseGridContentColumn.Subject,
            colIndex: 3,
            width: columnWidth,
            minWidth: columnWidth
        }
    ]
}
