import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName } from '../../../../utils/miscUtil'

import { AddNewTeacherButtonProps } from './types'

export const AddTeacherButton = ({ onTeacherCreated, disabled }: AddNewTeacherButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const freshTeacherSignature = () =>
        generateFreshName(
            'Ny lärare',
            schedule.getTeachers().map((t) => t.getTeacherSchoolId())
        )

    const handleNewTeacher = () => {
        const newTeacherId = uuid()

        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.teacherTransform({
                    newTeacher: {
                        teacherId: newTeacherId,
                        teacherSchoolId: freshTeacherSignature(),
                        firstName: '',
                        lastName: '',
                        workPercentage: 100,
                        qualifications: []
                    }
                })
            )
        )

        onTeacherCreated(newTeacherId)
    }

    return (
        <Button type="primary" onClick={handleNewTeacher} icon={<PlusOutlined />} disabled={disabled}>
            {t('AddTeacher')}
        </Button>
    )
}
