import styled from '@emotion/styled'
import { safePolygon } from '@floating-ui/react'
import { Button, Flex, Modal, Space } from 'antd'
import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import { Icon } from '../../icon/src'
import { useDevModeState } from '../../store/devmode/hooks'
import TypeScale from '../../styles/TypeScale'
import { toTranslate } from '../../utils/miscUtil'
import Dropdown from '../Dropdown'
import Floating from '../Floating'
import { MeitnerLogo } from '../MeitnerLogo'
import NavLink from '../NavLink'
import { ScheduleChecklistPanel } from '../schedule-checklist/ScheduleChecklistPanel'
import { ScheduleChecklistProgressBar } from '../schedule-checklist/ScheduleChecklistProgressBar'
import { AutoSchedulerSseConnectionIndicator } from '../SseConnectionIndicator/AutoSchedulerSseConnectionIndicator'
import { ScheduleSseConnectionIndicator } from '../SseConnectionIndicator/ScheduleSseConnectionIndicator'

import classes from './style.module.css'
import type { SidebarMenuItem, SidebarMenuSubItem } from './types'
import { shouldMenuItemBeShown } from './utils'

type SidebarProps = {
    menuItems: SidebarMenuItem[]
}

const Sidebar = ({ menuItems }: SidebarProps) => {
    const { t } = useTranslation()

    const [isCollapsed, setIsCollapsed] = useLocalStorage('sidebar-collapsed', false)
    const [expandedMenuPath, setExpandedMenuPath] = useState<string | null>(null)
    const [isScheduleChecklistModalOpen, setIsScheduleChecklistModalOpen] = useState(false)
    const devMode = useDevModeState()

    return (
        <nav
            className={classNames(classes.sidebar, {
                [classes['sidebar--collapsed']]: isCollapsed
            })}
            // This makes sure Sidebar is not masked in Sentry Session Replay
            data-sentry-unmask
        >
            <div className={classes.sidebar__inner}>
                <div className={classes.sidebar__brand}>
                    <MeitnerLogo className={classes.sidebar__logo} />

                    <div>
                        <p className={TypeScale.Label_SM_Semibold}>Meitner</p>
                        <p className={classNames(TypeScale.Label_XS_Regular, classes.sidebar__schoolType)}>
                            {t('LogoName')}
                        </p>
                    </div>
                </div>
                <button
                    type="button"
                    className={classes.sidebar__collapseButton}
                    onClick={() => {
                        setIsCollapsed((prev) => !prev)
                    }}
                    aria-label={isCollapsed ? toTranslate('Expandera') : toTranslate('Kollapsa')}
                >
                    <Icon name={isCollapsed ? 'icon-hamburger-max-16' : 'icon-line-arrow-left-16'} />
                </button>
                <NavigateToAdmin collapsed={isCollapsed} />
                <ul className={classes.sidebarList}>
                    {menuItems.filter(shouldMenuItemBeShown).map((menuItem) => (
                        <MenuItem
                            collapsed={isCollapsed}
                            menuItem={menuItem}
                            key={menuItem.label}
                            expandedMenuPath={expandedMenuPath}
                            setExpandedMenuPath={setExpandedMenuPath}
                        />
                    ))}
                </ul>
                {devMode && !isCollapsed && (
                    <Space direction="vertical">
                        <ScheduleSseConnectionIndicator />
                        <AutoSchedulerSseConnectionIndicator />
                    </Space>
                )}
                {!isCollapsed && (
                    <Flex gap={5} vertical style={{ padding: '0 1.2em' }}>
                        <ScheduleChecklistProgressBar />
                        <Button
                            type="link"
                            onClick={() => {
                                setIsScheduleChecklistModalOpen(true)
                            }}
                        >
                            {t('OpenChecklist')}
                        </Button>
                        <NoPaddingModal
                            maskClosable
                            open={isScheduleChecklistModalOpen}
                            closable
                            onCancel={() => {
                                setIsScheduleChecklistModalOpen(false)
                            }}
                            keyboard
                            width="90%"
                            footer={null}
                        >
                            <ScheduleChecklistPanel
                                onNavigateAway={() => {
                                    setIsScheduleChecklistModalOpen(false)
                                }}
                            />
                        </NoPaddingModal>
                    </Flex>
                )}
            </div>
        </nav>
    )
}

type MenuItemProps = {
    menuItem: SidebarMenuItem
    collapsed: boolean
    expandedMenuPath: string | null
    setExpandedMenuPath: (path: string | null) => void
}

const MenuItem = ({ menuItem, collapsed, expandedMenuPath, setExpandedMenuPath }: MenuItemProps) => {
    const subItems = menuItem.subItems?.filter((subItem) => subItem.visible ?? true) ?? []

    if (subItems.length === 0) {
        return <MenuItemWithoutSubItems menuItem={menuItem} />
    }

    return (
        <MenuItemWithSubItems
            menuItem={menuItem}
            collapsed={collapsed}
            expandedMenuPath={expandedMenuPath}
            setExpandedMenuPath={setExpandedMenuPath}
        />
    )
}

type MenuItemWithoutSubItemsProps = {
    menuItem: SidebarMenuItem
}

const MenuItemWithoutSubItems = ({ menuItem }: MenuItemWithoutSubItemsProps) => {
    return (
        <li key={menuItem.href} className={classes.sidebarList__item}>
            <NavLink to={menuItem.href} className={classes.sidebarList__link}>
                <Icon name={menuItem.iconName} />
                <p className={TypeScale.Label_MD_Semibold}>{menuItem.label}</p>
            </NavLink>
        </li>
    )
}

const MenuItemWithSubItems = ({ menuItem, collapsed, expandedMenuPath, setExpandedMenuPath }: MenuItemProps) => {
    const subItems = menuItem.subItems?.filter((subItem) => subItem.visible ?? true) ?? []

    const isSubSectionExpanded = expandedMenuPath === menuItem.href

    const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false)

    return (
        <li
            key={menuItem.href}
            className={classNames(classes.sidebarList__item, {
                [classes['sidebarList__item--expanded']]: isSubSectionExpanded
            })}
        >
            <Floating.Root
                open={isFloatingMenuOpen}
                onChangeOpen={setIsFloatingMenuOpen}
                enabled={collapsed && subItems.length > 0}
                interactions={['hover']}
                middleware={['shift', 'flip', 'autoUpdate', 'offset']}
                offsetOptions={{
                    mainAxis: 8
                }}
                hoverOptions={{
                    handleClose: safePolygon({
                        requireIntent: false,
                        blockPointerEvents: true
                    })
                }}
                placement="right-start"
            >
                <Floating.Target>
                    <button
                        type="button"
                        className={classes.sidebarList__link}
                        onClick={() => {
                            setExpandedMenuPath(isSubSectionExpanded ? null : menuItem.href)
                        }}
                    >
                        <Icon name={menuItem.iconName} />
                        <div className={classes.sidebarList__itemLabel}>
                            <p className={TypeScale.Label_MD_Semibold}>{menuItem.label}</p>
                        </div>
                        {subItems.length ? (
                            <Icon name="icon-chevron-down-16" className={classes.sideBarList__chevron} />
                        ) : null}
                    </button>
                </Floating.Target>
                <Floating.Element>
                    <FloatingSubSection
                        subItems={subItems}
                        onClickItem={() => {
                            setIsFloatingMenuOpen(false)
                        }}
                    />
                </Floating.Element>
            </Floating.Root>
            {subItems.length ? <SubSection subItems={subItems} /> : null}
        </li>
    )
}

type FloatingSubSectionProps = {
    subItems: SidebarMenuSubItem[]
    onClickItem: () => void
}

const FloatingSubSection = forwardRef<HTMLDivElement, FloatingSubSectionProps>(
    ({ subItems, onClickItem, ...props }, ref) => {
        return (
            <Dropdown className={classes.floatingSubSection} size="sm" {...props} ref={ref}>
                {subItems.map((subItem) => (
                    <Dropdown.LinkItem key={subItem.href} to={subItem.href} onClick={onClickItem}>
                        {subItem.label}
                    </Dropdown.LinkItem>
                ))}
            </Dropdown>
        )
    }
)

type SubSectionProps = {
    subItems: SidebarMenuSubItem[]
}

const SubSection = ({ subItems }: SubSectionProps) => {
    return (
        <div className={classes.sidebarSubSection}>
            <div className={classes.sidebarSubSection__verticalLine} />
            <ul className={classes.sidebarSubList}>
                {subItems.map((subItem) => (
                    <li key={subItem.href} className={classes.sidebarSubList__item}>
                        <NavLink to={subItem.href}>
                            <div className={classes.sidebarSubList__itemLabel}>
                                <p>{subItem.label}</p>
                            </div>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}

type NavigateToAdminProps = {
    collapsed: boolean
}

const NavigateToAdmin = ({ collapsed }: NavigateToAdminProps) => {
    const { t } = useTranslation()

    return (
        <a
            href={`${process.env.REACT_APP_MEITNER_ADMIN_URL}/school/scheduling/`}
            className={classes.sidebar__backToAdmin}
        >
            <Icon name="icon-shield-24" />
            {collapsed ? null : <p>{t('BackToAdmin')}</p>}
        </a>
    )
}

const NoPaddingModal = styled(Modal)`
    max-width: 100em;
    & .ant-modal-content {
        padding: 0;
    }
`

export default Sidebar
