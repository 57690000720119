import { FloatingArrow, FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import type { HTMLProps, ReactElement } from 'react'

import { useFloatingContext } from '../FloatingProvider'

type FloatingElementProps = {
    children: ReactElement
    elementProps?: HTMLProps<HTMLElement> | undefined
    zIndex?: number
    enableFocusManager?: boolean
}

const FloatingElement = ({ children, elementProps, zIndex = 2, enableFocusManager = false }: FloatingElementProps) => {
    const {
        refs,
        showFloatingElement,
        floatingTransitionStyles,
        arrowRef,
        arrowEnabled,
        arrowProps,
        context,
        getFloatingProps,
        floatingStyles
    } = useFloatingContext()

    if (!showFloatingElement) {
        return null
    }

    if (!refs?.setFloating || !context) {
        return null
    }

    return (
        <FloatingPortal>
            <FloatingFocusManager
                context={context}
                // TODO: possibly add info about this to the wiki
                disabled={!enableFocusManager}
            >
                <div
                    ref={refs.setFloating}
                    {...getFloatingProps(elementProps)}
                    style={{
                        ...floatingTransitionStyles,
                        ...floatingStyles,
                        ...elementProps?.style,
                        // TODO: Temporary until we get rid of the waves effect which causes z-index issues
                        zIndex
                    }}
                >
                    {children}
                    {arrowEnabled ? <FloatingArrow ref={arrowRef} context={context} {...arrowProps} /> : null}
                </div>
            </FloatingFocusManager>
        </FloatingPortal>
    )
}

export default FloatingElement
