import { Space } from 'antd'
import { useTranslation } from 'react-i18next'

import TypeScale from '../../styles/TypeScale'
import { toTranslate } from '../../utils/miscUtil'
import styled from '../../utils/styled'

import { MeitnerScheduleJsonImportPanel } from './MeitnerScheduleJsonImportPanel'
import { RoyalScheduleImportPanel } from './RoyalScheduleImportPanel'

export const RoyalScheduleImportIndexPage = () => {
    const { t } = useTranslation()

    return (
        <PageContent style={{ height: '100vh', padding: '20px' }}>
            <Space direction="vertical" size="large">
                <h1 className={TypeScale.Heading_XL}>{t('ImportFromRoyalSchedule')}</h1>
                <RoyalScheduleImportPanel />
                <h1 className={TypeScale.Heading_XL}>{toTranslate('Importera JSON-fil')}</h1>
                <MeitnerScheduleJsonImportPanel />
            </Space>
        </PageContent>
    )
}

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`
