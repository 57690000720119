import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import { SubjectFilterDropdown } from '../../../../components/filter-dropdowns/SubjectFilterDropdown'
import type { FilterableColumn } from '../../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../../components/table-support/util'
import { stringCmp } from '../../../../utils/compareUtil'
import type { FilteringState } from '../../../schedule/components/FilterInput/types'

import type { CourseRow } from './types'

export enum CoursesColumnKey {
    Name = 0,
    Code = 1,
    Subject = 2
}

export const useCourseColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleCourseColumns', [
        CoursesColumnKey.Name,
        CoursesColumnKey.Code,
        CoursesColumnKey.Subject
    ])
    const { t } = useTranslation()

    const columns: FilterableColumn<CourseRow>[] = [
        {
            title: t('Name'),
            key: CoursesColumnKey.Name,
            filterTexts: ({ course }) => [course.getName()],
            sorter: (a, b) => stringCmp(a.course.getName(), b.course.getName())
        },
        {
            title: t('Code'),
            key: CoursesColumnKey.Code,
            filterTexts: ({ course }) => [course.getCode()],
            sorter: (a, b) => stringCmp(a.course.getCode(), b.course.getCode())
        },
        {
            title: t('Subject'),
            key: CoursesColumnKey.Subject,
            filterDropdown: SubjectFilterDropdown,
            onFilter: (subjectId, { course }) => course.getSubject().getSubjectId() === subjectId,
            filterTexts: ({ course }) => [course.getSubject().getCode()],
            sorter: (a, b) => stringCmp(a.course.getSubject().getCode(), b.course.getSubject().getCode())
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}
