import classNames from 'classnames'
import type { ReactNode } from 'react'

import classes from './style.module.css'

type ModalContentProps = {
    className?: string
    children: ReactNode
}

const ModalContent = ({ className, children }: ModalContentProps) => {
    return <div className={classNames(classes.modal__content, className)}>{children}</div>
}

export default ModalContent
