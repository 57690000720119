import { ChangeListProps } from './types'

export const ChangeList = ({ children, header, showHeader }: ChangeListProps) => {
    return (
        <div>
            {showHeader ? <strong>{header}</strong> : null}
            <ul style={{ padding: '0 24px' }}>{children}</ul>
        </div>
    )
}
