import { CheckOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import Color from 'colorjs.io'

import { bestTextColorOnBackground, contrastValue } from '../../../../../../utils/colors'

import { AlreadyAssignedList } from './AlreadyAssignedList'
import classes from './style.module.css'
import { PaletteButtonProps } from './types'

export const PaletteButton = ({
    assignedColors,
    color,
    isChecked,
    paletteColumn,
    paletteRow,
    onClick
}: PaletteButtonProps) => {
    const colorIsInUse = assignedColors.length > 0

    // Use border if color is close to white.
    const c = new Color(`#${color}`)
    const disableBorder = !colorIsInUse && contrastValue(c, new Color('white')) > 0.0

    const button = (
        <Button
            icon={isChecked ? <CheckOutlined style={{ color: bestTextColorOnBackground(c) }} /> : <div></div>}
            onClick={onClick}
            className={classNames(classes.paletteButton, {
                [classes.noBorder]: disableBorder,
                [classes.inUseBorder]: colorIsInUse
            })}
            style={{
                backgroundColor: `#${color}`,
                gridColumnStart: paletteColumn === undefined ? undefined : 1 + paletteColumn,
                gridRowStart: paletteRow === undefined ? undefined : 1 + paletteRow
            }}
        />
    )

    return colorIsInUse ? (
        <Tooltip title={<AlreadyAssignedList alreadyAssigned={assignedColors} />}>{button}</Tooltip>
    ) : (
        button
    )
}
