import i18next from 'i18next'
import { without } from 'lodash'

import { diff } from '../../../../../../EditTable/utils'
import { CourseRoundAccessor, ScheduleAccessor } from '../../../../../../schedule-access/scheduleAccessWrappers'
import { textualListing } from '../../../../../../textUtil'
import { addToMultimap, newMultiMapFromKeyValuePairs, orThrow } from '../../../../../../utils/collections'
import { ProblemListProblem } from '../../../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../../../schedule/components/ProblemsList/utils'

import { teacherCmp } from './diff'
import { conjureSubjectsFromSubjects, sortedConjureTeachersFromSchedule } from './hooks'
import { parseCommaSepSubjectList, parseTeacherRows } from './teacherData'
import {
    TableEditValidationProblem,
    TeacherGridContentColumn,
    TeacherGridMetaDataColumn,
    TeacherGridRow
} from './types'
import { isEmptyTeacherRow, isNonEmptyTeacherRow, isWellformedPercent, teacherDisplayName } from './util'

function signatureValidationProblems(
    rowsForSignatures: Map<string, number[]>,
    teacherRow: TeacherGridRow
): ProblemListProblem | undefined {
    const signature = teacherRow[TeacherGridContentColumn.Signature]
    const rowsWithSignature = orThrow(rowsForSignatures.get(signature))
    const otherRowsWithSameSignature = without(rowsWithSignature, teacherRow[TeacherGridMetaDataColumn.RowIndex]).map(
        (row) => `${row + 1}`
    )
    if (otherRowsWithSameSignature.length === 0) {
        return undefined
    }

    return createBlocker(
        i18next.t('Validation.SignatureAppears', {
            rows: textualListing(otherRowsWithSameSignature, i18next.t('Rows'))
        })
    )
}

function signatureRowsMap(teacherData: TeacherGridRow[]) {
    const rowsForSignatures = new Map<string, number[]>()
    for (const teacherRow of teacherData.filter(isNonEmptyTeacherRow)) {
        const rowIndex = teacherRow[TeacherGridMetaDataColumn.RowIndex]
        const signature = teacherRow[TeacherGridContentColumn.Signature]
        addToMultimap(rowsForSignatures, signature, rowIndex)
    }

    return rowsForSignatures
}

export const cellValidationErrors = (
    schedule: ScheduleAccessor,
    teacherData: TeacherGridRow[]
): TableEditValidationProblem[] => {
    // Signature -> Rows with that signature.
    // Example: { "DPE" -> [15, 17], "ASK" -> [8], ... }
    const rowsForSignatures = signatureRowsMap(teacherData)

    const result = []
    for (const teacherRow of teacherData) {
        // Skip validation for empty rows
        if (isEmptyTeacherRow(teacherRow)) {
            continue
        }

        // Signature validation
        const signatureProblem = signatureValidationProblems(rowsForSignatures, teacherRow)
        if (signatureProblem !== undefined) {
            result.push({
                location: {
                    rowIndex: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    colIndex: TeacherGridContentColumn.Signature
                },
                problem: signatureProblem
            })
        }

        // Missing subjects?
        const [_, missingSubjectNames] = parseCommaSepSubjectList(
            conjureSubjectsFromSubjects(schedule.getSubjects()),
            teacherRow[TeacherGridContentColumn.Qualifications]
        )
        result.push(
            ...missingSubjectNames.map((subjectName) => ({
                location: {
                    rowIndex: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    colIndex: TeacherGridContentColumn.Qualifications
                },
                problem: createBlocker(i18next.t('Validation.UnknownSubject', { subject: subjectName }))
            }))
        )

        // Work percentage validation
        if (!isWellformedPercent(teacherRow[TeacherGridContentColumn.WorkPercentage])) {
            result.push({
                location: {
                    rowIndex: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    colIndex: TeacherGridContentColumn.WorkPercentage
                },
                problem: createBlocker(i18next.t('Validation.MalformedPercentage'))
            })
        }
    }

    return result
}

export const globalValidationErrors = (
    currentSchedule: ScheduleAccessor,
    newTeacherData: TeacherGridRow[]
): ProblemListProblem[] => {
    const existingTeachers = sortedConjureTeachersFromSchedule(currentSchedule)
    const existingSubjects = conjureSubjectsFromSubjects(currentSchedule.getSubjects())
    const parsedTeachers = parseTeacherRows(existingTeachers, existingSubjects, newTeacherData)
    const diffToValidate = diff(existingTeachers, parsedTeachers, (t) => t.teacherId, teacherCmp)

    // Check if deleted teachers are referenced in course rounds.
    const courseRoundsByTeachers = newMultiMapFromKeyValuePairs(
        currentSchedule
            .getCourseRounds()
            .flatMap((cr) =>
                cr.getTeachers().map((teacher) => [teacher.getTeacherId(), cr] as [string, CourseRoundAccessor])
            )
    )

    const referencedTeacherIds = currentSchedule
        .getCourseRounds()
        .flatMap((cr) => cr.getTeachers())
        .map((t) => t.getTeacherId())

    const deletedAndReferencedTeachers = diffToValidate.deleted.filter((deletedTeacher) =>
        referencedTeacherIds.includes(deletedTeacher.teacherId)
    )

    const rowsForSignatures = signatureRowsMap(newTeacherData)
    const duplicateSignaturesErrors = [...rowsForSignatures.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(([teacherSchoolId, rows]) =>
            createBlocker(
                i18next.t('Validation.TeacherSignatureMultipleRows', {
                    signature: teacherSchoolId,
                    rows: textualListing(
                        rows.map((r) => `${r + 1}`),
                        i18next.t('Rows')
                    )
                })
            )
        )

    const deletedAndReferencedTeacherErrors = deletedAndReferencedTeachers.map((deletedAndReferencedTeacher) => {
        const referencingCourseRounds = orThrow(courseRoundsByTeachers.get(deletedAndReferencedTeacher.teacherId))
            .map((cr) => cr.getDisplayName())
            .join(', ')

        return createBlocker(
            i18next.t('Validation.TeacherRemovedButReferenced', {
                teacher: teacherDisplayName(deletedAndReferencedTeacher),
                groups: referencingCourseRounds
            })
        )
    })

    const nonExistingSubjectErrors = newTeacherData.filter(isNonEmptyTeacherRow).flatMap((teacherRow) => {
        const [_, missingSubjectNames] = parseCommaSepSubjectList(
            conjureSubjectsFromSubjects(currentSchedule.getSubjects()),
            teacherRow[TeacherGridContentColumn.Qualifications]
        )

        return missingSubjectNames.map((missingSubjectName) =>
            createBlocker(
                i18next.t('Validation.RowUnknownSubject', {
                    row: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1,
                    subject: missingSubjectName
                })
            )
        )
    })

    const malformedPercentagesErrors = newTeacherData
        .filter(isNonEmptyTeacherRow)
        .filter((teacherRow) => !isWellformedPercent(teacherRow[TeacherGridContentColumn.WorkPercentage]))
        .map((teacherRow) =>
            createBlocker(
                i18next.t('Validation.RowMalformedPercentage', {
                    row: teacherRow[TeacherGridMetaDataColumn.RowIndex] + 1
                })
            )
        )

    return [
        ...deletedAndReferencedTeacherErrors,
        ...duplicateSignaturesErrors,
        ...nonExistingSubjectErrors,
        ...malformedPercentagesErrors
    ]
}
