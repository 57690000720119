import { Alert, Flex } from 'antd'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'

import { ProblemListProblem } from '../../pages/schedule/components/ProblemsList/types'
import { DiffResult } from '../utils'

import { EditTableProblemList } from './EditTableProblemList'

type BulkUpdatePreviewProps<T> = {
    diff: DiffResult<T>
    validationErrors: ProblemListProblem[]
    DiffComponent: ComponentType<{ diff: DiffResult<T> }>
}

export default function BulkUpdatePreview<T>({ diff, validationErrors, DiffComponent }: BulkUpdatePreviewProps<T>) {
    const { t } = useTranslation()

    return (
        <Flex vertical gap={8}>
            <DiffComponent diff={diff} />
            {validationErrors.length > 0 ? (
                <Alert
                    showIcon
                    type="error"
                    message={t('ErrorsMustBeFixed')}
                    description={<EditTableProblemList problems={validationErrors} />}
                />
            ) : null}
        </Flex>
    )
}
