import { Modal, Space } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { StudentGroupId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { StudentGroupList } from '../StudentGroupList'
import StudentGroupTable from '../StudentGroupTable'

import { OverlapMode } from './OverlapMode'

type AddOverlapDialogProps = {
    open: boolean
    studentGroupIds: StudentGroupId[]
    mode: OverlapMode
    handleAdd: (selectedStudentGroupIds: StudentGroupId[]) => void
    handleCancel: () => void
}

const AddOverlapDialog = ({ open, studentGroupIds, mode, handleAdd, handleCancel }: AddOverlapDialogProps) => {
    const [selectedStudentGroupRowKeys, setSelectedStudentGroupRowKeys] = useState<StudentGroupId[]>([])

    const { t } = useTranslation()

    const resetDialog = () => {
        setSelectedStudentGroupRowKeys([])
    }

    const handleCancelWrapper = () => {
        handleCancel()
        resetDialog()
    }

    const handleAddWrapper = () => {
        // Add button should be disabled if nothing is selected
        handleAdd(selectedStudentGroupRowKeys)
        resetDialog()
    }

    return (
        <Modal
            title={`${t('Add')} ${mode === OverlapMode.NON_OVERLAP ? t('Non') : ''} ${t('OverlappingGroup')}`}
            open={open}
            forceRender // avoids an error message: https://stackoverflow.com/a/62082074
            onCancel={handleCancelWrapper}
            footer={
                <Space>
                    <Button variant="secondary" key="cancel" onClick={handleCancelWrapper}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        key="add"
                        onClick={handleAddWrapper}
                        variant="primary"
                        disabled={selectedStudentGroupRowKeys.length === 0}
                    >
                        {t('Add')}
                    </Button>
                </Space>
            }
        >
            {t('Add')} {mode === OverlapMode.NON_OVERLAP ? t('NonHypen') : ''} {t('OverlapWith')}{' '}
            {studentGroupIds.length <= 1 ? t('TheGroup') : t('TheGroups')}{' '}
            <StudentGroupList style={{ fontWeight: 'bold' }} studentGroupIds={studentGroupIds} />.
            <div
                style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    width: '100%',
                    height: '60vh',
                    marginTop: '1em'
                }}
            >
                <StudentGroupTable
                    showOverlappingGroupsColumn={false}
                    showAddButton={false}
                    selectedRowKeys={selectedStudentGroupRowKeys}
                    setSelectedRowKeys={setSelectedStudentGroupRowKeys}
                />
            </div>
        </Modal>
    )
}

export default AddOverlapDialog
