import { Flex } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { nullToUndefined } from '../../../utils/miscUtil'
import { TogglePin } from '../../../utils/TogglePin'
import { RoomAttributeSelector } from '../../RoomAttributeSelector'

import { RoomAssignmentMenuDropdown } from './RoomAssignmentMenuDropdown'
import { RoomSelector } from './RoomSelector'
import { RoomAssignmentInputProps } from './types'

export const RoomAssignmentInput = ({ value, onChange, onDelete }: RoomAssignmentInputProps) => {
    const [showRequiredAttributesInput, setShowRequiredAttributesInput] = useState(value.requiredAttributes.length > 0)
    const { t } = useTranslation()

    return (
        <div style={{ display: 'grid', columnGap: 5, rowGap: 8, gridTemplateColumns: '1fr auto auto' }}>
            <RoomSelector
                value={nullToUndefined(value.roomId)}
                onChange={(newRoomId) => {
                    onChange({ ...value, roomId: newRoomId === 'undefined' ? undefined : newRoomId })
                }}
            />
            <TogglePin
                isPinned={value.pinned}
                onChange={(newIsPinned) => {
                    onChange({ ...value, pinned: newIsPinned })
                }}
            />
            <RoomAssignmentMenuDropdown
                setShowRequiredAttributesInput={setShowRequiredAttributesInput}
                onDelete={onDelete}
            />
            {showRequiredAttributesInput && (
                <Flex gap={5} align="center">
                    <span style={{ whiteSpace: 'nowrap' }}>{t('RequiredRoomAttributes')}</span>
                    <RoomAttributeSelector
                        value={value.requiredAttributes}
                        onChange={(newAttributes) => {
                            onChange({ ...value, requiredAttributes: newAttributes })
                        }}
                    />
                </Flex>
            )}
        </div>
    )
}
