import type { IWeekSelection } from 'common-api'
import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'

import type * as weekSelectionActions from './actions'
import { WeekSelectionActionTypes } from './types'

export const setWeekSelection = (weekSelection: IWeekSelection) =>
    action(WeekSelectionActionTypes.SET_WEEK_SELECTION, weekSelection)

export type WeekSelectionAction = ActionType<typeof weekSelectionActions>
