import { Splitter } from 'antd'
import { useCallback, useEffect } from 'react'

import usePersistedSplitter from '../../hooks/usePersistedSplitterWidth'
import styled from '../../utils/styled'
import { FullHeightCollapse } from '../FullHeightCollapse'

import { TablePageProps } from './types'

const TablePage = <R,>({ onEscape, selectedRowKeys, sidePanels, table }: TablePageProps<R>) => {
    const showSidePanel = selectedRowKeys.length > 0
    const { splitterPanelProps, onResizeEnd } = usePersistedSplitter(showSidePanel)

    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onEscape()
            }
        },
        [onEscape]
    )

    useEffect(() => {
        document.addEventListener('keydown', escFunction)

        return () => {
            document.removeEventListener('keydown', escFunction)
        }
    }, [escFunction])

    const items = sidePanels.map(({ key, header, panel }) => ({
        key,
        label: header,
        children: panel
    }))

    return (
        <PageContentArticle>
            <Splitter onResizeEnd={onResizeEnd}>
                <Splitter.Panel
                    style={{
                        // TODO: remove this inline style and fix table without padding
                        padding: 20
                    }}
                >
                    {table}
                </Splitter.Panel>
                <Splitter.Panel {...splitterPanelProps}>
                    {showSidePanel ? (
                        <FullHeightCollapse defaultActiveKey={['edit', 'actions']} bordered={false} items={items} />
                    ) : null}
                </Splitter.Panel>
            </Splitter>
        </PageContentArticle>
    )
}

const PageContentArticle = styled('article')`
    display: flex;
    height: 100vh;
    overflow: hidden;
`

export default TablePage
