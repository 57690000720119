import { ExportOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { useNavigate } from 'react-router'

import { HelpPanelDivider } from '../HelpPanelDivider'
import { ScreenshotImage } from '../ScreenshotImage'

import crdndImage from './crdnd.png'
import { TeacherAssignmentsHelpPanelProps } from './types'

export const TeacherAssignmentsHelpPanel = (props: TeacherAssignmentsHelpPanelProps) => {
    const navigate = useNavigate()

    return (
        <div>
            <p>Tjänstefördelningen är informationen om vilka lärare som ska undervisa vilka kurser.</p>
            <p>
                Eftersom tjänstefördelningen bestämmer vilka lärare som ska vara kopplade till vilka lektioner, bör
                tjänstefördelningen vara helt klar innan schemaläggningen påbörjas. (Annars riskerar man att få göra om
                stora delar av schemaläggningen när tjänstefördelningen uppdateras.)
            </p>
            <p>
                Det kan vara så att tjänstefördelningen redan är klar, och då kan du bara klarmarkera detta steg och gå
                vidare.
            </p>
            <p>Tjänstefördelningen görs lättast i den grafiska tjänstefördelningsvyn.</p>
            <Flex justify="space-around" style={{ marginTop: '2em' }}>
                <Button
                    icon={<ExportOutlined />}
                    type="primary"
                    onClick={() => {
                        props.onNavigateAway()
                        navigate('/teaching-assignments')
                    }}
                >
                    Gå till den grafiska tjänstefördelningen
                </Button>
            </Flex>
            <HelpPanelDivider />
            <p>
                Bredden på kursens ruta är proportionell mot antalet målundervisningstimmar för kursen. En bred ruta
                innebär mycket undervisning, och en smal ruta innebär lite.
            </p>
            <HelpPanelDivider />
            <ScreenshotImage src={crdndImage} />
            <p>
                Tjänstefördelningen gör du lättast genom att dra och släppa kurser i den grafiska tjänstefördelningsvyn.
            </p>
            <HelpPanelDivider />
            <p>
                Om en kurs sticker ut på det röda området betyder det att läraren har mer undervisning än vad tjänsten
                tillåter.
            </p>
            <p>Om en lärare har har en deltidstjänst så har den läraren en mindre bredd tillgänglig.</p>
        </div>
    )
}
