import { useSelector } from 'react-redux'

import { AppError } from '../../utils/errorutil'
import type { ApplicationState } from '../index'

import { isAuthenticated } from './types'

export const useActiveOrgId = (): string =>
    useSelector<ApplicationState, string>(({ authState }) => {
        if (!isAuthenticated(authState)) {
            throw new AppError('Expected user to be authenticated.', { authState })
        }

        return authState.activeOrgId
    })
