import useSize from '@react-hook/size'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { clamp } from 'lodash'
import { RefObject, useState } from 'react'

export const useColumnWidth = (target: RefObject<HTMLDivElement> | null, numColumns: number) => {
    const [width] = useSize(target)
    const ROW_INDEX_COLUMN_WIDTH = 40
    const CONTENT_COLUMN_WIDTH = (width - ROW_INDEX_COLUMN_WIDTH) / numColumns

    // We don't know why ReactGrid doesn't handle the computed value nicely. We have these hacky adjustments to make the end result look ok. In the future react grid may make it easier to style the width with percentage instead of pixels in which case this can be cleaned up.
    const specificColumnCountAdjustments = [0, -18, -12, -8, -6, -5.3]

    return (
        CONTENT_COLUMN_WIDTH +
        specificColumnCountAdjustments[clamp(numColumns, 0, specificColumnCountAdjustments.length - 1)]
    )
}

export const useFocusedCell = () => {
    // For the "manual" paste implementation. Hopefully this wil be part of ReactGrid at some point.
    const [focusedCell, setFocusedCell] = useState<IndexedLocation>({ rowIndex: 1, colIndex: 1 })

    return [focusedCell, setFocusedCell] as const
}
