import { ALL_WEEKDAYS, dayOfWeekComparator } from '../../utils/DayAndTimeUtil'

import type { WeekDaysSelectorAction } from './actions'
import { WeekDaySelectorState, WeekDaySelectorType } from './types'

export const initialState: WeekDaySelectorState = {
    selectedWeekDays: ALL_WEEKDAYS
}

export const weekDaySelectorReducer = (state = initialState, action: WeekDaysSelectorAction): WeekDaySelectorState => {
    switch (action.type) {
        case WeekDaySelectorType.SET_SELECTED_WEEK_DAYS: {
            const newDays = action.payload
            const newState = {
                ...state,
                selectedWeekDays: newDays.toSorted(dayOfWeekComparator)
            }

            return newState
        }
        default:
            return state
    }
}
