import type { ReactElement } from 'react'

import { AppError } from '../../utils/errorutil'

type BulkGeneralInputProps<T> = {
    value?: T[]
    onChange?: (newValue: T[]) => void
    renderInput: (value: T, onChange: (newValue: T) => void) => ReactElement
    multiplicityMessage: string
}

const BulkGeneralInput = <T,>(props: BulkGeneralInputProps<T>) => {
    const singleOnChange = (newValue: T) => {
        if (typeof props.onChange === 'function') {
            props.onChange([newValue])
        }
    }

    if (props.value === undefined || props.value.length === 0) {
        throw new AppError('Expected at least one value to edit.')
    }

    if (props.value.length === 1) {
        return props.renderInput(props.value[0], singleOnChange)
    }

    return <div style={{ paddingTop: '.3em', fontStyle: 'italic' }}>{props.multiplicityMessage}</div>
}

export default BulkGeneralInput
