import { Tooltip } from 'antd'

import { comparing } from '../../../../utils/compareUtil'
import styled from '../../../../utils/styled'

import { EntityListTooltipProps } from './types'

export const EntityListTooltip = <T,>({
    title,
    entities,
    entityLabelFn,
    entityKeyFn,
    singularEntityLabel,
    pluralEntityLabel
}: EntityListTooltipProps<T>) => (
    <HelpHover>
        (
        <Tooltip
            placement="bottom"
            title={
                <>
                    {title}
                    <ul>
                        {entities.sort(comparing(entityLabelFn)).map((r) => (
                            <li key={entityKeyFn(r)}>{entityLabelFn(r)}</li>
                        ))}
                    </ul>
                </>
            }
        >
            <TooltipLink>
                {entities.length} {entities.length === 1 ? singularEntityLabel : pluralEntityLabel}
            </TooltipLink>
        </Tooltip>
        )
    </HelpHover>
)

const HelpHover = styled.span`
    color: #777;
    cursor: help;
`

const TooltipLink = styled.span`
    text-decoration: underline black dashed;
`
