import { Terms } from 'common-api'
import { groupBy, round } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import { CourseFilterDropdown } from '../../../../components/filter-dropdowns/CourseFilterDropdown'
import { StudentGroupFilterDropdown } from '../../../../components/filter-dropdowns/StudentGroupFilterDropdown'
import { SubjectFilterDropdown } from '../../../../components/filter-dropdowns/SubjectFilterDropdown'
import { TeacherFilterDropdown } from '../../../../components/filter-dropdowns/TeacherFilterDropdown'
import { TermFilterDropdown } from '../../../../components/filter-dropdowns/TermFilterDropdown'
import type { FilterableColumn } from '../../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../../components/table-support/util'
import type { CourseRoundAccessor, LectureAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing, stringCmp } from '../../../../utils/compareUtil'
import type { FilteringState } from '../../../schedule/components/FilterInput/types'
import type { CourseRoundRow } from '../../types'

export const termsText = (term: Terms) => (term === Terms.FALL ? 'HT' : term === Terms.SPRING ? 'VT' : 'Läsår')

export enum CourseRoundColumnKey {
    DisplayName = 0,
    Course = 1,
    Subject = 2,
    Teacher = 3,
    Terms = 4,
    Hours = 5,
    ActualHours = 6,
    ActualHoursPercent = 7,
    StudentGroup = 8
}

const useCourseRoundColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleCourseRoundColumns', [
        CourseRoundColumnKey.DisplayName,
        CourseRoundColumnKey.Course,
        CourseRoundColumnKey.Subject,
        CourseRoundColumnKey.Teacher,
        CourseRoundColumnKey.Terms,
        CourseRoundColumnKey.Hours,
        CourseRoundColumnKey.ActualHoursPercent
    ])

    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const durationOfAllLecturesMinutes = (lecture: LectureAccessor) =>
        lecture.getWeekSelection().getWeeks().length * lecture.getDurationInMinutes()

    const lecturesByCourseRound = groupBy(schedule.getLectures(), (l) => l.getCourseRound().getCourseRoundId())

    const totalCourseRoundDurationsInMinutes = Object.fromEntries(
        Object.entries(lecturesByCourseRound).map(([crId, lectures]) => [
            crId,
            lectures.map(durationOfAllLecturesMinutes).reduce((acc, m) => acc + m, 0)
        ])
    )

    const fractionOfTeachingHoursGoal = ({ courseRound }: CourseRoundRow) => {
        const targetCrHours = courseRound.getTotalHours()
        if (targetCrHours === 0) {
            return -1
        }

        const actualLectureHours = (totalCourseRoundDurationsInMinutes[courseRound.getCourseRoundId()] || 0) / 60

        return actualLectureHours / targetCrHours
    }

    const courseCodeForCourseRound = (cr: CourseRoundAccessor) => cr.getCourse()?.getCode() || ''
    const subjectCodeForCourseRound = (cr: CourseRoundAccessor) => cr.getSubject()?.getCode() || ''

    const columns: FilterableColumn<CourseRoundRow>[] = [
        {
            title: t('Name'),
            key: CourseRoundColumnKey.DisplayName,
            filterTexts: ({ courseRound }) => [courseRound.getDisplayName()],
            sorter: (a, b) => stringCmp(a.courseRound.getDisplayName(), b.courseRound.getDisplayName())
        },
        {
            title: t('Course'),
            key: CourseRoundColumnKey.Course,
            onFilter: (courseId, { courseRound }) => courseRound.getCourse()?.getCourseId() === courseId,
            filterDropdown: CourseFilterDropdown,
            filterTexts: ({ courseRound }) => [courseCodeForCourseRound(courseRound)]
        },
        {
            title: t('Subject'),
            key: CourseRoundColumnKey.Subject,
            filterDropdown: SubjectFilterDropdown,
            onFilter: (subjectId, { courseRound }) => courseRound.getSubject()?.getSubjectId() === subjectId,
            filterTexts: ({ courseRound }) => [subjectCodeForCourseRound(courseRound)],
            sorter: (a, b) =>
                stringCmp(a.courseRound.getSubject()?.getSubjectId(), b.courseRound.getSubject()?.getSubjectId())
        },
        {
            title: t('Teacher'),
            key: CourseRoundColumnKey.Teacher,
            onFilter: (teacherId, { courseRound }) =>
                courseRound.getTeachers().some((t) => t.getTeacherId() === teacherId),
            filterDropdown: TeacherFilterDropdown,
            filterTexts: ({ courseRound }) => courseRound.getTeachers().map((t) => t.getTeacherSchoolId())
        },
        {
            title: t('Semesters'),
            key: CourseRoundColumnKey.Terms,
            onFilter: (term, { courseRound }) => courseRound?.getTerms() === term,
            filterDropdown: TermFilterDropdown,
            filterTexts: ({ courseRound }) => [termsText(courseRound.getTerms())]
        },
        {
            title: t('Hours'),
            key: CourseRoundColumnKey.Hours,
            sorter: comparing(({ courseRound }) => courseRound.getTotalHours()),
            render: (_, { courseRound }) => courseRound.getTotalHours()
        },
        {
            title: t('ActualHours'),
            key: CourseRoundColumnKey.ActualHours,
            sorter: comparing(
                ({ courseRound }) => totalCourseRoundDurationsInMinutes[courseRound.getCourseRoundId()] || 0
            ),
            render: (_, { courseRound }) => {
                const actualLectureMinutes = totalCourseRoundDurationsInMinutes[courseRound.getCourseRoundId()]

                return `${round((actualLectureMinutes || 0) / 60, 1)}`
            }
        },
        {
            title: t('HoursInPercentOfGoal'),
            key: CourseRoundColumnKey.ActualHoursPercent,
            sorter: comparing(fractionOfTeachingHoursGoal),
            render: (_, row) => {
                const fraction = fractionOfTeachingHoursGoal(row)

                return fraction === -1 ? '-' : `${round(100 * fraction)}%`
            }
        },
        {
            title: t('StudentGroup'),
            key: CourseRoundColumnKey.StudentGroup,
            onFilter: (studentGroupId, { courseRound }) =>
                courseRound.getStudentGroup().getStudentGroupId() === studentGroupId,
            filterDropdown: StudentGroupFilterDropdown,
            filterTexts: ({ courseRound }) => [courseRound.getStudentGroup().getDisplayName()],
            sorter: (a, b) =>
                stringCmp(
                    a.courseRound.getStudentGroup().getDisplayName(),
                    b.courseRound.getStudentGroup().getDisplayName()
                )
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}

export { useCourseRoundColumns }
