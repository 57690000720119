import { useTranslation } from 'react-i18next'

import { RoomId } from '../../commonTypes'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { ManyToManySelector } from '../many-to-many-selector/ManyToManySelector'

import { RoomsListTooltip } from './RoomsListTooltip'
import { MultiRoomAttributeSelectorProps } from './types'

export const MultiRoomAttributeSelector = ({ value, onChange }: MultiRoomAttributeSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    return (
        <ManyToManySelector<RoomId, string>
            value={value}
            onChange={onChange}
            mode="tags"
            preexistingOptionValues={schedule.getRoomAttributes()}
            optionLabelFn={(attrib) => attrib}
            partialCoverSuffix={(roomIds) => (
                <RoomsListTooltip
                    title={t('ThisAttributeOnlyApplysToTheFollowingRooms')}
                    rooms={roomIds.map((roomId) => schedule.findRoom(roomId))}
                />
            )}
        />
    )
}
