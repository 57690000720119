import { ITeacher } from 'common-api'

import { combinedCmpFn, comparing } from '../../../../../../utils/compareUtil'

export const teacherCmp = combinedCmpFn<ITeacher>(
    comparing((t) => t.teacherSchoolId),
    comparing((t) => t.firstName),
    comparing((t) => t.lastName),
    comparing((t) => t.qualifications.toSorted((q1, q2) => q1.localeCompare(q2, 'sv')).join(',')),
    comparing((t) => t.workPercentage)
)
