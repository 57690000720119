import { useSelector } from 'react-redux'

import { ApplicationState } from '../../../store'
import { SseConnectionState } from '../../../store/devmode/types'
import { SseConnectionIndicator } from '../index'

export const ScheduleSseConnectionIndicator = () => {
    const numPendingTransforms = useSelector<ApplicationState, number>((s) => s.schedule.pendingTransforms.length)
    const subscribersExist = useSelector<ApplicationState, boolean>((s) => s.schedule.subscriptionCount > 0)
    const connectionState = useSelector<ApplicationState, SseConnectionState>(
        (s) => s.devInfoState.scheduleSseConnectionState
    )

    return (
        <SseConnectionIndicator
            label={`Schedule${numPendingTransforms > 0 ? ` [${numPendingTransforms}]` : ''}`}
            subscribersExist={subscribersExist}
            connectionState={connectionState}
        />
    )
}
