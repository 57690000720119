import { Column } from '@silevis/reactgrid'

export enum RoomGridMetaDataColumn {
    RowIndex = 0
}

export enum RoomGridContentColumn {
    Name = 1,
    Attributes = 2
}

export const ALL_CONTENT_COLUMNS = [RoomGridContentColumn.Name, RoomGridContentColumn.Attributes]

export type RoomGridRow = Record<RoomGridContentColumn, string> & {
    [RoomGridMetaDataColumn.RowIndex]: number
}

const ALL_COLUMNS = [RoomGridMetaDataColumn.RowIndex, ...ALL_CONTENT_COLUMNS]

export const NUM_ROOM_COLUMNS = ALL_COLUMNS.length

export type RoomGridColumnSpec = Column & {
    title: string
    gridColumn: RoomGridMetaDataColumn | RoomGridContentColumn
}
