const TypeScale = {
    // Display
    Display_6XL: 'typescale-display-6xl',
    // Headings
    Heading_5XL: 'typescale-heading-5xl',
    Heading_4XL: 'typescale-heading-4xl',
    Heading_3XL: 'typescale-heading-3xl',
    Heading_2XL: 'typescale-heading-2xl',
    Heading_XL: 'typescale-heading-xl',
    Heading_LG: 'typescale-heading-lg',
    Heading_MD: 'typescale-heading-md',
    // Paragraphs
    Paragraph_3XL_Regular: 'typescale-paragraph-3xl-regular',
    Paragraph_3XL_Semibold: 'typescale-paragraph-3xl-semibold',
    Paragraph_3XL_Bold: 'typescale-paragraph-3xl-bold',
    Paragraph_2XL_Regular: 'typescale-paragraph-2xl-regular',
    Paragraph_2XL_Semibold: 'typescale-paragraph-2xl-semibold',
    Paragraph_2XL_Bold: 'typescale-paragraph-2xl-bold',
    Paragraph_XL_Regular: 'typescale-paragraph-xl-regular',
    Paragraph_XL_Semibold: 'typescale-paragraph-xl-semibold',
    Paragraph_LG_Regular: 'typescale-paragraph-lg-regular',
    Paragraph_LG_Semibold: 'typescale-paragraph-lg-semibold',
    Paragraph_LG_Bold: 'typescale-paragraph-lg-bold',
    Paragraph_MD_Regular: 'typescale-paragraph-md-regular',
    Paragraph_MD_Semibold: 'typescale-paragraph-md-semibold',
    Paragraph_MD_Bold: 'typescale-paragraph-md-bold',
    Paragraph_SM_Regular: 'typescale-paragraph-sm-regular',
    Paragraph_SM_Semibold: 'typescale-paragraph-sm-semibold',
    Paragraph_SM_Bold: 'typescale-paragraph-sm-bold',
    Paragraph_XS_Regular: 'typescale-paragraph-xs-regular',
    Paragraph_XS_Semibold: 'typescale-paragraph-xs-semibold',
    Paragraph_XS_Bold: 'typescale-paragraph-xs-bold',
    // Labels
    Label_3XL_Regular: 'typescale-label-3xl-regular',
    Label_3XL_Semibold: 'typescale-label-3xl-semibold',
    Label_XL_Regular: 'typescale-label-xl-regular',
    Label_XL_Semibold: 'typescale-label-xl-semibold',
    Label_XL_Bold: 'typescale-label-xl-bold',
    Label_MD_Regular: 'typescale-label-md-regular',
    Label_MD_Semibold: 'typescale-label-md-semibold',
    Label_MD_Bold: 'typescale-label-md-bold',
    Label_SM_Regular: 'typescale-label-sm-regular',
    Label_SM_Semibold: 'typescale-label-sm-semibold',
    Label_XS_Regular: 'typescale-label-xs-regular',
    Label_XS_Semibold: 'typescale-label-xs-semibold',
    Label_XSS_Regular: 'typescale-label-xss-regular',
    // Button labels
    Buttonlabel_MD_Regular: 'typescale-buttonlabel-md-regular',
    Buttonlabel_MD_Semibold: 'typescale-buttonlabel-md-semibold',
    Buttonlabel_MD_Bold: 'typescale-buttonlabel-md-bold',
    Buttonlabel_SM_Regular: 'typescale-buttonlabel-sm-regular',
    Buttonlabel_SM_Semibold: 'typescale-buttonlabel-sm-semibold',
    Buttonlabel_SM_Bold: 'typescale-buttonlabel-sm-bold',
    Buttonlabel_XS_Regular: 'typescale-buttonlabel-xs-regular',
    Buttonlabel_XS_Semibold: 'typescale-buttonlabel-xs-semibold',
    Buttonlabel_XS_Bold: 'typescale-buttonlabel-xs-bold',
    // Input labels
    Inputlabel_MD_Regular: 'typescale-inputlabel-md-regular',
    Inputlabel_MD_Semibold: 'typescale-inputlabel-md-semibold',
    Inputlabel_SM_Regular: 'typescale-inputlabel-sm-regular',
    Inputlabel_SM_Semibold: 'typescale-inputlabel-sm-semibold',
    Inputlabel_SM_Bold: 'typescale-inputlabel-sm-bold',
    Inputlabel_XS_Regular: 'typescale-inputlabel-xs-regular',
    Inputlabel_XS_Semibold: 'typescale-inputlabel-xs-semibold',
    // Input text
    Inputtext_MD_Regular: 'typescale-inputtext-md-regular',
    Inputtext_MD_Bold: 'typescale-inputtext-md-bold',
    Inputtext_SM_Regular: 'typescale-inputtext-sm-regular',
    Inputtext_SM_Bold: 'typescale-inputtext-sm-bold',
    Inputtext_XS_Regular: 'typescale-inputtext-xs-regular',
    Inputtext_XS_Bold: 'typescale-inputtext-xs-bold',
    // Links
    Link_XL_Regular: 'typescale-link-xl-regular',
    Link_MD_Regular: 'typescale-link-md-regular',
    Link_SM_Regular: 'typescale-link-sm-regular',
    Link_XS_Regular: 'typescale-link-xs-regular'
}

export default TypeScale
