import { useTranslation } from 'react-i18next'

import { EntityListTooltip } from '../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'

import { CourseRoundListTooltipProps } from './types'

export const CourseRoundListTooltip = ({ title, courseRounds }: CourseRoundListTooltipProps) => {
    const { t } = useTranslation()

    return (
        <EntityListTooltip
            title={title}
            entities={courseRounds}
            entityLabelFn={(cr) => cr.getDisplayName()}
            entityKeyFn={(cr) => cr.getCourseRoundId()}
            singularEntityLabel={t('CourseRound.Singular')}
            pluralEntityLabel={t('CourseRound.Plural')}
        />
    )
}
