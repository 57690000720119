import type {
    ExtendedRefs,
    FloatingArrowProps,
    FloatingContext as FloatingContextType,
    ReferenceType,
} from '@floating-ui/react';
import type { CSSProperties, HTMLProps, RefObject } from 'react';
import { createContext, useContext } from 'react';

type FloatingContextState = {
    floatingStyles: CSSProperties;
    refs: ExtendedRefs<ReferenceType> | null;
    showFloatingElement: boolean;
    floatingTransitionStyles: CSSProperties;
    arrowRef: RefObject<SVGSVGElement>;
    context: FloatingContextType | null;
    arrowEnabled: boolean;
    arrowProps: Partial<FloatingArrowProps> | undefined;
    getReferenceProps: (
        userProps?: HTMLProps<HTMLElement>
    ) => Record<string, unknown>;
    getFloatingProps: (
        userProps?: HTMLProps<HTMLElement>
    ) => Record<string, unknown>;
};

const FloatingContext = createContext<FloatingContextState>({
    floatingStyles: {},
    refs: null,
    showFloatingElement: false,
    floatingTransitionStyles: {},
    arrowRef: { current: null },
    context: null,
    arrowEnabled: false,
    arrowProps: undefined,
    getReferenceProps: () => ({}),
    getFloatingProps: () => ({}),
});

const FloatingProvider = FloatingContext.Provider;

export const useFloatingContext = () => useContext(FloatingContext);

export default FloatingProvider;
