import { useTranslation } from 'react-i18next'

import { CourseRoundAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { isProblemRelatedToCourseRound, isProblemRelatedToTeachingAssignment } from '../../../../utils/problems'
import { ProblemCorner } from '../../../schedule/components/ProblemCorner'
import { toProblemListProblem } from '../../../schedule/components/ProblemsList/utils'
import { DraggableCourseRound } from '../../CourseRound'
import { Positioned } from '../../Positioned'

type SortedCourseRoundsProps = {
    sortedCourseRounds: {
        row: number
        crws: {
            cr: CourseRoundAccessor
            startHour: number
            totalHours: number
        }
    }[]
    maxVisibleHours: number
    draggedCrIds: Set<string>
    onCourseRoundClick: (isShiftClick: boolean, courseRound: CourseRoundAccessor) => void
    onStartDrag: (courseRoundId: string) => void
    onEndDrag: (courseRoundId: string) => void
    hasSelectedCourseRounds: (courseRoundId: string) => boolean
    hasFilteredCourseRound: (courseRoundId: string) => boolean
    hasFilteredCourseOptions: (courseId: string) => boolean
}

export function SortedCourseRounds({
    sortedCourseRounds,
    maxVisibleHours,
    draggedCrIds,
    onCourseRoundClick,
    onStartDrag,
    onEndDrag,
    hasSelectedCourseRounds,
    hasFilteredCourseRound,
    hasFilteredCourseOptions
}: SortedCourseRoundsProps) {
    const { t } = useTranslation()
    const schedule = useLocalSchedule()

    return (
        <>
            {sortedCourseRounds.map(({ crws, row }) => {
                const courseId = crws.cr.getCourse()?.getCourseId() ?? ''
                const isInFilter =
                    hasFilteredCourseRound(crws.cr.getCourseRoundId()) || hasFilteredCourseOptions(courseId)

                return (
                    <Positioned
                        key={crws.cr.getCourseRoundId()}
                        row={row}
                        startHour={crws.startHour}
                        totalHours={crws.totalHours}
                        maxVisibleHours={maxVisibleHours}
                        visible={!draggedCrIds.has(crws.cr.getCourseRoundId())}
                    >
                        {isInFilter && (
                            <ProblemCorner
                                problems={schedule
                                    .getSchedulingProblems()
                                    .filter(
                                        (pws) =>
                                            isProblemRelatedToTeachingAssignment(pws.problem) &&
                                            isProblemRelatedToCourseRound(pws.problem, crws.cr.getCourseRoundId())
                                    )
                                    .map((pws) => toProblemListProblem(schedule, pws, t))}
                            />
                        )}
                        <DraggableCourseRound
                            onClick={(event) => {
                                onCourseRoundClick(event.shiftKey, crws.cr)
                            }}
                            courseRoundId={crws.cr.getCourseRoundId()}
                            row={row}
                            onStartDrag={() => {
                                onStartDrag(crws.cr.getCourseRoundId())
                            }}
                            onEndDrag={() => {
                                onEndDrag(crws.cr.getCourseRoundId())
                            }}
                            selected={hasSelectedCourseRounds(crws.cr.getCourseRoundId())}
                            isInFilter={isInFilter}
                        />
                    </Positioned>
                )
            })}
        </>
    )
}
