import { Column } from '@silevis/reactgrid'

export enum StudentGroupsGridMetaDataColumn {
    RowIndex = 0
}

export enum StudentGroupsGridContentColumn {
    DisplayName = 1,
    Description = 2,
    Labels = 3
}

export const ALL_CONTENT_COLUMNS = [
    StudentGroupsGridContentColumn.DisplayName,
    StudentGroupsGridContentColumn.Description,
    StudentGroupsGridContentColumn.Labels
]

export type StudentGroupGridRow = Record<StudentGroupsGridContentColumn, string> & {
    [StudentGroupsGridMetaDataColumn.RowIndex]: number
}

const ALL_COLUMNS = [StudentGroupsGridMetaDataColumn.RowIndex, ...ALL_CONTENT_COLUMNS]

export const NUM_STUDENT_GROUPS_COLUMNS = ALL_COLUMNS.length

export type StudentGroupsColumnSpec = Column & {
    title: string
    gridColumn: StudentGroupsGridMetaDataColumn | StudentGroupsGridContentColumn
}
