import { Flex, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import brandColors from '../../styles/colors/brandColors'
import { nullToUndefined } from '../../utils/miscUtil'
import { MinutesInput } from '../MinutesInput'
import { LectureDurationOverrideDropdown } from '../schedule-settings/LectureDurationOverrideDropdown'

import { LectureDurationThresholdsInputProps } from './types'


export const LectureDurationThresholdsInput = ({
    onChange,
    value,
    inheritedValues,
    inheritLabels
}: LectureDurationThresholdsInputProps) => {
    const { t } = useTranslation()

    const inheritValues = value === undefined && inheritedValues !== undefined

    const valueToPresent = inheritValues ? inheritedValues : value

    const softMin = nullToUndefined(valueToPresent?.minDuration.softMinutes)
    const hardMin = nullToUndefined(valueToPresent?.minDuration.hardMinutes)
    const softMax = nullToUndefined(valueToPresent?.maxDuration.softMinutes)
    const hardMax = nullToUndefined(valueToPresent?.maxDuration.hardMinutes)

    type OnChangeArguments = {
        newInheritValues?: boolean
        newSoftMin?: number
        newSoftMax?: number
        newHardMin?: number
        newHardMax?: number
    }

    const onChangeWrapper = ({
        newInheritValues = inheritValues,
        newHardMax = hardMax,
        newHardMin = hardMin,
        newSoftMax = softMax,
        newSoftMin = softMin
    }: OnChangeArguments) => {
        if (typeof onChange === 'function') {
            onChange(
                newInheritValues
                    ? undefined
                    : {
                          minDuration: { softMinutes: newSoftMin, hardMinutes: newHardMin },
                          maxDuration: { softMinutes: newSoftMax, hardMinutes: newHardMax }
                      }
            )
        }
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Flex style={{ height: '32px' }} align="center">
                <div style={{ flex: 1 }}>
                    {t('Lectures')} <strong style={{ color: brandColors.warning }}>{t('ShouldPreferablyBe')} </strong>
                    {t('Between')}
                </div>
                {inheritLabels && (
                    <LectureDurationOverrideDropdown
                        inheritLabels={inheritLabels}
                        value={inheritValues}
                        onChange={(newInheritValues) => onChangeWrapper({ newInheritValues })}
                    />
                )}
            </Flex>
            <Space style={{ marginLeft: '1em' }}>
                <MinutesInput
                    readOnly={inheritValues}
                    value={softMin}
                    onChange={(newSoftMin) => onChangeWrapper({ newSoftMin })}
                />
                <div>{t('And')}</div>
                <MinutesInput
                    readOnly={inheritValues}
                    value={softMax}
                    onChange={(newSoftMax) => onChangeWrapper({ newSoftMax })}
                />
            </Space>
            <Flex style={{ height: '32px' }} align="center">
                <span>
                    {t('MinutesLongBut')} <strong style={{ color: brandColors.blocker }}>{t('HaveToBe')} </strong>
                    {t('Between')}
                </span>
            </Flex>
            <Space style={{ marginLeft: '1em' }}>
                <MinutesInput
                    readOnly={inheritValues}
                    value={hardMin}
                    onChange={(newHardMin) => onChangeWrapper({ newHardMin })}
                />
                <div>{t('And')}</div>
                <MinutesInput
                    readOnly={inheritValues}
                    value={hardMax}
                    onChange={(newHardMax) => onChangeWrapper({ newHardMax })}
                />
            </Space>
        </Space>
    )
}
