import { ScheduleId } from '../../commonTypes'
import { AppError } from '../../utils/errorutil'
import { ApplicationState } from '../index'

import { isAuthenticated } from './types'


export function getActiveOrgId({ authState }: ApplicationState): ScheduleId {
    if (!isAuthenticated(authState)) {
        throw new AppError('Expected user to be authenticated.', { authState })
    }

    return authState.activeOrgId
}
