import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement } from 'react'

import DropdownButtonItem from './DropdownButtonItem'
import DropdownLinkItem from './DropdownLinkItem'
import classes from './style.module.css'
import type { DropdownProps } from './types'

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({ size, ...props }, ref) => {
    const children = Children.map(props.children, (child) => {
        if (!isValidElement(child)) {
            return null
        }

        return cloneElement(child, {
            ...child.props,
            size
        })
    })

    return (
        <div
            ref={ref}
            {...props}
            className={classNames(classes.dropdown, [classes[`dropdown--size-${size}`]], props.className)}
        >
            {children}
        </div>
    )
})

export default Object.assign(Dropdown, {
    Item: DropdownButtonItem,
    LinkItem: DropdownLinkItem
})
