import { ISubject } from 'common-api'

import { ScheduleAccessor, SubjectAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { comparing } from '../../../../../utils/compareUtil'

import { SubjectGridRow, SubjectGridContentColumn, SubjectGridMetaDataColumn } from './types'

export const sortedSubjectsFromSchedule = (schedule: ScheduleAccessor) => {
    return schedule.getSubjects().toSorted(comparing((subject) => subject.getName()))
}

export const conjureSubjectsFromSubjects = (subjects: SubjectAccessor[]): ISubject[] => {
    return subjects.map((subject) => subject.getConjureObject())
}

export const isEmptySubjectRow = (subjectRow: SubjectGridRow) => {
    return [SubjectGridContentColumn.Name, SubjectGridContentColumn.Code].every((col) => subjectRow[col].trim() === '')
}

export const generateEmptySubjectRow = (rowIndex: number): SubjectGridRow => {
    return {
        [SubjectGridMetaDataColumn.RowIndex]: rowIndex,
        [SubjectGridContentColumn.Name]: '',
        [SubjectGridContentColumn.Code]: ''
    }
}
