import { DayOfWeek } from 'common-api'
import { action, ActionType } from 'typesafe-actions'

import type * as weekDaysSelectorActions from './actions'
import { WeekDaySelectorType } from './types'

export const setSelectedWeekDays = (selectedWeekDays: DayOfWeek[]) =>
    action(WeekDaySelectorType.SET_SELECTED_WEEK_DAYS, selectedWeekDays)

export type WeekDaysSelectorAction = ActionType<typeof weekDaysSelectorActions>
