import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { IStudentGroup } from 'common-api'
import { useTranslation } from 'react-i18next'
import { DiffCard } from '../../../../../../EditTable/components/DiffCard'
import { DiffResult } from '../../../../../../EditTable/utils'

type StudentGroupListDiffProps = {
    diff: DiffResult<IStudentGroup>
}

const StudentGroupListDiff = ({ diff: diffToShow }: StudentGroupListDiffProps) => {
    const { t } = useTranslation()

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue === newValue ? null : (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    return (
        <Flex gap={8}>
            <DiffCard
                title={t('StudentGroupListChanges.NewStudentGroups')}
                icon={<PlusOutlined />}
                noChangesText={t('StudentGroupListChanges.NoNewStudentGroups')}
                changes={diffToShow.created}
                renderChange={(studentGroup) => <li key={studentGroup.studentGroupId}>{studentGroup.displayName}</li>}
            />
            <DiffCard
                title={t('StudentGroupListChanges.UpdatedStudentGroups')}
                icon={<EditOutlined />}
                noChangesText={t('StudentGroupListChanges.NoUpdatedStudentGroups')}
                changes={diffToShow.updated}
                renderChange={([prev, next]) => (
                    <li key={prev.studentGroupId}>
                        {prev.displayName}
                        <ul>
                            {propertyDiffListItem(t('StudentGroup'), prev.displayName, next.displayName)}
                            {propertyDiffListItem(t('StudentGroupId'), prev.studentGroupId, next.studentGroupId)}
                            {propertyDiffListItem(t('Description'), prev.description, next.description)}
                            {propertyDiffListItem(t('Labels'), prev.labels.join(','), next.labels.join(','))}
                        </ul>
                    </li>
                )}
            />
            <DiffCard
                title={t('StudentGroupListChanges.DeletedStudentGroups')}
                icon={<MinusOutlined />}
                noChangesText={t('StudentGroupListChanges.NoDeletedStudentGroups')}
                changes={diffToShow.deleted}
                renderChange={(studentGroup) => <li key={studentGroup.studentGroupId}>{studentGroup.displayName}</li>}
            />
        </Flex>
    )
}

export default StudentGroupListDiff
