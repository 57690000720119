import { ProblemListProblem } from '../../pages/schedule/components/ProblemsList/types'

type EditTableProblemListProps = {
    problems: ProblemListProblem[]
}

export const EditTableProblemList = ({ problems }: EditTableProblemListProps) => (
    <ul>
        {problems.map((p, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{p.messageText}</li>
        ))}
    </ul>
)
