import { IRoom } from 'common-api'
import { v4 as uuid } from 'uuid'

import { matchRowsWithExistingData } from '../../../../EditTable/data'
import { combinedCmpFn, comparing, stringCmp } from '../../../../utils/compareUtil'

import { RoomGridRow, RoomGridContentColumn, RoomGridMetaDataColumn } from './types'
import { isEmptyRoomsRow } from './utils'

export const roomCmp = combinedCmpFn<IRoom>(
    comparing((r) => r.name),
    comparing((r) => r.attributes.toSorted(stringCmp).join(','))
)

const matchScore = (row: RoomGridRow, room: IRoom) => (room.name === row[RoomGridContentColumn.Name] ? 1 : 0)

export const parseRoomRows = (existingRooms: IRoom[], rowsIncludingEmpty: RoomGridRow[]): IRoom[] => {
    const rows = rowsIncludingEmpty.filter((row) => !isEmptyRoomsRow(row))

    const matchedRooms = matchRowsWithExistingData<IRoom, RoomGridRow>({
        existingData: existingRooms,
        rows,
        matchRow: RoomGridMetaDataColumn.RowIndex,
        matchScore: (row, room) => matchScore(row, room),
        maximumScore: 1,
        minimumAcceptableScore: 1
    })

    return rows.map((row) => {
        const matchingRoom = matchedRooms.get(row[RoomGridMetaDataColumn.RowIndex])
        const attributes = row[RoomGridContentColumn.Attributes]
            .split(',')
            .map((attr) => attr.trim())
            .filter((attr) => attr !== '')

        return {
            roomId: matchingRoom?.roomId ?? uuid(),
            name: row[RoomGridContentColumn.Name],
            attributes
        }
    })
}
