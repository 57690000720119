import { Modal } from 'antd'
import { ICourse, IScheduleTransform } from 'common-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import BulkUpdatePreview from '../../../../../EditTable/components/BulkUpdatePreview'
import { diff } from '../../../../../EditTable/utils'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { conjureSubjectsFromSubjects } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'

import CourseListDiff from './components/CourseListDiff'
import CoursesEditGrid from './CoursesEditGrid'
import { courseCmp, parseCoursesRows } from './data'
import { useCoursesTableDataState } from './hooks'
import { conjureCourseFromCourses, sortedCoursesFromSchedule } from './utils'
import { globalValidationErrors } from './validation'

const CoursesEditTable = () => {
    const { t } = useTranslation()

    const schedule = useLocalSchedule()
    const liveCourses = conjureCourseFromCourses(sortedCoursesFromSchedule(schedule))
    const [coursesWhenEditStarted, setCoursesWhenEditStarted] = useState(liveCourses)

    const { courseGridData, setCourses, resetCourses } = useCoursesTableDataState()
    const dispatch = useDispatch()

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())

    const onSave = () => {
        const parsedCourses = parseCoursesRows({
            existingCourses: liveCourses,
            existingSubjects: liveSubjects,
            rowsIncludingEmpty: courseGridData
        })
        const diffToSave = diff(liveCourses, parsedCourses, (course) => course.courseId, courseCmp)

        const courseTransforms = [
            ...diffToSave.deleted.map((courseToDelete) =>
                IScheduleTransform.courseDeleteTransform({ courseId: courseToDelete.courseId })
            ),
            ...diffToSave.updated.map(([_, newCourse]) => IScheduleTransform.courseTransform({ newCourse })),
            ...diffToSave.created.map((courseToCreate) =>
                IScheduleTransform.courseTransform({ newCourse: courseToCreate })
            )
        ]

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(courseTransforms)))
        setIsPreviewOpen(false)

        setCoursesWhenEditStarted(parsedCourses)
    }

    const newCourses = parseCoursesRows({
        existingCourses: liveCourses,
        existingSubjects: liveSubjects,
        rowsIncludingEmpty: courseGridData
    })
    const diffPreview = diff(liveCourses, newCourses, (course) => course.courseId, courseCmp)

    const validationErrors = globalValidationErrors(schedule, courseGridData, liveSubjects)

    return (
        <>
            <Modal
                open={isPreviewOpen}
                closeIcon={false}
                style={{ maxWidth: 'min(80em, calc(100% - 32px))' }}
                destroyOnClose
                width="auto"
                okText={t('Save')}
                onOk={onSave}
                okButtonProps={{
                    disabled: validationErrors.length > 0
                }}
                cancelText={t('Cancel')}
                onCancel={() => {
                    setIsPreviewOpen(false)
                }}
            >
                <BulkUpdatePreview<ICourse>
                    diff={diffPreview}
                    validationErrors={validationErrors}
                    DiffComponent={CourseListDiff}
                />
            </Modal>
            <CoursesEditGrid
                courses={courseGridData}
                setCourses={setCourses}
                onReset={resetCourses}
                onSave={() => {
                    setIsPreviewOpen(true)
                }}
                coursesWhenEditingStarted={coursesWhenEditStarted}
            />
        </>
    )
}

export default CoursesEditTable
