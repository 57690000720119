import type { SidebarMenuItem } from './types'

/**
 * Returns true if the menu item should be shown based on its visibility and the visibility of its subItems
 */
export const shouldMenuItemBeShown = (menuItem: SidebarMenuItem) => {
    // Menu item is explicitly hidden
    if (menuItem.visible === false) {
        return false
    }

    const hasVisibleSubItems = menuItem.subItems?.some((subItem) => subItem.visible !== false)

    // If the menu item has no subItems, it should be shown, otherwise it should be shown if at least one of its subItems is visible
    return hasVisibleSubItems || menuItem.subItems === undefined
}
