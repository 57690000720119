import FloatingElement from './FloatingElement'
import type { FloatingRootProps } from './FloatingRoot'
import FloatingRoot from './FloatingRoot'
import FloatingTarget from './FloatingTarget'

// Transitions
export { getScaleUpTransition, getSlideTransition } from './transitions'

const Floating = {
    Root: FloatingRoot,
    Target: FloatingTarget,
    Element: FloatingElement
}

export type { FloatingRootProps }

export default Floating
