import type {
    ArrowOptions,
    AutoPlacementOptions,
    FlipOptions,
    FloatingArrowProps,
    HideOptions,
    InlineOptions,
    OffsetOptions,
    Placement,
    ShiftOptions,
    SizeOptions,
    UseClickProps,
    UseDismissProps,
    UseFocusProps,
    UseHoverProps,
    UseRoleProps,
    UseTransitionStylesProps
} from '@floating-ui/react'
import {
    arrow,
    autoPlacement,
    autoUpdate,
    flip,
    hide,
    inline,
    offset,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    useTransitionStyles
} from '@floating-ui/react'
import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import FloatingProvider from '../FloatingProvider'
import type { PreventOverflowOptions } from '../middleware/preventOverflow'
import preventOverflow from '../middleware/preventOverflow'

type Interaction = 'click' | 'hover' | 'focus' | 'dismiss'

type Middleware =
    | 'offset'
    | 'shift'
    | 'flip'
    | 'arrow'
    | 'size'
    | 'autoPlacement'
    | 'hide'
    | 'inline'
    | 'autoUpdate'
    | 'preventOverflow'

export type FloatingRootProps = {
    placement?: Placement
    open?: boolean
    onChangeOpen?: (nextOpen: boolean) => void
    children: ReactNode
    enableTransition?: boolean
    enabled?: boolean
    // Interactions
    interactions?: Interaction[]
    clickOptions?: UseClickProps
    hoverOptions?: UseHoverProps
    focusOptions?: UseFocusProps
    dismissOptions?: UseDismissProps
    roleOptions?: UseRoleProps
    // Middlewares
    middleware?: Middleware[]
    offsetOptions?: OffsetOptions
    shiftOptions?: ShiftOptions
    flipOptions?: FlipOptions
    arrowOptions?: Omit<ArrowOptions, 'element'>
    sizeOptions?: SizeOptions
    autoPlacementOptions?: AutoPlacementOptions
    hideOptions?: HideOptions
    inlineOptions?: InlineOptions
    transitionOptions?: UseTransitionStylesProps
    preventOverflowOptions?: PreventOverflowOptions
    // Other
    arrowProps?: Partial<FloatingArrowProps>
    positionMethod?: 'transform' | 'absolute'
}

const FloatingRoot = ({
    placement = 'bottom',
    open: externalOpen,
    onChangeOpen: externalOnChangeOpen,
    interactions = ['click', 'dismiss'],
    children,
    enabled = true,
    enableTransition = false,
    clickOptions,
    hoverOptions,
    focusOptions,
    dismissOptions,
    roleOptions,
    middleware = ['shift', 'flip', 'autoUpdate'],
    offsetOptions,
    shiftOptions,
    flipOptions,
    arrowOptions,
    sizeOptions,
    autoPlacementOptions,
    hideOptions,
    inlineOptions,
    transitionOptions,
    preventOverflowOptions,
    arrowProps,
    positionMethod = 'transform'
}: FloatingRootProps) => {
    const [open, setOpen] = useState(false)

    const arrowRef = useRef<SVGSVGElement>(null)

    const handleOpenChange = (nextOpen: boolean) => {
        setOpen(nextOpen)
        externalOnChangeOpen?.(nextOpen)
    }

    const { floatingStyles, refs, context } = useFloating({
        placement,
        transform: positionMethod === 'transform',
        open,
        onOpenChange: handleOpenChange,
        middleware: [
            ...(middleware.includes('offset') ? [offset(offsetOptions)] : []),
            ...(middleware.includes('shift') ? [shift(shiftOptions)] : []),
            ...(middleware.includes('flip') ? [flip(flipOptions)] : []),
            ...(middleware.includes('arrow') ? [arrow({ element: arrowRef, ...arrowOptions })] : []),
            ...(middleware.includes('size') ? [size(sizeOptions)] : []),
            ...(middleware.includes('autoPlacement') ? [autoPlacement(autoPlacementOptions)] : []),
            ...(middleware.includes('hide') ? [hide(hideOptions)] : []),
            ...(middleware.includes('inline') ? [inline(inlineOptions)] : []),
            ...(middleware.includes('preventOverflow') ? [preventOverflow(preventOverflowOptions)] : [])
        ],
        whileElementsMounted: middleware.includes('autoUpdate') ? autoUpdate : undefined
    })

    const click = useClick(context, {
        ...clickOptions,
        enabled: interactions.includes('click') && enabled
    })
    const hover = useHover(context, {
        ...hoverOptions,
        enabled: interactions.includes('hover') && enabled
    })
    const focus = useFocus(context, {
        ...focusOptions,
        enabled: interactions.includes('focus') && enabled
    })
    const dismiss = useDismiss(context, {
        outsidePressEvent: 'click',
        ...dismissOptions,
        enabled: interactions.includes('dismiss') && enabled
    })
    const role = useRole(context, roleOptions)

    const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, focus, dismiss, role])

    const { isMounted, styles } = useTransitionStyles(context, transitionOptions)
    const showFloatingElement = enableTransition ? isMounted : open
    const floatingTransitionStyles = enableTransition ? styles : {}

    // Update the open state when the external open state changes
    useEffect(() => {
        if (externalOpen !== undefined) {
            setOpen(externalOpen)
        }
    }, [externalOpen])

    return (
        <FloatingProvider
            value={{
                floatingStyles,
                refs,
                showFloatingElement,
                floatingTransitionStyles,
                arrowRef,
                context,
                arrowEnabled: middleware.includes('arrow'),
                arrowProps,
                getReferenceProps,
                getFloatingProps
            }}
        >
            {children}
        </FloatingProvider>
    )
}

export default FloatingRoot
