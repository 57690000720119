import { CellWrapper, useCellContext } from '@silevis/reactgrid'
import { CSSProperties, KeyboardEvent, useEffect, useRef, useState } from 'react'

import { ProblemCorner } from '../../../pages/schedule/components/ProblemCorner'

import { keyCodes } from './keyCodes'
import { TextCellWithValidationProps } from './types'

export const isKeyCodeAlphaNumeric = (keyCode: number): boolean =>
    (keyCode >= keyCodes.KEY_0 && keyCode <= keyCodes.KEY_9) || // 0-9
    (keyCode >= keyCodes.KEY_A && keyCode <= keyCodes.KEY_Z) || // A-Z
    (keyCode >= keyCodes.NUMPAD_0 && keyCode <= keyCodes.NUMPAD_9) // Numpad 0-9

export const isAlphaNumericWithoutModifiers = (e: KeyboardEvent): boolean => {
    const isAlphaNumericOrEnter = isKeyCodeAlphaNumeric(e.keyCode) || 'åäö'.includes(e.key.toLowerCase())
    const noModifiers = !e.ctrlKey && !e.metaKey && !e.altKey

    return isAlphaNumericOrEnter && noModifiers
}

export const TextCellWithValidation = ({
    text: initialText,
    onTextChanged,
    validationProblems
}: TextCellWithValidationProps) => {
    const ctx = useCellContext()
    const targetInputRef = useRef<HTMLInputElement>(null)
    const [isEditMode, setEditMode] = useState(false)
    const [currentValue, setCurrentValue] = useState(initialText || '')

    useEffect(() => {
        setCurrentValue(initialText)
    }, [initialText])

    return (
        <CellWrapper
            onStringValueRequsted={() => initialText}
            onStringValueReceived={(v) => {
                onTextChanged(v)
            }}
            style={{ padding: '.2rem', textAlign: 'center', outline: 'none', minHeight: 0 }}
            onDoubleClick={() => {
                if (ctx.isFocused) {
                    setCurrentValue(initialText || '')
                    setEditMode(true)
                }
            }}
            onKeyDown={(e) => {
                if (!isEditMode && isAlphaNumericWithoutModifiers(e)) {
                    setCurrentValue('')
                    setEditMode(true)
                } else if (!isEditMode && e.key === 'Enter') {
                    e.stopPropagation()
                    setCurrentValue(initialText || '')
                    setEditMode(true)
                }
            }}
        >
            {isEditMode ? (
                <input
                    value={currentValue}
                    style={inputStyle}
                    onChange={(e) => {
                        setCurrentValue(e.currentTarget.value)
                    }}
                    onBlur={(e) => {
                        onTextChanged(e.currentTarget.value)
                        setEditMode(false)
                    }}
                    onCut={(e) => {
                        e.stopPropagation()
                    }}
                    onCopy={(e) => {
                        e.stopPropagation()
                    }}
                    onPaste={(e) => {
                        e.stopPropagation()
                    }}
                    onPointerDown={(e) => {
                        e.stopPropagation()
                    }}
                    onKeyDown={(e) => {
                        const controlKeys = ['Escape', 'Enter', 'Tab']
                        if (!controlKeys.includes(e.key)) {
                            e.stopPropagation()
                        }

                        if (e.key === 'Escape') {
                            setEditMode(false)
                        } else if (e.key === 'Enter') {
                            onTextChanged(e.currentTarget.value)
                            setEditMode(false)
                        }
                    }}
                    autoFocus
                    ref={targetInputRef}
                />
            ) : (
                <>
                    <ProblemCorner roundedCorner={false} problems={validationProblems} />
                    {initialText}
                </>
            )}
        </CellWrapper>
    )
}

const inputStyle: CSSProperties = {
    resize: 'none',
    overflowY: 'hidden',
    boxSizing: 'border-box',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    background: 'transparent',
    border: 'none',
    padding: 0,
    outline: 'none',
    color: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit'
}
