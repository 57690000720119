import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { ISubject } from 'common-api'
import { useTranslation } from 'react-i18next'

import { DiffCard } from '../../../../../../EditTable/components/DiffCard'
import { DiffResult } from '../../../../../../EditTable/utils'


type SubjectListDiffProps = {
    diff: DiffResult<ISubject>
}

const SubjectListDiff = ({ diff }: SubjectListDiffProps) => {
    const { t } = useTranslation()

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue === newValue ? null : (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    return (
        <Flex gap={8}>
            <DiffCard
                title={t('SubjectListChanges.NewSubjects')}
                icon={<PlusOutlined />}
                noChangesText={t('SubjectListChanges.NoNewSubjects')}
                changes={diff.created}
                renderChange={(subject) => <li key={subject.subjectId}>{subject.name}</li>}
            />
            <DiffCard
                title={t('SubjectListChanges.UpdatedSubjects')}
                icon={<EditOutlined />}
                noChangesText={t('SubjectListChanges.NoUpdatedSubjects')}
                changes={diff.updated}
                renderChange={([prev, next]) => (
                    <li key={prev.subjectId}>
                        {prev.name}
                        <ul>
                            {propertyDiffListItem(t('Subject'), prev.name, next.name)}
                            {propertyDiffListItem(t('SubjectId'), prev.subjectId, next.subjectId)}
                            {propertyDiffListItem(t('SubjectCode'), prev.code, next.code)}
                        </ul>
                    </li>
                )}
            />
            <DiffCard
                title={t('SubjectListChanges.DeletedSubjects')}
                icon={<MinusOutlined />}
                noChangesText={t('SubjectListChanges.NoDeletedSubjects')}
                changes={diff.deleted}
                renderChange={(subject) => <li key={subject.subjectId}>{subject.name}</li>}
            />
        </Flex>
    )
}

export default SubjectListDiff
