import type { DayOfWeek, IProblemWithScore, IScore } from 'common-api'
import { IProblem } from 'common-api'

import type { CourseRoundId, LectureId, StudentGroupId, TeacherId, Week } from '../commonTypes'
import { ProblemListProblem } from '../pages/schedule/components/ProblemsList/types'
import { numBlockers, numWarnings } from '../pages/schedule/components/ProblemsList/utils'
import type { ScheduleAccessor, StudentGroupAccessor } from '../schedule-access/scheduleAccessWrappers'
import brandColors from '../styles/colors/brandColors'

import { comparing, reverseCmp } from './compareUtil'
import { AppError } from './errorutil'

export enum ProblemSeverity {
    NONE = 0,
    WARNING = 1,
    BLOCKER = 2
}

export const isProblemRelatedToLecture = (problem: IProblem, lectureId: LectureId) =>
    relatedLectures(problem).includes(lectureId)

const relatedLectures = (problem: IProblem): LectureId[] =>
    IProblem.visit(problem, {
        endTimeProblem: (p) => [p.lectureId],
        teacherBreakProblem: (p) => [p.breakProblem.afterLectureId],
        teacherConflictProblem: (p) => [p.lectureId1, p.lectureId2],
        roomConflictProblem: (p) => [p.lectureId1, p.lectureId2],
        roomReservedTimeConflictProblem: (p) => [p.lectureId],
        requiredRoomAttributeProblem: (p) => [p.lectureId],
        noRoomSetProblem: (p) => [p.lectureId],
        unscheduledLectureProblem: (p) => [p.lectureId],
        scheduleOverrunProblem: (p) => [p.lectureId],
        sameCourseMultipleTimesOnSameDayProblem: (p) => p.lectureIds,
        sameSubjectMultipleTimesOnSameDayProblem: (p) => p.lectureIds,
        studentGroupConflictProblem: (p) => [p.lectureId1, p.lectureId2],
        studentGroupLunchProblem: () => [],
        teacherLunchProblem: () => [],
        studentGroupBreakProblem: (p) => [p.breakProblem.afterLectureId],
        teacherReservedTimeConflictProblem: (p) => [p.lectureId],
        studentGroupReservedTimeConflictProblem: (p) => [p.lectureId],
        teacherFrameTimeProblem: () => [],
        lectureDurationProblem: (p) => [p.lectureId],
        totalCourseRoundDuration: () => [],
        unassignedCourseRoundProblem: () => [],
        teacherNotQualifiedProblem: () => [],
        teacherOverloadProblem: () => [],
        unknown: (problem: IProblem) => {
            throw new AppError('Unknown problem type', { problem })
        }
    })

export const isProblemRelatedToTeacher = (schedule: ScheduleAccessor, problem: IProblem, teacherId: TeacherId) =>
    relatedTeachers(schedule, problem).includes(teacherId)

export const isProblemRelatedToTeachingAssignment = (problem: IProblem) =>
    IProblem.visit(problem, {
        endTimeProblem: () => false,
        teacherBreakProblem: () => false,
        teacherConflictProblem: () => false,
        roomConflictProblem: () => false,
        roomReservedTimeConflictProblem: () => false,
        requiredRoomAttributeProblem: () => false,
        noRoomSetProblem: () => false,
        unscheduledLectureProblem: () => false,
        scheduleOverrunProblem: () => false,
        sameCourseMultipleTimesOnSameDayProblem: () => false,
        sameSubjectMultipleTimesOnSameDayProblem: () => false,
        studentGroupConflictProblem: () => false,
        studentGroupLunchProblem: () => false,
        teacherLunchProblem: () => false,
        studentGroupBreakProblem: () => false,
        teacherReservedTimeConflictProblem: () => false,
        studentGroupReservedTimeConflictProblem: () => false,
        teacherFrameTimeProblem: () => false,
        lectureDurationProblem: () => false,
        totalCourseRoundDuration: () => false,
        teacherNotQualifiedProblem: () => true,
        unassignedCourseRoundProblem: () => true,
        teacherOverloadProblem: () => true,
        unknown: (problem: IProblem) => {
            throw new AppError('Unknown problem type', { problem })
        }
    })

export const isProblemRelatedToCourseRound = (problem: IProblem, courseRoundId: CourseRoundId) =>
    relatedCourseRoundIds(problem).includes(courseRoundId)

const relatedCourseRoundIds = (problem: IProblem) =>
    IProblem.visit(problem, {
        endTimeProblem: () => [],
        teacherBreakProblem: () => [],
        teacherConflictProblem: () => [],
        roomConflictProblem: () => [],
        roomReservedTimeConflictProblem: () => [],
        requiredRoomAttributeProblem: () => [],
        noRoomSetProblem: () => [],
        unscheduledLectureProblem: () => [],
        scheduleOverrunProblem: () => [],
        sameCourseMultipleTimesOnSameDayProblem: () => [],
        sameSubjectMultipleTimesOnSameDayProblem: () => [],
        studentGroupConflictProblem: () => [],
        studentGroupLunchProblem: () => [],
        teacherLunchProblem: () => [],
        studentGroupBreakProblem: () => [],
        teacherReservedTimeConflictProblem: () => [],
        studentGroupReservedTimeConflictProblem: () => [],
        teacherFrameTimeProblem: () => [],
        lectureDurationProblem: () => [],
        totalCourseRoundDuration: () => [],
        teacherNotQualifiedProblem: (p) => [p.courseRoundId],
        unassignedCourseRoundProblem: (p) => [p.courseRoundId],
        teacherOverloadProblem: () => [],
        unknown: (problem: IProblem) => {
            throw new AppError('Unknown problem type', { problem })
        }
    })

const teacherIdsForLectureId = (schedule: ScheduleAccessor, lectureId: LectureId) =>
    schedule
        .findLecture(lectureId)
        .getCourseRound()
        .getTeachers()
        .map((t) => t.getTeacherId())

export const relatedTeachers = (schedule: ScheduleAccessor, problem: IProblem): TeacherId[] =>
    IProblem.visit(problem, {
        endTimeProblem: (p) => teacherIdsForLectureId(schedule, p.lectureId),
        teacherBreakProblem: (p) => [
            ...teacherIdsForLectureId(schedule, p.breakProblem.beforeLectureId),
            ...teacherIdsForLectureId(schedule, p.breakProblem.afterLectureId)
        ],
        teacherConflictProblem: (p) => [p.teacherId],
        roomConflictProblem: () => [],
        roomReservedTimeConflictProblem: () => [],
        requiredRoomAttributeProblem: () => [],
        noRoomSetProblem: () => [],
        unscheduledLectureProblem: () => [],
        scheduleOverrunProblem: () => [],
        sameCourseMultipleTimesOnSameDayProblem: () => [],
        sameSubjectMultipleTimesOnSameDayProblem: () => [],
        studentGroupConflictProblem: () => [],
        studentGroupLunchProblem: () => [],
        teacherLunchProblem: (p) => [p.teacherId],
        studentGroupBreakProblem: () => [],
        teacherReservedTimeConflictProblem: (p) => [p.teacherId],
        studentGroupReservedTimeConflictProblem: () => [],
        teacherFrameTimeProblem: (p) => [p.teacherId],
        lectureDurationProblem: () => [],
        totalCourseRoundDuration: () => [],
        teacherNotQualifiedProblem: () => [],
        unassignedCourseRoundProblem: () => [],
        teacherOverloadProblem: (p) => [p.teacherId],
        unknown: (problem: IProblem) => {
            throw new AppError('Unknown problem type', { problem })
        }
    })

export const isProblemRelatedToStudentGroup = (
    schedule: ScheduleAccessor,
    problem: IProblem,
    studentGroup: StudentGroupAccessor
) =>
    relatedStudentGroups(schedule, problem)
        .map((sgId) => schedule.findStudentGroup(sgId))
        .some((sg) => sg.doesOverlapWith(studentGroup))

const studentGroupIdForLectureId = (schedule: ScheduleAccessor, lectureId: LectureId) =>
    schedule.findLecture(lectureId).getCourseRound().getStudentGroup().getStudentGroupId()

const relatedStudentGroups = (schedule: ScheduleAccessor, problem: IProblem): StudentGroupId[] =>
    IProblem.visit(problem, {
        endTimeProblem: (p) => [studentGroupIdForLectureId(schedule, p.lectureId)],
        teacherBreakProblem: () => [],
        teacherConflictProblem: () => [],
        roomConflictProblem: () => [],
        roomReservedTimeConflictProblem: () => [],
        requiredRoomAttributeProblem: () => [],
        noRoomSetProblem: () => [],
        unscheduledLectureProblem: () => [],
        scheduleOverrunProblem: (p) => [studentGroupIdForLectureId(schedule, p.lectureId)],
        sameCourseMultipleTimesOnSameDayProblem: (p) =>
            p.lectureIds.map((lid) => studentGroupIdForLectureId(schedule, lid)),
        sameSubjectMultipleTimesOnSameDayProblem: (p) =>
            p.lectureIds.map((lid) => studentGroupIdForLectureId(schedule, lid)),
        studentGroupConflictProblem: (p) => p.studentGroupIds,
        studentGroupLunchProblem: (p) => [p.studentGroupId],
        teacherLunchProblem: () => [],
        studentGroupReservedTimeConflictProblem: (p) => [p.studentGroupId],
        studentGroupBreakProblem: (p) => [
            studentGroupIdForLectureId(schedule, p.breakProblem.afterLectureId),
            studentGroupIdForLectureId(schedule, p.breakProblem.beforeLectureId)
        ],
        teacherReservedTimeConflictProblem: () => [],
        teacherFrameTimeProblem: () => [],
        lectureDurationProblem: () => [],
        totalCourseRoundDuration: () => [],
        unassignedCourseRoundProblem: () => [],
        teacherNotQualifiedProblem: () => [],
        teacherOverloadProblem: () => [],
        unknown: (problem: IProblem) => {
            throw new AppError('Unknown problem type', { problem })
        }
    })

export type DayAndWeek = {
    day: DayOfWeek
    weeks: Week[]
}

export const relatedDayAndWeeks = (problem: IProblem): DayAndWeek | undefined =>
    IProblem.visit(problem, {
        endTimeProblem: () => undefined,
        teacherBreakProblem: () => undefined,
        teacherConflictProblem: () => undefined,
        roomConflictProblem: () => undefined,
        roomReservedTimeConflictProblem: () => undefined,
        requiredRoomAttributeProblem: () => undefined,
        noRoomSetProblem: () => undefined,
        unscheduledLectureProblem: () => undefined,
        scheduleOverrunProblem: () => undefined,
        sameCourseMultipleTimesOnSameDayProblem: () => undefined,
        sameSubjectMultipleTimesOnSameDayProblem: () => undefined,
        studentGroupConflictProblem: () => undefined,
        studentGroupLunchProblem: (p) => ({ day: p.day, weeks: p.weeks }),
        studentGroupReservedTimeConflictProblem: () => undefined,
        teacherLunchProblem: (p) => ({ day: p.day, weeks: p.weeks }),
        studentGroupBreakProblem: () => undefined,
        teacherReservedTimeConflictProblem: () => undefined,
        teacherFrameTimeProblem: () => undefined,
        lectureDurationProblem: () => undefined,
        totalCourseRoundDuration: () => undefined,
        unassignedCourseRoundProblem: () => undefined,
        teacherNotQualifiedProblem: () => undefined,
        teacherOverloadProblem: () => undefined,
        unknown: (problem: IProblem) => {
            throw new AppError('Unknown problem type', { problem })
        }
    })

export const maxSeverity = (problems: ProblemListProblem[]): ProblemSeverity =>
    numBlockers(problems) > 0
        ? ProblemSeverity.BLOCKER
        : numWarnings(problems) > 0
          ? ProblemSeverity.WARNING
          : ProblemSeverity.NONE

export const isBlocker = (problemWithScore: IProblemWithScore): boolean =>
    scoreSeverity(problemWithScore.score) === ProblemSeverity.BLOCKER

export const scoreSeverity = ({ hard, soft }: IScore): ProblemSeverity => {
    return hard < 0 ? ProblemSeverity.BLOCKER : soft < 0 ? ProblemSeverity.WARNING : ProblemSeverity.NONE
}

export const severityColor = (severity: ProblemSeverity) =>
    [brandColors.black, brandColors.warning, brandColors.blocker][severity]

// Blocker -> Warning -> None
export const problemSeverityComparator = reverseCmp<ProblemListProblem>(comparing((p) => p.severity))
