import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import type { RoomId } from '../../commonTypes'
import TablePage from '../../components/PageContent'
import { RoomDetails } from '../../components/rooms/RoomDetails'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { RoomActionsPanel } from './RoomActionsPanel'
import RoomsTable from './RoomsTable'

type LocationState = { selectedRoomIds?: RoomId[] } | undefined

export const RoomsIndexPage = () => {
    const locationState = useLocation().state as LocationState
    const [selectedRowKeys, setSelectedRowKeys] = useState<RoomId[]>(locationState?.selectedRoomIds ?? [])

    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    // Some selected rooms may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedRoomId) => schedule.doesRoomIdExist(selectedRoomId))

    const selectedRooms = validSelectedRowKeys.map((roomId) => schedule.findRoom(roomId))

    const detailsPanel = (
        <RoomDetails key={`${schedule.getVersion()} ${validSelectedRowKeys.join(',')}`} rooms={selectedRooms} />
    )
    const actionsPanel = (
        <RoomActionsPanel
            rooms={selectedRooms}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
        />
    )

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                { key: 'actions', header: t('Actions'), panel: actionsPanel }
            ]}
            table={<RoomsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
        />
    )
}
