import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { Endpoints } from '../../services/Endpoints'
import { sseConnectDisconnectLoop } from '../../utils/sse/sse'
import { setAutoSchedulerSseConnectionState } from '../devmode/actions'
import type { ApplicationState } from '../index'

import { setAutoSchedulerState } from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerRunningState } from './types'

const apiBaseUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3006/api'

type SetAutoSchedulerStateAction = {
    type: typeof AutoSchedulerActionTypes.HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE
    payload: {
        isRunning: boolean
        isTaRunning: boolean
    }
}

function* handleLocallyTriggeredAutoSchedulerStateUpdate(action: SetAutoSchedulerStateAction): any {
    const { isRunning, isTaRunning } = action.payload

    // Set unknown state right after the click.
    yield put(
        setAutoSchedulerState({
            isRunning: AutoSchedulerRunningState.UNKNOWN,
            isTaRunning: AutoSchedulerRunningState.UNKNOWN
        })
    )

    // Fire off update request.
    const scheduleId = yield select((state: ApplicationState) => state.schedule.schedule.getScheduleId())
    const result = yield call(
        [Endpoints.autoSchedulingService, Endpoints.autoSchedulingService.setAutoSchedulerState],
        scheduleId,
        { isRunning, isTaRunning }
    )

    // Set state to result of request
    const newAutoSchedulerState = {
        isRunning: result.isRunning ? AutoSchedulerRunningState.RUNNING : AutoSchedulerRunningState.PAUSED,
        isTaRunning: result.isTaRunning ? AutoSchedulerRunningState.RUNNING : AutoSchedulerRunningState.PAUSED
    }
    yield put(setAutoSchedulerState(newAutoSchedulerState))
}

function* processAutoSchedulerStateUpdateEvent(action: any): any {
    const { isRunning, isTaRunning } = JSON.parse(action.data)
    const newAutoSchedulerState = {
        isRunning: isRunning ? AutoSchedulerRunningState.RUNNING : AutoSchedulerRunningState.PAUSED,
        isTaRunning: isTaRunning ? AutoSchedulerRunningState.RUNNING : AutoSchedulerRunningState.PAUSED
    }
    yield put(setAutoSchedulerState(newAutoSchedulerState))
}

function* autoSchedulerStateSubscriptionsSaga(): any {
    yield call(
        sseConnectDisconnectLoop,
        [AutoSchedulerActionTypes.SUBSCRIBE, AutoSchedulerActionTypes.UNSUBSCRIBE],
        (scheduleId: string) => `${apiBaseUrl}/auto-scheduler/${scheduleId}/updates`,
        'autoscheduler-state-update',
        (s: ApplicationState) => s.autoSchedulerState.subscriptionCount,
        processAutoSchedulerStateUpdateEvent,
        setAutoSchedulerSseConnectionState
    )
}

function* watchAutoSchedulerStateChanges(): any {
    yield takeEvery(
        AutoSchedulerActionTypes.HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE,
        handleLocallyTriggeredAutoSchedulerStateUpdate
    )
}

function* autoSchedulerSaga() {
    yield all([fork(watchAutoSchedulerStateChanges), fork(autoSchedulerStateSubscriptionsSaga)])
}

export default autoSchedulerSaga
