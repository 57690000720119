import type { ITimeOfDay } from 'common-api'
import { range } from 'lodash'

import { useLocalSchedule } from '../../../store/schedule/hooks'
import brandColors from '../../../styles/colors/brandColors'
import styled from '../../../utils/styled'

import { positioningHeightRaw, positioningTopRaw } from './util'

const TIMELINE_WIDTH = '40px'

export const TimeLine = () => {
    const schedule = useLocalSchedule()

    // Todo: Remove the ! part since these will not be optional after migration is done
    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    const firstHourMark = startOfDay.minute === 0 ? startOfDay.hour : startOfDay.hour + 1
    const lastHourMark = endOfDay.hour

    return (
        <Wrapper>
            {range(firstHourMark, lastHourMark + 1).map((hour) => (
                <div key={hour}>
                    <HourWrapper startOfDay={startOfDay} endOfDay={endOfDay} hour={hour} minute={0}>
                        <HourLine borderType="solid" />
                    </HourWrapper>
                    {hour !== endOfDay.hour && (
                        <HourWrapper startOfDay={startOfDay} endOfDay={endOfDay} hour={hour} minute={30}>
                            <HourLine borderType="dashed" />
                        </HourWrapper>
                    )}
                    <HourWrapper startOfDay={startOfDay} endOfDay={endOfDay} hour={hour} minute={-30}>
                        <HourHeader>
                            <HourTextWrapper>
                                <HourText>{hour < 10 ? `0${hour}` : hour}:00</HourText>
                            </HourTextWrapper>
                        </HourHeader>
                    </HourWrapper>
                </div>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled('div')`
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
`

interface HourProps {
    startOfDay: ITimeOfDay
    endOfDay: ITimeOfDay
    hour: number
    minute: number
}

const HourWrapper = styled('div')<HourProps>`
    position: absolute;
    top: ${(props) => positioningTopRaw(props.startOfDay, props.endOfDay, props.hour, props.minute)}%;
    height: ${(props) => positioningHeightRaw(props.startOfDay, props.endOfDay, 60)}%;
    width: 100%;
`

interface HourLineProps {
    borderType: 'solid' | 'dashed'
}

const HourLine = styled('div')<HourLineProps>`
    position: absolute;
    left: calc(${TIMELINE_WIDTH} / ${(props) => (props.borderType === 'solid' ? 2 : 1)});
    width: calc(100% - ${TIMELINE_WIDTH});
    border-top: 1px ${(props) => props.borderType} ${brandColors.gray10};
    margin-top: -1px;
`

const HourHeader = styled('div')`
    display: table;
    height: 100%;
    width: ${TIMELINE_WIDTH};
`

const HourTextWrapper = styled('div')`
    display: table-cell;
    vertical-align: middle;
    font-size: 0.5em;
    font-weight: bold;
`

const HourText = styled('span')`
    display: inline-block;
    color: ${brandColors.black};
    padding: 0px 3px 0px 3px;
    font-size: 10px;
`
