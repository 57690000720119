import { isEqual, reject } from 'lodash'

import { initialState as initialScheduleState } from '../schedule/reducer'

import type { ScheduleSelectionAction } from './actions'
import { ScheduleSelectionActionTypes, type ScheduleSelectionState } from './types'

export const initialState: ScheduleSelectionState = {
    scheduleId: initialScheduleState.schedule.getScheduleId(),
    selectedSchedules: []
}

export const scheduleSelectionReducer = (
    state = initialState,
    action: ScheduleSelectionAction
): ScheduleSelectionState => {
    switch (action.type) {
        case ScheduleSelectionActionTypes.USER_ADDED_SCHEDULE_SELECTOR: {
            const scheduleSelectorToAdd = action.payload

            return {
                ...state,
                selectedSchedules: [...state.selectedSchedules, scheduleSelectorToAdd]
            }
        }
        case ScheduleSelectionActionTypes.USER_REMOVED_SCHEDULE_SELECTOR: {
            const scheduleSelectorToRemove = action.payload

            return {
                ...state,
                selectedSchedules: reject(state.selectedSchedules, (sel) => isEqual(sel, scheduleSelectorToRemove))
            }
        }
        case ScheduleSelectionActionTypes.NEW_SCHEDULE_SELECTORS_LOADED_DUE_TO_SCHEDULE_UPDATE: {
            return {
                ...state,
                scheduleId: action.payload.scheduleId,
                selectedSchedules: action.payload.loadedScheduleSelectors
            }
        }
        default:
            return state
    }
}
