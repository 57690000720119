import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined,
    MergeCellsOutlined,
    SplitCellsOutlined
} from '@ant-design/icons'
import { Button, Dropdown, Empty, Flex, message, Space } from 'antd'
import { IEventGroup, IScheduleTransform } from 'common-api'
import { head, tail, uniqBy } from 'lodash'
import { cloneElement, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { LectureId } from '../../../commonTypes'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { findOrThrow, indexBy } from '../../../utils/collections'
import { comparing } from '../../../utils/compareUtil'
import { DuplicateLectureButton } from '../DuplicateLectureButton'
import { ExtractLectureFromSeriesButton } from '../ExtractLectureFromSeriesButton'
import { SplitLectureButton } from '../SplitLectureButton'

import { LectureActionsPanelProps } from './types'

const LecturesActionsPanel = ({ lectureIds, onDeleteLecture }: LectureActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    if (lectureIds.length === 0) {
        return <Empty description={t('NoLessonSelected')} />
    }

    const lectures = lectureIds.map((lid) => schedule.findLecture(lid))

    const handleBlockCreation = () => {
        // At least two lectures selected
        if (lectures.length < 2) {
            message.error(t('SelectTwoOrMoreLectures'))

            return
        }

        // Collect event groups from all lectures
        const allEventGroups = uniqBy(
            lectures.map((l) => l.getEventGroup()),
            (eg) => eg.getEventGroupId()
        )

        if (allEventGroups.length < 2) {
            message.error(t('AllLecturesAlreadyInBlock'))

            return
        }

        // Terminology:
        // - Winner event group = event group to extend to a block
        // - Loser event groups = event groups to empty and delete
        //
        // Strategy:  Pick a "winner" event group arbitrarily (but prefer a scheduled one over an unscheduled one).
        //
        // TODO: There are better "guesses" possible here. If all event groups are on the same day, we can pick the
        //  earliest one for example.
        const sortedEventGroups = allEventGroups.sort(comparing((eg) => eg.getDayAndTime() === undefined))
        const winnerEg = head(sortedEventGroups)!.getConjureObject()
        const loserEgs = tail(sortedEventGroups).map((eg) => eg.getConjureObject())

        // Move lectures from loser event groups to winner.
        for (const loserEg of loserEgs) {
            // Here we assume that each loser lecture has rel start time = 0
            winnerEg.lectures.push(...loserEg.lectures)
        }

        // Delete losers / update winner
        const transforms = []
        for (const loserEg of loserEgs) {
            transforms.push(
                IScheduleTransform.eventGroupDeleteTransform({
                    eventGroupId: loserEg.eventGroupId
                })
            )
        }

        transforms.push(IScheduleTransform.eventGroupTransform({ newEventGroup: winnerEg }))

        // Submit transform
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))

        message.success('Block skapat')
    }

    const getTransformsForExtractingSingleLectureFromEventGroup = (
        eventGroup: IEventGroup,
        lectureIds: LectureId[]
    ) => {
        const newEventGroups = []
        for (const lectureId of lectureIds) {
            // If there's only one lecture left in event group (the lecture we're about to extract) we leave it as is.
            // By doing so we don't need to delete the empty event group afterward.
            if (eventGroup.lectures.length === 1) {
                break
            }

            // Create a new singleton event group
            newEventGroups.push({
                ...eventGroup,
                eventGroupId: uuid(),
                lectures: [findOrThrow(eventGroup.lectures, lectureId, (l) => l.lectureId)]
            })

            // Remove lectures from it current event group
            eventGroup = {
                ...eventGroup,
                lectures: eventGroup.lectures.filter((l) => l.lectureId !== lectureId)
            }
        }

        return [eventGroup, ...newEventGroups].map((eg) =>
            IScheduleTransform.eventGroupTransform({ newEventGroup: eg })
        )
    }

    const handleBlockSplit = (splitEntireBlock: boolean) => {
        // Lectures to extract into singleton event groups
        const lecturesToExtract = splitEntireBlock
            ? uniqBy(
                  lectures.flatMap((l) => l.getEventGroup().getLectures()),
                  (l) => l.getLectureId()
              )
            : lectures

        // Process one event group at a time. If we extract one lecture at a time we have to keep track of the newly
        // updated event group when extracting subsequent lectures.
        const lecturesByEventGroup = indexBy(lecturesToExtract, (l) => l.getEventGroup().getEventGroupId())
        const transforms = []
        for (const [eventGroupId, lectures] of lecturesByEventGroup) {
            const eg = schedule.findEventGroup(eventGroupId).getConjureObject()
            const lectureIds = lectures.map((l) => l.getLectureId())
            transforms.push(...getTransformsForExtractingSingleLectureFromEventGroup(eg, lectureIds))
        }

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))

        message.success('Block uppdelat') // or "Lektion extraherad från block"
    }

    const handleUnscheduleLecture = () => {
        const selectedEventGroups = lectures.map((l) => l.getEventGroup())

        const uniqueSelectedEventGroups = uniqBy(selectedEventGroups, (eg) => eg.getEventGroupId())

        const resetTransforms = uniqueSelectedEventGroups.map((eg) =>
            IScheduleTransform.eventGroupTransform({
                newEventGroup: {
                    ...eg.getConjureObject(),
                    dayAndTime: null
                }
            })
        )

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(resetTransforms)))
        message.success(
            lectures.length === 1
                ? 'Dag och tid nollställt'
                : `Dag och tid nollställt för alla ${lectures.length} valda lektioner`
        )
    }

    const handleDeleteLecture = () => {
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    lectureIds.map((lectureId) => IScheduleTransform.lectureDeleteTransform({ lectureId }))
                )
            )
        )
        onDeleteLecture()
    }

    return (
        <Flex vertical gap={8} align="stretch" flex={1}>
            <Button icon={<MergeCellsOutlined />} onClick={handleBlockCreation} disabled={lectures.length < 2}>
                {t('CreateBlock')}
            </Button>
            <Dropdown.Button
                buttonsRender={([left, right]) => [
                    isValidElement(left) ? cloneElement(left, { ...left.props, style: { flex: 1 } }) : left,
                    right
                ]}
                menu={{
                    items: [
                        {
                            key: 1,
                            label:
                                lectures.length === 1
                                    ? t('ExtractSingleLectureFromBlock')
                                    : t('ExtractSelectedLecturesFromBlock'),
                            onClick: () => {
                                handleBlockSplit(false)
                            }
                        }
                    ]
                }}
                icon={<DownOutlined />}
                onClick={() => {
                    handleBlockSplit(true)
                }}
            >
                <Space>
                    <SplitCellsOutlined />
                    {t('SplitBlock')}
                </Space>
            </Dropdown.Button>
            <DuplicateLectureButton disabled={lectures.length !== 1} lectureId={lectureIds[0]} />
            <SplitLectureButton disabled={lectures.length !== 1} lectureId={lectureIds[0]} />
            <ExtractLectureFromSeriesButton lectureIds={lectureIds} />
            <Button icon={<DownloadOutlined />} onClick={handleUnscheduleLecture}>
                {t('MoveToDrawer')}
            </Button>
            <Button danger icon={<DeleteOutlined />} onClick={handleDeleteLecture}>
                {t(lectures.length === 1 ? 'RemoveLecture' : 'RemoveLectures')}
            </Button>
        </Flex>
    )
}

export default LecturesActionsPanel
