import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'

import { useLocalSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const RoomFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const allRoomFilters = schedule.getRooms().map((r) => ({
        value: r.getRoomId(),
        text: r.getName()
    }))

    return <FilterableFilterDropdown {...props} allFilters={allRoomFilters} noKeysAvailableDescription={t('NoRooms')} />
}
