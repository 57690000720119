import { IStudentGroup } from 'common-api'
import i18next from 'i18next'
import { diff } from '../../../../../EditTable/utils'
import { ScheduleAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { textualListing } from '../../../../../textUtil'
import { ProblemListProblem } from '../../../../schedule/components/ProblemsList/types'
import { createBlocker } from '../../../../schedule/components/ProblemsList/utils'
import { TableEditValidationProblem } from '../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/types'
import { parseStudentGroupsRows, studentGroupCmp } from './data'
import { StudentGroupGridRow, StudentGroupsGridContentColumn, StudentGroupsGridMetaDataColumn } from './types'
import { isEmptyStudentGroupsRow } from './utils'

function checkForDuplicateStudentGroupNames(
    studentGroupNames: string[],
    studentGroupRow: StudentGroupGridRow
): TableEditValidationProblem | undefined {
    const studentGroupName = studentGroupRow[StudentGroupsGridContentColumn.DisplayName]

    const duplicateStudentGroupNames = studentGroupNames.filter(
        (name) => name.toLowerCase() === studentGroupName.toLowerCase()
    )

    if (duplicateStudentGroupNames.length > 1) {
        return {
            location: {
                rowIndex: studentGroupRow[StudentGroupsGridMetaDataColumn.RowIndex] + 1,
                colIndex: StudentGroupsGridContentColumn.DisplayName
            },
            problem: createBlocker(i18next.t('Validation.StudentGroupAlreadyExists'))
        }
    }

    return undefined
}

export function studentGroupCellValidationErrors(studentGroups: StudentGroupGridRow[]): TableEditValidationProblem[] {
    const result = []
    const studentGroupNames = studentGroups.map((group) => group[StudentGroupsGridContentColumn.DisplayName])

    for (const studentGroupRow of studentGroups) {
        if (isEmptyStudentGroupsRow(studentGroupRow)) {
            continue
        }

        const studentGroupName = studentGroupRow[StudentGroupsGridContentColumn.DisplayName]

        if (studentGroupName.length === 0) {
            result.push({
                location: {
                    rowIndex: studentGroupRow[StudentGroupsGridMetaDataColumn.RowIndex] + 1,
                    colIndex: StudentGroupsGridContentColumn.DisplayName
                },
                problem: createBlocker(i18next.t('Validation.StudentGroupMissingName'))
            })

            return result
        }

        const duplicateProblem = checkForDuplicateStudentGroupNames(studentGroupNames, studentGroupRow)

        if (duplicateProblem !== undefined) {
            result.push(duplicateProblem)
        }
    }

    return result
}

export function globalValidationErrors(
    currentSchedule: ScheduleAccessor,
    newStudentGroupData: StudentGroupGridRow[],
    existingStudentGroups: IStudentGroup[]
): ProblemListProblem[] {
    const parsedStudentGroups = parseStudentGroupsRows(existingStudentGroups, newStudentGroupData)
    const diffToValidate = diff(existingStudentGroups, parsedStudentGroups, (t) => t.studentGroupId, studentGroupCmp)

    // Check if deleted teachers are referenced in course rounds.
    const allCourseRoundWithStudentGroups = currentSchedule.getCourseRounds().map((cr) => {
        const studentGroupId = cr.getStudentGroup().getStudentGroupId()
        const courseRoundName = cr.getDisplayName()
        const studentGroupName = cr.getStudentGroup().getDisplayName()

        return {
            studentGroupId,
            courseRoundName,
            studentGroupName
        }
    })

    const deletedAndReferencedStudentGroups = diffToValidate.deleted.filter((deletedStudentGroup) =>
        allCourseRoundWithStudentGroups.find(
            ({ studentGroupId }) => studentGroupId === deletedStudentGroup.studentGroupId
        )
    )

    const referencedStudentGroupErrors = deletedAndReferencedStudentGroups.map((deletedStudentGroup) => {
        const referencingCourseRounds = allCourseRoundWithStudentGroups.filter(
            ({ studentGroupId }) => studentGroupId === deletedStudentGroup.studentGroupId
        )
        const value = referencingCourseRounds[0]
        const studentGroupName = value.studentGroupName
        const courseRoundName = value.courseRoundName

        return createBlocker(
            i18next.t('Validation.CouldntRemoveStudentGroups', {
                studentGroup: studentGroupName,
                courseRound: courseRoundName
            })
        )
    })

    // Check for duplicate student group names
    const studentGroupNameCounts = new Map<string, number[]>()
    newStudentGroupData.forEach((studentGroupRow, index) => {
        const name = studentGroupRow[StudentGroupsGridContentColumn.DisplayName]
        if (name !== '') {
            if (!studentGroupNameCounts.has(name)) {
                studentGroupNameCounts.set(name, [])
            }

            const rows = studentGroupNameCounts.get(name)
            if (rows) {
                rows.push(index)
            }
        }
    })

    const duplicateStudentGroupErrors = [...studentGroupNameCounts.entries()]
        .filter(([, rows]) => rows.length > 1)
        .map(([studentGroupName, rows]) =>
            createBlocker(
                i18next.t('Validation.DuplicateStudentGroupNames', {
                    name: studentGroupName,
                    rows: textualListing(
                        rows.map((r) => `${r + 1}`),
                        i18next.t('Rows')
                    )
                })
            )
        )

    return [...duplicateStudentGroupErrors, ...referencedStudentGroupErrors]
}
