import styled from '../../../utils/styled'

import type { SubseqHighlightProps } from './types'

const subseqMatchParts = (subseq: string, stringToMatch: string, originalString: string) => {
    const untouchedOriginalString = originalString
    const parts = []
    let currentPart = []
    while (stringToMatch.length > 0) {
        const highlightCharacter = subseq.length > 0 && subseq[0] === stringToMatch[0]
        const isInHighlightPart = parts.length % 2 == 1
        if (highlightCharacter !== isInHighlightPart) {
            // Switching from highlighting to non-highlighting or vice versa
            parts.push(currentPart)
            currentPart = []
        }

        currentPart.push(originalString[0])
        stringToMatch = stringToMatch.slice(1)
        originalString = originalString.slice(1)
        if (highlightCharacter) {
            subseq = subseq.slice(1)
        }
    }

    parts.push(currentPart)

    // Not a full match?
    if (subseq.length !== 0) {
        parts.length = 0

        return [untouchedOriginalString]
    }

    return parts
}

const substringMatchParts = (substring: string, stringToMatch: string, originalString: string) => {
    const matchIndex = stringToMatch.indexOf(substring)
    if (matchIndex === -1) {
        return [originalString]
    }

    return [
        originalString.substring(0, matchIndex),
        originalString.substring(matchIndex, matchIndex + substring.length),
        originalString.substring(matchIndex + substring.length, originalString.length)
    ]
}

export const FilterHighlight = ({ filtering, text }: SubseqHighlightProps) => {
    if (!filtering.filterHighlighting) {
        return text
    }

    // Lower case strings in case case-insensitive filtering is active
    const pattern = filtering.caseSensitive ? filtering.filterText : filtering.filterText.toLowerCase()
    const stringToMatch = filtering.caseSensitive ? text : text.toLowerCase()

    // Split up text in alternating parts (non-highlighting, highlighting, non-highlighting, highlighting, ...) staring
    // with a non-highlighting part.
    const matchFn = filtering.subseq ? subseqMatchParts : substringMatchParts
    const parts = matchFn(pattern, stringToMatch, text)

    // Highlight every other part
    return <span>{parts.map((part, i) => (i % 2 == 0 ? part : <Highlight key={i}>{part}</Highlight>))}</span>
}

const Highlight = styled.mark`
    background-color: #faef2d;
`
