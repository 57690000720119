import { CheckOutlined } from '@ant-design/icons'
import Button from 'antd/es/button'
import { useTranslation } from 'react-i18next'

import { COMPLETED_COLOR } from '../../constants'
import { useCompletionStateToggler } from '../../hooks'
import { celebrateClick, isCelebrationStep } from '../../util'

import { ToggleExplicitlyDoneButtonProps } from './types'

export const ToggleExplicitlyDoneButton = (props: ToggleExplicitlyDoneButtonProps) => {
    const toggleState = useCompletionStateToggler(props.stepUiItem.step)
    const isMarkedAsCompleted = props.stepUiItem.isMarkedAsCompleted
    const { t } = useTranslation()

    const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        toggleState()
        if (isCelebrationStep(props.stepUiItem.step) && !isMarkedAsCompleted) {
            celebrateClick(e)
        }
    }

    return (
        <Button
            size="large"
            icon={isMarkedAsCompleted ? undefined : <CheckOutlined />}
            type="primary"
            onClick={handleClick}
            style={{
                backgroundColor: isMarkedAsCompleted ? undefined : COMPLETED_COLOR,
                border: 'none',
                boxShadow: '.2em .2em .3em #aaa'
            }}
            shape="round"
        >
            {isMarkedAsCompleted ? t('MarkAsNotDone') : t('MarkAsDone')}
        </Button>
    )
}
