import { IStudentGroup } from 'common-api'
import { v4 as uuid } from 'uuid'
import { matchRowsWithExistingData } from '../../../../../EditTable/data'
import { combinedCmpFn, comparing, stringCmp } from '../../../../../utils/compareUtil'
import { StudentGroupGridRow, StudentGroupsGridContentColumn, StudentGroupsGridMetaDataColumn } from './types'
import { isEmptyStudentGroupsRow } from './utils'

export const studentGroupCmp = combinedCmpFn<IStudentGroup>(
    comparing((r) => r.displayName),
    comparing((r) => r.labels.toSorted(stringCmp).join(',')),
    comparing((r) => r.description)
)

const matchScore = (row: StudentGroupGridRow, studentGroup: IStudentGroup) =>
    (studentGroup.displayName === row[StudentGroupsGridContentColumn.DisplayName] ? 1 : 0) +
    (studentGroup.description === row[StudentGroupsGridContentColumn.Description] ? 1 : 0) +
    (studentGroup.labels.join(',') === row[StudentGroupsGridContentColumn.Labels] ? 1 : 0)

export const parseStudentGroupsRows = (
    existingStudentGroups: IStudentGroup[],
    rowsIncludingEmpty: StudentGroupGridRow[]
): IStudentGroup[] => {
    const rows = rowsIncludingEmpty.filter((row) => !isEmptyStudentGroupsRow(row))
    const matchedStudentGroups = matchRowsWithExistingData<IStudentGroup, StudentGroupGridRow>({
        existingData: existingStudentGroups,
        rows,
        matchRow: StudentGroupsGridMetaDataColumn.RowIndex,
        matchScore: (row, studentGroup) => matchScore(row, studentGroup),
        maximumScore: 3,
        minimumAcceptableScore: 1
    })

    return rows.map((row) => {
        const matchingStudentGroup = matchedStudentGroups.get(row[StudentGroupsGridMetaDataColumn.RowIndex])
        const labels = row[StudentGroupsGridContentColumn.Labels]
            .split(',')
            .map((attr) => attr.trim())
            .filter((attr) => attr !== '')

        const description = row[StudentGroupsGridContentColumn.Description]

        return {
            studentGroupId: matchingStudentGroup?.studentGroupId ?? uuid(),
            displayName: row[StudentGroupsGridContentColumn.DisplayName],
            labels,
            description,
            overlaps: matchingStudentGroup?.overlaps ?? [],
            nonOverlaps: matchingStudentGroup?.nonOverlaps ?? []
        }
    })
}
