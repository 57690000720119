import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'

import { useSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const SubjectFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()
    const allSubjectFilters = schedule.getSubjects().map((s) => ({
        value: s.getSubjectId(),
        text: s.getCode()
    }))

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allSubjectFilters}
            noKeysAvailableDescription={t('NoSubjects')}
        />
    )
}
