import { Card, Empty, Space } from 'antd'
import { ReactNode } from 'react'

import classes from './style.module.css'

type DiffCardProps<T> = {
    icon: ReactNode
    title: string
    noChangesText: string
    changes: T[]
    renderChange: (change: T) => ReactNode
}

export const DiffCard = <T,>({ icon, title, renderChange, noChangesText, changes }: DiffCardProps<T>) => (
    <Card
        title={
            <Space>
                {icon}
                {title}
            </Space>
        }
        style={{ flex: 1 }}
    >
        {changes.length > 0 ? (
            <ul className={classes.diffCard__list}>{changes.map(renderChange)}</ul>
        ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noChangesText} />
        )}
    </Card>
)
