import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useColumnWidth } from '../../../../../EditTable/hooks'
import { SubjectAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'

import {
    SubjectGridMetaDataColumn,
    SubjectGridContentColumn,
    SubjectGridRow,
    ALL_CONTENT_COLUMNS,
    SubjectGridColumnSpec
} from './types'
import { sortedSubjectsFromSchedule } from './utils'

export const useSortedSubjectsFromSchedule = () => {
    const schedule = useLocalSchedule()

    return sortedSubjectsFromSchedule(schedule)
}

const subjectToSubjectRow = (subject: SubjectAccessor, rowIndex: number): SubjectGridRow => {
    return {
        [SubjectGridMetaDataColumn.RowIndex]: rowIndex,
        [SubjectGridContentColumn.Name]: subject.getName(),
        [SubjectGridContentColumn.Code]: subject.getCode()
    }
}

export const useLiveSubjectTableData = () => {
    const subjects = useSortedSubjectsFromSchedule()

    return subjects.map((subject, index) => subjectToSubjectRow(subject, index))
}

export const useSubjectTableDataState = () => {
    const subjectTableData = useLiveSubjectTableData()
    const [subjectGridData, setSubjects] = useState<SubjectGridRow[]>(subjectTableData)

    const resetSubjects = () => {
        setSubjects(subjectTableData)
    }

    return { subjectGridData, setSubjects, resetSubjects }
}

export const useSubjectGridColumns = (target: RefObject<HTMLDivElement> | null): SubjectGridColumnSpec[] => {
    const { t } = useTranslation()
    const columnWidth = useColumnWidth(target, ALL_CONTENT_COLUMNS.length)

    return [
        {
            title: '',
            gridColumn: SubjectGridMetaDataColumn.RowIndex,
            colIndex: 0,
            width: 60,
            minWidth: 60
        },
        {
            title: t('Name'),
            gridColumn: SubjectGridContentColumn.Name,
            colIndex: 1,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: t('Code'),
            gridColumn: SubjectGridContentColumn.Code,
            colIndex: 2,
            width: columnWidth,
            minWidth: columnWidth
        }
    ]
}
