import { Flex, Table } from 'antd'
import { Outlet } from 'react-router'

import type { StudentGroupId } from '../../../../commonTypes'
import TableCard from '../../../../components/TableCard'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import EditTableLoadingSpinner from '../../../../EditTable/components/EditTableLoadingSpinner'
import { TableEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch'
import { useEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch/useEditModeSwitch'
import { useIsDummySchedule, useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { NewStudentGroupButton } from '../NewStudentGroupButton'

import { useStudentGroupColumns } from './columns'
import type { StudentGroupRow, StudentGroupsTableProps } from './types'

const StudentGroupTable = ({
    selectedRowKeys,
    setSelectedRowKeys,
    showOverlappingGroupsColumn = true,
    showAddButton = true
}: StudentGroupsTableProps) => {
    const { isEditModeToggled } = useEditModeSwitch()

    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const isDummySchedule = useIsDummySchedule()

    const { columns, setVisibleColumns } = useStudentGroupColumns(showOverlappingGroupsColumn, filtering)

    const dataSourceUnfiltered = schedule.getStudentGroups().map((studentGroup) => ({ studentGroup }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ studentGroup }) => studentGroup.getDisplayName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableCard
            title={
                <Flex gap={8}>
                    <FilterInput {...filtering} disabled={isEditModeToggled} />
                    <TableEditModeSwitch disabled={isDummySchedule} onChange={handleModeChange} />
                    {showAddButton && (
                        <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    )}
                    {showAddButton && (
                        <NewStudentGroupButton
                            onStudentGroupCreated={(studentGroupId) => {
                                setSelectedRowKeys([studentGroupId])
                            }}
                        />
                    )}
                </Flex>
            }
        >
            {isEditModeToggled ? (
                <EditTableLoadingSpinner>
                    <Outlet />
                </EditTableLoadingSpinner>
            ) : (
                <Table<StudentGroupRow>
                    bordered
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys) => {
                            setSelectedRowKeys(newSelectedRowKeys as StudentGroupId[])
                        }
                    }}
                    dataSource={dataSource}
                    columns={columns}
                    showSorterTooltip={false}
                    size="small"
                    rowKey={({ studentGroup }) => studentGroup.getStudentGroupId()}
                    sticky
                    onRow={({ studentGroup }) => ({
                        onClick: () => {
                            setSelectedRowKeys([studentGroup.getStudentGroupId()])
                        }
                    })}
                    style={{ height: '100vh' }}
                    pagination={false}
                />
            )}
        </TableCard>
    )
}

export default StudentGroupTable
