import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

const SCHEDULE_STORAGE_KEY = 'schedule-viewing-mode'

type ViewingMode = 'columns' | 'per-schedule'

type State = {
    viewingMode: ViewingMode
    columns: number
}

type Action = {
    setViewingMode: (viewingMode: ViewingMode) => void
    setColumns: (columns: number) => void
}

export const useScheduleStore = create<State & Action>()(
    persist(
        (set) => ({
            viewingMode: 'columns',
            setViewingMode: (viewingMode: ViewingMode) => {
                set({ viewingMode })
            },
            columns: 2,
            setColumns: (columns: number) => {
                set({ columns })
            }
        }),
        {
            name: SCHEDULE_STORAGE_KEY,
            storage: createJSONStorage(() => localStorage)
        }
    )
)
