import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'

import { ScheduleId } from '../../commonTypes'

import type * as autoSchedulerActions from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerRunningState } from './types'

export const setAutoSchedulerState = (newState: {
    isRunning: AutoSchedulerRunningState
    isTaRunning: AutoSchedulerRunningState
}) => action(AutoSchedulerActionTypes.SET_AUTO_SCHEDULER_STATE, newState)

export const handleLocallyTriggeredAutoSchedulerStateUpdate = (isRunning: boolean, isTaRunning: boolean) =>
    action(AutoSchedulerActionTypes.HANDLE_LOCALLY_TRIGGERED_AUTO_SCHEDULER_STATE_UPDATE, { isRunning, isTaRunning })

export const subscribe = (scheduleId: ScheduleId) => action(AutoSchedulerActionTypes.SUBSCRIBE, scheduleId)
export const unsubscribe = (scheduleId: ScheduleId) => action(AutoSchedulerActionTypes.UNSUBSCRIBE, scheduleId)

export type AutoSchedulerAction = ActionType<typeof autoSchedulerActions>
