import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import type { EventGroupId } from '../../../commonTypes'
import { setLectureSelection } from '../../../store/lectureselector/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import Button from '../../Button'
import { NonInput } from '../../forms/NonInput'

type SinglePartialBlockInputMessageProps = {
    eventGroupId: EventGroupId
    numLecturesSelected: number
}

export const SinglePartialBlockInputMessage = ({
    eventGroupId,
    numLecturesSelected
}: SinglePartialBlockInputMessageProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const eventGroup = schedule.findEventGroup(eventGroupId)
    const eventGroupLectureIds = eventGroup.getLectures().map((l) => l.getLectureId())

    return (
        <NonInput>
            {t('LecturesInBlockSelected', { count: numLecturesSelected, total: eventGroupLectureIds.length })}{' '}
            <Button
                variant="tertiary"
                type="button"
                onClick={() => dispatch(setLectureSelection(eventGroupLectureIds))}
            >
                {t('SelectAllLecturesInBlock')}
            </Button>{' '}
            {t('SelectAllLecturesInBlockToEdit')}
        </NonInput>
    )
}
