import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

import type { ClearTableContentButtonProps } from './types'

export const ClearTableContentButton = ({ onClear }: ClearTableContentButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button icon={<DeleteOutlined />} onClick={onClear}>
            {t('ClearContent')}
        </Button>
    )
}
