import { PlusOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import Button from '../../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName } from '../../../../utils/miscUtil'

import type { NewStudentGroupButtonProps } from './types'

export const NewStudentGroupButton = (props: NewStudentGroupButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const freshDisplayName = () =>
        generateFreshName(
            'Ny grupp',
            schedule.getStudentGroups().map((sg) => sg.getDisplayName())
        )

    const handleNewStudentGroup = () => {
        const newStudentGroupId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.studentGroupTransform({
                    newStudentGroup: {
                        studentGroupId: newStudentGroupId,
                        displayName: freshDisplayName(),
                        labels: [],
                        description: '',
                        overlaps: [],
                        nonOverlaps: []
                    }
                })
            )
        )
        props.onStudentGroupCreated(newStudentGroupId)
    }

    return (
        <Button variant="primary" size="sm" onClick={handleNewStudentGroup} startIcon={<PlusOutlined />}>
            {t('AddStudentGroup')}
        </Button>
    )
}
