import { CheckOutlined, MoreOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

import { COMPLETED_COLOR, STARTED_COLOR, TODO_COLOR } from '../../../constants'

import { ChecklistStateIconProps } from './types'

function stateIconParameters(isMarkedAsCompleted: boolean, isStartDetected: boolean, stepNumber: number) {
    if (isMarkedAsCompleted) {
        return { backgroundColor: COMPLETED_COLOR, item: <CheckOutlined /> }
    } else if (isStartDetected) {
        return { backgroundColor: STARTED_COLOR, item: <MoreOutlined rotate={90} /> }
    }

    return { backgroundColor: TODO_COLOR, item: stepNumber }
}

export const ChecklistStateIcon = ({
    isMarkedAsCompleted,
    isStartDetected,
    onClick,
    size,
    stepNumber
}: ChecklistStateIconProps) => {
    const { backgroundColor, item } = stateIconParameters(isMarkedAsCompleted, isStartDetected, stepNumber)

    return (
        <ChecklistStateIconWrapper size={size} backgroundColor={backgroundColor} onClick={onClick}>
            <span style={{ color: 'white', fontSize: '60%' }}>{item}</span>
        </ChecklistStateIconWrapper>
    )
}

const ChecklistStateIconWrapper = styled.div<{ size: string; backgroundColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: ${(props) => props.backgroundColor};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    font-size: ${(props) => props.size};
`
