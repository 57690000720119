import { Space } from 'antd'

import { DeleteTeachersButton } from './DeleteTeacherButton'
import { TeacherActionsPanelProps } from './types'

export const TeacherActionsPanel = ({ teacherIds, onDelete }: TeacherActionsPanelProps) => {
    return (
        <Space direction="vertical">
            <DeleteTeachersButton teacherIds={teacherIds} onDelete={onDelete} />
        </Space>
    )
}
