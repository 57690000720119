import { ISubject } from 'common-api'
import { useTranslation } from 'react-i18next'

import { SimpleListDiff } from '../../../../../../EditTable/components/SimpleListDiff'
import { DiffResult } from '../../../../../../EditTable/utils'

export type SimpleSubjectListDiffProps = {
    diff: DiffResult<ISubject>
}

const SimpleSubjectListDiff = ({ diff }: SimpleSubjectListDiffProps) => {
    const { t } = useTranslation()

    return (
        <SimpleListDiff
            diff={diff}
            getDisplayName={(subject) => subject.name}
            getPropertyDiffs={(subject) => [
                {
                    label: t('Subject'),
                    prevValue: subject.name,
                    nextValue: subject.name
                },
                {
                    label: t('SubjectId'),
                    prevValue: subject.subjectId,
                    nextValue: subject.subjectId
                },
                {
                    label: t('SubjectCode'),
                    prevValue: subject.code,
                    nextValue: subject.code
                }
            ]}
            translations={{
                newItems: t('SubjectListChanges.NewSubjects'),
                updatedItems: t('SubjectListChanges.UpdatedSubjects'),
                deletedItems: t('SubjectListChanges.DeletedSubjects')
            }}
        />
    )
}

export default SimpleSubjectListDiff
