import { Flex, Table } from 'antd'
import { Outlet } from 'react-router'

import TableCard from '../../../../components/TableCard'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import EditTableLoadingSpinner from '../../../../EditTable/components/EditTableLoadingSpinner'
import { TableEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch'
import { useEditModeSwitch } from '../../../../EditTable/components/TableEditModeSwitch/useEditModeSwitch'
import { useIsDummySchedule, useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { useSubjectColumns } from '../columns'
import { NewSubjectButton } from '../NewSubjectButton'

import type { SubjectRow, SubjectsTableProps } from './types'

export const SubjectsTable = ({ selectedRowKeys, setSelectedRowKeys }: SubjectsTableProps) => {
    const { isEditModeToggled } = useEditModeSwitch()
    const filtering = useFiltering()
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useSubjectColumns(filtering)

    const dataSourceUnfiltered = schedule.getSubjects().map((subject) => ({ subject }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ subject }) => subject.getName()))

    const handleModeChange = () => {
        setSelectedRowKeys([])
    }

    return (
        <TableCard
            title={
                <Flex gap={8}>
                    <FilterInput {...filtering} disabled={isEditModeToggled} />
                    <VisibleColumnsSelector
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        disabled={isEditModeToggled}
                    />
                    <TableEditModeSwitch disabled={isDummySchedule} onChange={handleModeChange} />
                    <NewSubjectButton
                        onNewSubject={(newSubjectId) => {
                            setSelectedRowKeys([newSubjectId])
                        }}
                        disabled={isEditModeToggled}
                    />
                </Flex>
            }
        >
            {isEditModeToggled ? (
                <EditTableLoadingSpinner>
                    <Outlet />
                </EditTableLoadingSpinner>
            ) : (
                <Table<SubjectRow>
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRows) => {
                            setSelectedRowKeys(newSelectedRows.map(String))
                        }
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    showSorterTooltip={false}
                    size="small"
                    rowKey={({ subject }) => subject.getSubjectId()}
                    onRow={({ subject }) => ({
                        onClick: () => {
                            setSelectedRowKeys([subject.getSubjectId()])
                        }
                    })}
                    pagination={false}
                    sticky
                    bordered
                />
            )}
        </TableCard>
    )
}
