import { useTranslation } from 'react-i18next'

import { EmptyTablePlaceholder } from '../../../../../components/EmptyTablePlaceholder/indext'

import type { EmptyLectureTablePlaceholderProps } from './types'

export const EmptyLectureTablePlaceholder = (props: EmptyLectureTablePlaceholderProps) => {
    const { t } = useTranslation()

    return (
        <EmptyTablePlaceholder description={props.nonEmptyDataSource ? t('NoLecturesMatchFilter') : t('NoLectures')} />
    )
}
