import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import type { RoomId } from '../../../commonTypes'
import Button from '../../../components/Button'
import type { RoomAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'

type RoomActionsPanelProps = {
    rooms: RoomAccessor[]
    onDelete: () => void
}

export const RoomActionsPanel = ({ rooms, onDelete }: RoomActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const getLecturesReferencingRoom = (roomId: RoomId) =>
        schedule.getLectures().filter((l) => l.getAssignedRooms().some((r) => r.getRoomId() === roomId))

    const handleDeleteRoom = () => {
        const problematicLectures = rooms.map((r) => r.getRoomId()).flatMap(getLecturesReferencingRoom)
        if (problematicLectures.length > 0) {
            message.error(t('LecturesReferencingSelectedRooms', { count: problematicLectures.length }))

            return
        }

        onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    rooms.map((r) =>
                        IScheduleTransform.roomDeleteTransform({
                            roomId: r.getRoomId()
                        })
                    )
                )
            )
        )
        message.success(t('RoomRemoved'))
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteRoom}>
                {t(rooms.length === 1 ? 'RemoveRoom' : 'RemoveRooms')}
            </Button>
        </Space>
    )
}
