import type { FilterDropdownProps } from 'antd/es/table/interface'
import { max, range } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useSchedule } from '../../../store/schedule/hooks'
import { EnumFilterDropdown } from '../../YesNoFilterDropdown'

export const LectureNumFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()
    const maxLectureNumber = max(schedule.getCourseRounds().map((cr) => cr.getLectures().length)) || 0
    const allLectureNumFilters = range(1, maxLectureNumber + 1).map((num) => ({
        value: `${num}`,
        text: `${num}`
    }))

    return (
        <EnumFilterDropdown {...props} allFilters={allLectureNumFilters} noKeysAvailableDescription={t('NoLectures')} />
    )
}
