import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { ClipboardEvent } from 'react'

export const clipboardToTabularData = (event: ClipboardEvent<HTMLElement>): string[][] => {
    try {
        const html = event.clipboardData.getData('text/html')
        if (html !== '') {
            const result: string[][] = []
            const parser = new DOMParser()
            const doc = parser.parseFromString(html, 'text/html')

            // Safely handle empty/invalid HTML
            if (!doc.body) {
                return []
            }

            const rows = doc.body.querySelectorAll('tr')
            if (!rows || rows.length === 0) {
                return []
            }

            Array.from(rows).forEach((row) => {
                const currentRow: string[] = []
                const cells = row.querySelectorAll('td')
                if (cells && cells.length > 0) {
                    Array.from(cells).forEach((cell) => {
                        currentRow.push(cell.textContent?.trim() ?? '')
                    })
                    if (currentRow.length > 0) {
                        result.push(currentRow)
                    }
                }
            })

            return result
        }

        const text = event.clipboardData.getData('text/plain')
        if (text !== '') {
            return [[text]]
        }

        return []
    } catch (error) {
        console.error('Error parsing clipboard data:', error)

        return []
    }
}

type HandlePasteOptions<T> = {
    columns: number[]
    currentRows: T[]
    event: ClipboardEvent<HTMLElement>
    generateEmptyRow: (dstRowIndex: number) => T
    indexLocation: IndexedLocation
    formatters?: Map<number, (input: string) => string>
}

export const handlePaste = <T extends Record<number, string>>({
    columns,
    currentRows,
    event,
    formatters,
    generateEmptyRow,
    indexLocation
}: HandlePasteOptions<T>): T[] => {
    const tabularData = clipboardToTabularData(event)
    const { rowIndex, colIndex } = indexLocation

    if (tabularData.length === 0) {
        return currentRows
    }

    const result = [...currentRows]

    for (let srcRowIndex = 0; srcRowIndex < tabularData.length; srcRowIndex++) {
        const srcRow = tabularData[srcRowIndex]
        const dstRowIndex = rowIndex + srcRowIndex

        if (dstRowIndex >= result.length) {
            result.push(generateEmptyRow(dstRowIndex))
        }

        const dstRow = result[dstRowIndex]

        for (let srcColIndex = 0; srcColIndex < srcRow.length; srcColIndex++) {
            const dstColIndex = colIndex + srcColIndex
            // +1 because there is an index column
            if (dstColIndex >= columns.length + 1) {
                break
            }

            let toPaste = tabularData[srcRowIndex][srcColIndex]
            if (formatters) {
                const formatter = formatters.get(dstColIndex)
                if (formatter) {
                    toPaste = formatter(toPaste)
                }
            }

            const dstCol = columns[dstColIndex - 1]
            dstRow[dstCol] = toPaste
        }
    }

    return result
}
