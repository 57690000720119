import classes from './style.module.css'

interface HourIntervalsProps {
    maxVisibleHours: number
}

function generateIntervals(maxVisibleHours: number): number[] {
    const step = 100
    const intervals: number[] = []

    // Add intervals in steps of 100 up to but not exceeding maxVisibleHours
    for (let i = 0; i <= maxVisibleHours - (maxVisibleHours % step); i += step) {
        intervals.push(i)
    }

    return intervals
}

export function HourIntervals({ maxVisibleHours }: HourIntervalsProps) {
    const hourIntervals = generateIntervals(maxVisibleHours)

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                {hourIntervals.map((interval) => {
                    const position = (interval / maxVisibleHours) * 100

                    return (
                        <div
                            key={interval}
                            className={classes.interval}
                            style={{
                                left: `${position}%`
                            }}
                        >
                            <div className={classes.line} />
                            <div className={classes.label}>{interval}h</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
