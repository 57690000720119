import { CheckOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TypeScale from '../../styles/TypeScale'
import { togglePresence } from '../../utils/collections'
import Button from '../Button'
import { FilterHighlight } from '../dev-util/FilterHighlight'

import classes from './style.module.css'
import { SearchPopupListProps } from './types'

function useVisibleOptions(
    options: SearchPopupListProps['options'],
    showAllOptions: boolean
): SearchPopupListProps['options'] {
    return useMemo(() => {
        if (showAllOptions) {
            return options
        }

        return options.slice(0, 5)
    }, [showAllOptions, options])
}

function SearchPopupList({
    options,
    onChangeValue,
    currentValue,
    title,
    filtering,
    onDeselect = () => {},
    onSelect = () => {}
}: SearchPopupListProps) {
    const [showingAllOptions, setShowingAllOptions] = useState(false)
    const { t } = useTranslation()

    const isFiltering = filtering.filterText.length > 0
    const showAllOptions = showingAllOptions || isFiltering

    const visibleOptions = useVisibleOptions(options, showAllOptions)

    function handleClickValue(value: string): void {
        onChangeValue(togglePresence(currentValue, value))
    }

    function handleOptionSelect(value: string, multiSelect: boolean): void {
        const selected = currentValue.includes(value)
        if (selected) {
            onDeselect(value, multiSelect)
        }

        if (!selected) {
            onSelect(value, multiSelect)
        }

        handleClickValue(value)
    }

    return (
        <div className={classes.container}>
            <p className={classNames(TypeScale.Label_SM_Regular, classes.title)}>{title}</p>
            <div className={classes.listContainer}>
                {visibleOptions.map((option, index) => {
                    const selected = currentValue.includes(option.value)

                    return (
                        <button
                            type="button"
                            key={index}
                            className={classNames(classes.listItem, {
                                [classes.listItemSelected]: selected
                            })}
                            onClick={(e) => {
                                handleOptionSelect(option.value, e.shiftKey)
                            }}
                        >
                            <p
                                className={classNames(TypeScale.Label_SM_Regular, {
                                    [TypeScale.Label_SM_Semibold]: selected
                                })}
                            >
                                <FilterHighlight text={option.displayLabel ?? option.label} filtering={filtering} />
                            </p>
                            {selected && <CheckOutlined className={classes.checkIcon} />}
                        </button>
                    )
                })}

                {!isFiltering && options.length > 5 && (
                    <Button
                        variant="tertiary"
                        onClick={() => {
                            setShowingAllOptions(!showingAllOptions)
                        }}
                        className={classes.showMoreLessBtn}
                        endIcon={
                            showAllOptions ? (
                                <UpOutlined style={{ fontSize: '85%' }} />
                            ) : (
                                <DownOutlined style={{ fontSize: '85%' }} />
                            )
                        }
                    >
                        {showAllOptions ? t('ShowLess') : t('ShowAll', { count: options.length })}
                    </Button>
                )}

                {visibleOptions.length === 0 && (
                    <span className={classes.noMatchesMessage}>
                        {t('No')} {title.toLowerCase()}
                    </span>
                )}
            </div>
        </div>
    )
}

export default SearchPopupList
