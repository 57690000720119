import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'

import type { LectureId } from '../../../commonTypes'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { ManyToManySelector } from '../../many-to-many-selector/ManyToManySelector'
import { LecturesListTooltip } from '../LecturesListTooltip'

type LectureLabelSelectorProps = {
    value?: Map<LectureId, string[]>
    onChange?: (newValue: Map<LectureId, string[]>) => void
}

export const LectureLabelSelector = ({ value, onChange }: LectureLabelSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    return (
        <ManyToManySelector<LectureId, string>
            value={value}
            mode="tags"
            onChange={onChange}
            preexistingOptionValues={uniq(schedule.getLectures().flatMap((l) => l.getLabels()))}
            optionLabelFn={(label) => label}
            partialCoverSuffix={(lectureIds) => (
                <LecturesListTooltip
                    title={t('ThisAttributeOnlyApplysToTheFollowingLectures')}
                    lectures={lectureIds.map((lectureId) => schedule.findLecture(lectureId))}
                />
            )}
        />
    )
}
