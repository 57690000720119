import { Tooltip } from 'antd'
import { memo, useCallback } from 'react'

import type { StudentGroupId } from '../../../commonTypes'
import type { StudentGroupAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../store/schedule/hooks'

type StudentGroupListProps = {
    studentGroupIds: StudentGroupId[]
    maxClauses?: number
    style?: React.CSSProperties
}

// Case 1: 1
// 1 group: "a"

// Case 2: <= MAX_CLAUSES
// 2 groups: "a och b"
// 4 groups: "a, b, c och d"
// 5 groups: "a, b, c, d och e"

// Case 3: > MAX_CLAUSES
// 6 groups: "a, b, c, d och 2 andra grupper"
const DEFAULT_MAX_CLAUSES = 5

const tooltipTitle = (groups: StudentGroupAccessor[]) => (
    <>
        {groups.map((sg) => (
            <div key={sg.getStudentGroupId()}>{sg.getDisplayName()}</div>
        ))}
    </>
)

const afterAndElements = (groups: StudentGroupAccessor[]) => {
    switch (groups.length) {
        case 0:
            return null
        case 1:
            return <>{` och ${groups[0].getDisplayName()}`}</>
        default:
            return (
                <>
                    {' och '}
                    <Tooltip title={tooltipTitle(groups)}>
                        <span style={{ textDecoration: 'underline black dashed' }}>{groups.length} andra grupper</span>
                    </Tooltip>
                </>
            )
    }
}

export const StudentGroupList = memo<StudentGroupListProps>(
    ({ studentGroupIds, style, maxClauses = DEFAULT_MAX_CLAUSES }) => {
        const schedule = useLocalSchedule()
        const studentGroupDisplayNames = studentGroupIds.map((sgId) => schedule.findStudentGroup(sgId))

        // Before 'och'

        // Num.    Before  After
        // groups  'and'   'and'
        // ---------------------
        // 1       1       0
        // 2       1       1
        // 3       2       1
        // 4       3       1
        // 5       4       1
        // 6       4       2 ("…and 2 more")
        // 7       4       3 ("…and 3 more")
        const numClausesBeforeAnd = Math.max(1, Math.min(studentGroupDisplayNames.length, maxClauses) - 1)
        const beforeAndGroups = studentGroupDisplayNames.slice(0, numClausesBeforeAnd)
        const afterAndGroups = studentGroupDisplayNames.slice(numClausesBeforeAnd)
        const renderAfterAndElements = useCallback(() => afterAndElements(afterAndGroups), [afterAndGroups])

        return (
            <span style={style}>
                {beforeAndGroups.map((sg) => sg.getDisplayName()).join(', ')}
                {renderAfterAndElements()}
            </span>
        )
    }
)
