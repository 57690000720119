import { Flex, Space } from 'antd'

import { getPalette } from '../../../../../utils/colors'

import { PaletteButton } from './PaletteButton'
import classes from './style.module.css'
import { PaletteColorPickerProps } from './types'

export const PaletteColorPicker = ({ onChange, value, assignedColors }: PaletteColorPickerProps) => {
    const palette = getPalette()
    const isCustom = palette.every(({paletteColor}) => paletteColor !== value)

    return (
        <Space direction="vertical" size="large">
            <div className={classes.paletteGrid}>
                {palette.map(({paletteColor, row, col}) => <PaletteButton
                    key={paletteColor}
                    paletteRow={row}
                    paletteColumn={col}
                    assignedColors={assignedColors.get(paletteColor) || []}
                    color={paletteColor}
                    onClick={() => onChange(paletteColor)}
                    isChecked={paletteColor === value}
                />)}
            </div>
            {isCustom && <Flex justify="center" align="center" gap={5}>
                <span>Anpassad färg:</span>
                <PaletteButton color={value} onClick={() => onChange(value)} assignedColors={assignedColors.get(value) || []} isChecked={true}/>
            </Flex>}
        </Space>
    )
}
