import Icon from '@ant-design/icons'
import { message, Space, Spin, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import { UploadFileStatus } from 'antd/es/upload/interface'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import SvgFileRoyalSchedule from '../../../icons/SvgFileRoyalSchedule'
import { Endpoints } from '../../../services/Endpoints'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { newScheduleSelectorsLoadedDueToScheduleUpdate } from '../../../store/scheduleselector/actions'

export const RoyalScheduleImportPanel = () => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    // RS
    const [fileUploadStatus, setFileUploadStatus] = useState<UploadFileStatus | undefined>(undefined)

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    const handleOnChange = (info: UploadChangeParam<UploadFile>) => {
        setFileUploadStatus(info.file.status)
        if (info.file.status === 'done') {
            message.success(t('Success.ImportComplete'))
        } else if (info.file.status === 'error') {
            message.error(t('Errors.ImportFailed'))
        }

        dispatch(newScheduleSelectorsLoadedDueToScheduleUpdate(schedule.getScheduleId(), []))
    }

    return (
        <Space direction="vertical" size="large">
            <Dragger
                disabled={fileUploadStatus === 'uploading'}
                name="file"
                action={`${Endpoints.API_URL}/rs-upload`}
                onChange={handleOnChange}
                showUploadList={false}
                withCredentials
            >
                <p className="ant-upload-drag-icon">
                    <Icon component={SvgFileRoyalSchedule} />
                </p>
                <p className="ant-upload-text">Klicka här för att välja RoyalSchedule-fil att ladda upp</p>
                <p className="ant-upload-hint">Den importerade filen kommer ersätta all data i aktuellt schema.</p>
            </Dragger>
            {fileUploadStatus === 'uploading' ? (
                <Space>
                    <Spin /> Importerar fil...
                </Space>
            ) : null}
        </Space>
    )
}
