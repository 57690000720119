import { Spin } from 'antd'
import { ReactNode } from 'react'

import { useIsDummySchedule } from '../../../store/schedule/hooks'

import classes from './style.module.css'

type EditTableLoadingSpinnerProps = {
    children: ReactNode
}

function EditTableLoadingSpinner({ children }: EditTableLoadingSpinnerProps) {
    const isDummySchedule = useIsDummySchedule()

    return isDummySchedule ? (
        <Spin className={classes.editTableLoadingSpinner} size="large">
            {children}
        </Spin>
    ) : (
        children
    )
}

export default EditTableLoadingSpinner
