import { PushpinFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import type { IDayAndTime } from 'common-api'

import { formatHHMM } from '../../../utils/DayAndTimeUtil'

type CornerPos = 'topleft' | 'bottomright'

export const TimestampCorner = ({
    pos,
    dayAndTime,
    pinned = false
}: {
    dayAndTime: IDayAndTime
    pos: CornerPos
    pinned?: boolean
}) => (
    <Corner pos={pos}>
        <CornerContent
            style={{ display: 'flex', fontWeight: 'bold', gap: '3px', alignItems: 'center', paddingTop: '.2em' }}
        >
            <div>{formatHHMM(dayAndTime)}</div>
            {pinned ? <PushpinFilled style={{ fontSize: '80%' }} /> : null}
        </CornerContent>
    </Corner>
)

const Corner = styled.div<{ pos: CornerPos }>`
    position: absolute;
    color: #333333;
    font-size: 100%;
    height: 1.3em;
    top: ${({ pos }) => (pos === 'topleft' ? '5px' : 'initial')};
    left: ${({ pos }) => (pos === 'topleft' ? '5px' : 'initial')};
    bottom: ${({ pos }) => (pos === 'bottomright' ? '5px' : 'initial')};
    right: ${({ pos }) => (pos === 'bottomright' ? '5px' : 'initial')};
`

const CornerContent = styled.span`
    position: relative;
    top: -0.15em;
`
