/** Constant reference to `undefined` that we expect to get minified and therefore reduce total code size */
var __undefined = undefined;
var MeitnerApi = /** @class */ (function () {
    function MeitnerApi(bridge) {
        this.bridge = bridge;
    }
    MeitnerApi.prototype.queryAuthenticationServiceGetAuthenticationClaims = function (request) {
        return this.bridge.call("MeitnerApi", "queryAuthenticationServiceGetAuthenticationClaims", "POST", "/v1/Query_AuthenticationService.GetAuthenticationClaims", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceList = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceList", "POST", "/oto/ScheduleService.List", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreate = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreate", "POST", "/oto/ScheduleService.Create", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListEventTypes = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListEventTypes", "POST", "/v1/Query_EventService.ListEventTypes", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreateTeacher = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreateTeacher", "POST", "/oto/ScheduleService.CreateTeacher", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceUpdateTeacher = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceUpdateTeacher", "POST", "/oto/ScheduleService.UpdateTeacher", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListTeachers = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListTeachers", "POST", "/oto/ScheduleService.ListTeacher", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceDeleteTeacher = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceDeleteTeacher", "POST", "/oto/ScheduleService.DeleteTeacher", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreateRoom = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreateRoom", "POST", "/oto/ScheduleService.CreateRoom", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceUpdateRoom = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceUpdateRoom", "POST", "/oto/ScheduleService.UpdateRoom", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListRooms = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListRooms", "POST", "/oto/ScheduleService.ListRoom", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceDeleteRoom = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceDeleteRoom", "POST", "/oto/ScheduleService.DeleteRoom", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreatePeriod = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreatePeriod", "POST", "/oto/ScheduleService.CreatePeriod", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceUpdatePeriod = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceUpdatePeriod", "POST", "/oto/ScheduleService.UpdatePeriod", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListPeriods = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListPeriods", "POST", "/oto/ScheduleService.ListPeriod", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceDeletePeriod = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceDeletePeriod", "POST", "/oto/ScheduleService.DeletePeriod", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreateGroup = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreateGroup", "POST", "/oto/ScheduleService.CreateGroup", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceUpdateGroup = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceUpdateGroup", "POST", "/oto/ScheduleService.UpdateGroup", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListGroups = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListGroups", "POST", "/oto/ScheduleService.ListGroup", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceDeleteGroup = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceDeleteGroup", "POST", "/oto/ScheduleService.DeleteGroup", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreateCourse = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreateCourse", "POST", "/oto/ScheduleService.CreateCourse", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceUpdateCourse = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceUpdateCourse", "POST", "/oto/ScheduleService.UpdateCourse", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListCourses = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListCourses", "POST", "/oto/ScheduleService.ListCourse", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceDeleteCourse = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceDeleteCourse", "POST", "/oto/ScheduleService.DeleteCourse", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceCreateLesson = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceCreateLesson", "POST", "/oto/ScheduleService.CreateLesson", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceUpdateLesson = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceUpdateLesson", "POST", "/oto/ScheduleService.UpdateLesson", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceListLessons = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceListLessons", "POST", "/oto/ScheduleService.ListLesson", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    MeitnerApi.prototype.scheduleServiceDeleteLesson = function (request) {
        return this.bridge.call("MeitnerApi", "scheduleServiceDeleteLesson", "POST", "/oto/ScheduleService.DeleteLesson", request, __undefined, __undefined, __undefined, __undefined, __undefined);
    };
    return MeitnerApi;
}());
export { MeitnerApi };
