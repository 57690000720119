import { Button, Checkbox, Divider, Empty, Flex } from 'antd'
import { useTranslation } from 'react-i18next'

import { FilterInput } from '../../pages/schedule/components/FilterInput'
import { useFiltering } from '../../pages/schedule/components/FilterInput/hooks'
import { columnFilterItemComparator } from '../../pages/schedule/components/ScheduleSearchSelector/utils'
import { setPresence } from '../../utils/collections'
import { FilterHighlight } from '../dev-util/FilterHighlight'
import { ValueSelectionCheckbox } from '../ValueSelectionCheckbox'

import type { FilterableFilterDropdownProps } from './types'

export const FilterableFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters = () => {},
    allFilters,
    noKeysAvailableDescription
}: FilterableFilterDropdownProps) => {
    const filtering = useFiltering()
    const { t } = useTranslation()
    const filteredFilters = allFilters.toSorted(columnFilterItemComparator).filter((key) => filtering.isMatch(key.text))
    const allFiltersAreSelected = filteredFilters.every((filter) => selectedKeys.includes(filter.value))
    const noFiltersAreSelected = !filteredFilters.some((filter) => selectedKeys.includes(filter.value))

    const handleReset = () => {
        clearFilters()
        handleOk()
    }

    const handleOk = () => {
        filtering.setFilterText('')
        confirm({ closeDropdown: true })
    }

    const noFiltersAvailable = allFilters.length === 0

    return (
        <Flex vertical gap={8} style={{ padding: 8, width: '25em' }}>
            <FilterInput {...filtering} disabled={noFiltersAvailable} />
            {noFiltersAvailable ? (
                <Empty
                    style={{ margin: '2em 0' }}
                    description={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{noKeysAvailableDescription}</span>}
                />
            ) : (
                <>
                    <Checkbox
                        checked={allFiltersAreSelected}
                        indeterminate={!allFiltersAreSelected && !noFiltersAreSelected}
                        onChange={({ target: { checked } }) => {
                            const filteredFilterKeys = filteredFilters.map((fk) => fk.value)
                            setSelectedKeys(setPresence(selectedKeys, checked, filteredFilterKeys))
                        }}
                    >
                        <span style={{ fontStyle: 'italic' }}>{t('AllVisible')}</span>
                    </Checkbox>
                    <Divider style={{ margin: 0 }} />
                    <Flex vertical style={{ maxHeight: '50vh', overflow: 'scroll' }} gap={3} align="stretch">
                        {filteredFilters.map((filter) => (
                            <ValueSelectionCheckbox
                                key={filter.value}
                                value={filter.value}
                                selectedValues={selectedKeys}
                                setSelectedValues={setSelectedKeys}
                            >
                                <FilterHighlight text={filter.text} filtering={filtering} />
                            </ValueSelectionCheckbox>
                        ))}
                    </Flex>
                </>
            )}
            <Flex gap={8} align="center">
                <div style={{ flex: 1, textAlign: 'center' }}>
                    {noFiltersAvailable
                        ? ''
                        : selectedKeys.length === 0
                          ? 'Ingen filtrering'
                          : `${selectedKeys.length} / ${allFilters.length}`}
                </div>
                <Button disabled={noFiltersAvailable} onClick={handleReset}>
                    {t('Reset')}
                </Button>
                <Button type="primary" onClick={handleOk}>
                    {t('Ok')}
                </Button>
            </Flex>
        </Flex>
    )
}
