import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { ICourse } from 'common-api'
import { useTranslation } from 'react-i18next'

import { DiffCard } from '../../../../../../EditTable/components/DiffCard'
import { DiffResult } from '../../../../../../EditTable/utils'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import { conjureSubjectsFromSubjects } from '../../../../../teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/hooks'

type CourseListDiffProps = {
    diff: DiffResult<ICourse>
}

const CourseListDiff = ({ diff: diffToShow }: CourseListDiffProps) => {
    const { t } = useTranslation()
    const schedule = useLocalSchedule()

    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())

    const findSubjectCode = (subjectId: string) => {
        const subject = liveSubjects.find((s) => s.subjectId === subjectId)

        return subject?.code ?? subjectId
    }

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue === newValue ? null : (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    return (
        <Flex gap={8}>
            <DiffCard
                title={t('CourseListChanges.NewCourses')}
                icon={<PlusOutlined />}
                noChangesText={t('CourseListChanges.NoNewCourses')}
                changes={diffToShow.created}
                renderChange={(course) => <li key={course.courseId}>{course.code}</li>}
            />
            <DiffCard
                title={t('CourseListChanges.UpdatedCourses')}
                icon={<EditOutlined />}
                noChangesText={t('CourseListChanges.NoUpdatedCourses')}
                changes={diffToShow.updated}
                renderChange={([prev, next]) => (
                    <li key={prev.courseId}>
                        {prev.name}
                        <ul>
                            {propertyDiffListItem(t('Course'), prev.name, next.name)}
                            {propertyDiffListItem(t('Code'), prev.code, next.code)}
                            {propertyDiffListItem(
                                t('Subject'),
                                findSubjectCode(prev.subjectId),
                                findSubjectCode(next.subjectId)
                            )}
                        </ul>
                    </li>
                )}
            />
            <DiffCard
                title={t('CourseListChanges.DeletedCourses')}
                icon={<MinusOutlined />}
                noChangesText={t('CourseListChanges.NoRemovedCourses')}
                changes={diffToShow.deleted}
                renderChange={(course) => <li key={course.courseId}>{course.code}</li>}
            />
        </Flex>
    )
}

export default CourseListDiff
