import classNames from 'classnames'
import { forwardRef } from 'react'

import packageJSON from '../../../../package.json'

import classes from './style.module.css'
import type { IconNames } from './types'

type IconProps = {
    name: IconNames
    className?: string
}

/**
 * The Icon component is used to render an SVG icon from the icon sprite.
 *
 * @param {IconNames} props.name - The name of the icon to render
 */
const Icon = forwardRef<SVGSVGElement, IconProps>(({ name, className }: IconProps, ref) => {
    // We add the package version as a parameter to bust the cache when the main package is updated
    // TODO: Do not use LMS package version here. Get it some other way!
    const symbolId = `/icon-sprite.svg?v=${packageJSON.version}#${name}`

    const getClasses = classNames(classes.icon, className, {
        [classes['icon--16']]: name.endsWith('-16'),
        [classes['icon--24']]: name.endsWith('-24'),
        [classes['icon--32']]: name.endsWith('-32')
    })

    // Icon is considered a purely decorative element, so we hide it from screen readers (aria-hidden="true")
    return (
        <svg className={getClasses} aria-hidden="true" ref={ref}>
            <use href={symbolId} />
        </svg>
    )
})

export default Icon
