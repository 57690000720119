import { ISubject } from 'common-api'
import { v4 as uuid } from 'uuid'

import { matchRowsWithExistingData } from '../../../../../EditTable/data'
import { combinedCmpFn, comparing } from '../../../../../utils/compareUtil'

import { SubjectGridRow, SubjectGridContentColumn, SubjectGridMetaDataColumn } from './types'
import { isEmptySubjectRow } from './utils'



export const subjectCmp = combinedCmpFn<ISubject>(
    comparing((s) => s.name),
    comparing((s) => s.code)
)

const matchScore = (row: SubjectGridRow, subject: ISubject) =>
    (subject.name === row[SubjectGridContentColumn.Name] ? 1 : 0) +
    (subject.code === row[SubjectGridContentColumn.Code] ? 1 : 0)

export const parseSubjectRows = (existingSubjects: ISubject[], rowsIncludingEmpty: SubjectGridRow[]): ISubject[] => {
    const rows = rowsIncludingEmpty.filter((row) => !isEmptySubjectRow(row))

    const matchedSubjects = matchRowsWithExistingData<ISubject, SubjectGridRow>({
        existingData: existingSubjects,
        rows,
        matchRow: SubjectGridMetaDataColumn.RowIndex,
        matchScore: (row, subject) => matchScore(row, subject),
        maximumScore: 2,
        minimumAcceptableScore: 1
    })

    return rows.map((row) => {
        const matchingSubject = matchedSubjects.get(row[SubjectGridMetaDataColumn.RowIndex])
        const code = row[SubjectGridContentColumn.Code]

        return {
            subjectId: matchingSubject?.subjectId ?? uuid(),
            name: row[SubjectGridContentColumn.Name],
            code,
            color: matchingSubject?.color ?? 'ffffff'
        }
    })
}
