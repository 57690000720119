import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { IRoom } from 'common-api'
import { useTranslation } from 'react-i18next'

import { DiffCard } from '../../../../../EditTable/components/DiffCard'
import { DiffResult } from '../../../../../EditTable/utils'

type RoomListDiffProps = {
    diff: DiffResult<IRoom>
}

const RoomListDiff = ({ diff: diffToShow }: RoomListDiffProps) => {
    const { t } = useTranslation()

    const propertyDiffListItem = (label: string, oldValue: string, newValue: string) =>
        oldValue === newValue ? null : (
            <li>
                {label}: <del>{oldValue}</del> ⇒ {newValue}
            </li>
        )

    return (
        <Flex gap={8}>
            <DiffCard
                title={t('RoomListChanges.NewRooms')}
                icon={<PlusOutlined />}
                noChangesText={t('RoomListChanges.NoNewRooms')}
                changes={diffToShow.created}
                renderChange={(room) => <li key={room.roomId}>{room.name}</li>}
            />
            <DiffCard
                title={t('RoomListChanges.UpdatedRooms')}
                icon={<EditOutlined />}
                noChangesText={t('RoomListChanges.NoUpdatedRooms')}
                changes={diffToShow.updated}
                renderChange={([prev, next]) => (
                    <li key={prev.roomId}>
                        {prev.name}
                        <ul>
                            {propertyDiffListItem(t('Room'), prev.name, next.name)}

                            {propertyDiffListItem(t('RoomId'), prev.roomId, next.roomId)}
                            {propertyDiffListItem(
                                t('RoomAttributes'),
                                prev.attributes.join(','),
                                next.attributes.join(',')
                            )}
                        </ul>
                    </li>
                )}
            />
            <DiffCard
                title={t('RoomListChanges.DeletedRooms')}
                icon={<MinusOutlined />}
                noChangesText={t('RoomListChanges.NoRemovedRooms')}
                changes={diffToShow.deleted}
                renderChange={(room) => <li key={room.roomId}>{room.name}</li>}
            />
        </Flex>
    )
}

export default RoomListDiff
