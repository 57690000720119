import { Radio } from 'antd'
import { DayOfWeek } from 'common-api'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from '../../../../store'
import { setSelectedWeekDays } from '../../../../store/weekDaySelector/actions'
import { WeekDaySelectorState } from '../../../../store/weekDaySelector/types'
import { togglePresence } from '../../../../utils/collections'
import { useNameOfDay } from '../../../../utils/weekUtil'
import { DAYS } from '../util'

import classes from './style.module.css'

const WeekDaySelector = () => {
    const selectedWeekDays = useSelector<ApplicationState, WeekDaySelectorState['selectedWeekDays']>(
        (state) => state.weekDaySelector.selectedWeekDays
    )
    const { getShortDayName } = useNameOfDay()

    const dispatch = useDispatch()

    const handleOnDayClick = (day: DayOfWeek) => {
        const newSelectedWeekDays = togglePresence(selectedWeekDays, day)

        dispatch(setSelectedWeekDays(newSelectedWeekDays))
    }

    return (
        <div className={classes.weekDaySelector}>
            {DAYS.map((day) => {
                const isActive = selectedWeekDays.includes(day)

                return (
                    <Radio.Button
                        key={day}
                        value={day}
                        onClick={() => handleOnDayClick(day)}
                        checked={isActive}
                        type="primary"
                    >
                        {getShortDayName(day)}
                    </Radio.Button>
                )
            })}
        </div>
    )
}

export default WeekDaySelector
