import { CloudTwoTone } from '@ant-design/icons'
import { Space } from 'antd'

import { SseConnectionState } from '../../store/devmode/types'

import { SseConnectionIndicatorPros } from './types'

export const SseConnectionIndicator = ({ connectionState, label, subscribersExist }: SseConnectionIndicatorPros) => {
    const color = !subscribersExist
        ? '#bbb'
        : connectionState === SseConnectionState.OPEN
          ? '#00762a'
          : connectionState === SseConnectionState.CLOSED
            ? '#ff9a2c'
            : connectionState === SseConnectionState.ERROR
              ? '#ce001c'
              : '#6b6b6b'

    return (
        <Space>
            <CloudTwoTone twoToneColor={color} />
            <span style={{ color: subscribersExist ? 'inherit' : '#bbb' }}>{label}</span>
        </Space>
    )
}
