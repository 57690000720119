import { fullJoin } from '../export-util'

import { SrcToDstIdMap } from './SrcToDstIdMap'

export async function exportEntities<SRC, DST>(
    dstScheduleId: string,
    srcIdFn: (e: SRC) => string,
    dstIdFn: (e: DST) => string | null,
    dstInternalIdFn: (e: DST) => string,
    listSrcEntities: () => SRC[],
    listDstEntities: (scheduleId: string) => Promise<DST[]>,
    createDstEntity: (scheduleId: string, e: SRC) => Promise<string>,
    deleteDstEntity: (dstInternalId: string) => Promise<void>,
    updateDstEntity: (src: SRC, dst: DST) => Promise<string>,
    serializeRequests?: boolean
): Promise<SrcToDstIdMap> {
    const idsMap = new SrcToDstIdMap()
    const entitiesInSource = listSrcEntities()
    const entitiesInDestination = await listDstEntities(dstScheduleId)
    const pairedEntities = fullJoin(entitiesInSource, srcIdFn, entitiesInDestination, dstIdFn)
    const actions = []

    // First: Delete existing entities before moving on to updating and creating new entities to mitigate the risk of
    // violating constraints such as uniqueness of certain names.
    for (const [srcEntity, dstEntity] of pairedEntities) {
        if (srcEntity === undefined && dstEntity !== undefined) {
            actions.push(() => deleteDstEntity(dstInternalIdFn(dstEntity)))
        }
    }

    // Next: Update existing entities in case any entity (prior to being updated) have the same name as an entity that
    // is to be created.
    //
    // There can still be constraint violations in this phase, such as if two entities have swapped names. Leaving this
    // as a todo for the time being.
    for (const [srcEntity, dstEntity] of pairedEntities) {
        if (srcEntity !== undefined && dstEntity !== undefined) {
            actions.push(() =>
                updateDstEntity(srcEntity, dstEntity).then((dstEntityId) => {
                    idsMap.addMapping(srcIdFn(srcEntity), dstEntityId)
                })
            )
        }
    }

    // Last: Create new entities
    for (const [srcEntity, dstEntity] of pairedEntities) {
        if (srcEntity !== undefined && dstEntity === undefined) {
            actions.push(() =>
                createDstEntity(dstScheduleId, srcEntity).then((dstEntityId) =>
                    idsMap.addMapping(srcIdFn(srcEntity), dstEntityId)
                )
            )
        }
    }

    if (serializeRequests) {
        await actions.reduce((promise, action) => promise.then(action), Promise.resolve())
    } else {
        const promises = actions.map((a) => a())
        await Promise.allSettled(promises)
    }

    return idsMap
}
