import { Select, TimePicker } from 'antd'
import type { DayOfWeek } from 'common-api'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import { VISIBLE_DAYS } from '../pages/schedule/components/util'
import { seDayName } from '../utils/DayAndTimeUtil'
import { optMap } from '../utils/optionals'
import { TogglePin } from '../utils/TogglePin'

import type { DayAndTimeWithPin } from './lectures/LectureDetails/types'

type DayAndTimeWithPinInputProps = {
    value?: DayAndTimeWithPin
    onChange?: (newDayAndTimeWithPin: DayAndTimeWithPin) => void
}

type DayOption = DayOfWeek | 'unset'

export const DayAndTimeWithPinInput = ({ onChange, value }: DayAndTimeWithPinInputProps) => {
    const day = value?.dayAndTime?.day || 'unset'
    const time = optMap(value?.dayAndTime, ({ hour, minute }) => dayjs().set('hour', hour).set('minute', minute))
    const isPinned = value?.pinned || false

    const onChangeWrapper = (newDay: DayOption, newTime: Dayjs | undefined, newPin: boolean) => {
        if (typeof onChange === 'function') {
            onChange({
                dayAndTime: optMap(newDay === 'unset' ? undefined : newDay, (d) => ({
                    day: d,
                    hour: newTime?.hour() || 8,
                    minute: newTime?.minute() || 0
                })),
                pinned: newPin
            })
        }
    }

    const onDayChange = (newDay: DayOption) => {
        onChangeWrapper(newDay, time, isPinned)
    }

    const onTimeChange = (newTime: Dayjs, dateString: string | string[]) => {
        onChangeWrapper(day, newTime, isPinned)
    }

    const onPinChange = (newPin: boolean) => {
        onChangeWrapper(day, time, newPin)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Select
                style={{ flex: '2 0 0' }}
                allowClear
                value={day}
                onChange={onDayChange}
                options={[
                    { value: 'unset', label: 'Ej schemalagd' },
                    ...VISIBLE_DAYS.map((day) => ({ value: day, label: seDayName(day) }))
                ]}
            />
            <TimePicker
                style={{ flex: '1 0 0' }}
                value={time}
                disabled={day === 'unset'}
                format="HH:mm"
                hideDisabledOptions
                disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23] })}
                minuteStep={5}
                onChange={onTimeChange}
            />
            <div style={{ flex: '0 1 0' }}>
                <TogglePin isPinned={isPinned} onChange={onPinChange} />
            </div>
        </div>
    )
}
