import { DownloadOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import dayjs from 'dayjs'

import Button from '../../../components/Button'
import { useIsDummySchedule, useLocalSchedule } from '../../../store/schedule/hooks'
import { toTranslate } from '../../../utils/miscUtil'

export const DownloadSchedulePanel = () => {
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()

    const downloadSchedule = () => {
        const fileData = JSON.stringify(schedule.getConjureSchedule())
        const blob = new Blob([fileData], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url

        const yourDate = dayjs()

        a.download = `${yourDate.format('YYYY-MM-DD_HH-mm-ss')}.json`
        a.click()
    }

    return (
        <Space direction="vertical" size="large">
            <p>{toTranslate('Här kan du ladda ner aktuell version av schemat.')}</p>
            <Button
                startIcon={<DownloadOutlined />}
                disabled={isDummySchedule}
                variant="primary"
                onClick={downloadSchedule}
            >
                {toTranslate('Ladda ner schemafil')}
            </Button>
        </Space>
    )
}
