import { clamp } from 'lodash'

export const intDiv = (numerator: number, denominator: number): [number, number] => [
    Math.trunc(numerator / denominator),
    numerator % denominator
]

export const mapLinearly = (v: number, fromMin: number, fromMax: number, toMin = 0, toMax = 1) => {
    const f = fromMin === fromMax ? 0 : clamp((v - fromMin) / (fromMax - fromMin), 0, 1)

    return f * (toMax - toMin) + toMin
}
