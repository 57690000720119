import { Empty, Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import type { ILectureDurationThresholds, IMinBreakThresholds, IRoomAssignment, IWeekSelection } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import type { LectureId } from '../../../commonTypes'
import useFormProps from '../../../form/hooks/useFormProps'
import { displayNameForLecture } from '../../../pages/lectures/utils'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'
import BulkGeneralInput from '../../BulkGeneralInput'
import { BulkSingleSelect } from '../../BulkSingleSelect'
import Button from '../../Button'
import { MinBreakThresholdsInput } from '../../course-rounds/MinBreakThresholdsInput'
import { DayAndTimeWithPinInput } from '../../DayAndTimeWithPinInput'
import { NonInput } from '../../forms/NonInput'
import { LectureDurationThresholdsInput } from '../../LectureDurationThresholdsInput'
import { MinutesInput } from '../../MinutesInput'
import { RoomAssignmentListInput } from '../../RoomAssignmentListInput'
import { WeekSelectionInput2 } from '../../WeekSelectionInput2'
import { SinglePartialBlockInputMessage } from '../SinglePartialBlockInputMessage'

import { LectureLabelSelector } from './LectureLabelSelector'
import { TeacherOverrideInput } from './TeacherOverrideInput'
import type { TeacherOverrideValue } from './TeacherOverrideInput/types'
import type { LectureFormValues } from './types'

type LectureDetailsProps = {
    lectureIds: LectureId[]
}

export const LectureDetails = ({ lectureIds }: LectureDetailsProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [form] = useForm()
    const { t } = useTranslation()
    const { antFormProps, buttonProps, container } = useFormProps()

    // If selected lecture has been deleted (for example in another browser tab) the selection may have become invalid.
    const validSelectedLectureIds = lectureIds.filter((lectureId) => schedule.doesLectureIdExist(lectureId))

    if (validSelectedLectureIds.length === 0) {
        return <Empty description={t('NoLessonSelected')} />
    }

    const lectures = lectureIds.map((lid) => schedule.findLecture(lid))
    lectures.sort(comparing(displayNameForLecture))

    // Determine how to present event group form components
    const firstEg = lectures[0].getEventGroup()
    const singleEventGroup = lectures.every((l) => l.getEventGroup().getEventGroupId() === firstEg.getEventGroupId())
    const lecturesCoverEntireEventGroup = singleEventGroup && lectures.length === firstEg.getLectures().length
    const useGroupLevelFormValues = lecturesCoverEntireEventGroup

    const initialValues: LectureFormValues = {
        dayAndTimeWithPin: {
            dayAndTime: firstEg.getDayAndTime(),
            pinned: firstEg.isTimeslotPinned()
        },
        lectureIds: lectures.map((l) => l.getLectureId()),
        courseRoundIds: lectures.map((l) => l.getCourseRound().getCourseRoundId()),
        teacherIds: lectures.map((l) => l.getTeachers()?.map((t) => t.getTeacherId())),
        roomAssignments: lectures.map((l) => l.getRoomAssignments().map((ra) => ra.getConjureObject())),
        weekSelections: lectures.map((l) => l.getWeekSelection().getConjureWeekSelection()),
        durationsInMinutes: lectures.map((l) => l.getDurationInMinutes()),
        labels: new Map(lectures.map((l) => [l.getLectureId(), l.getLabels()])),
        minBreakThresholds: lectures.map((l) => l.getMinBreakThresholds()),
        lectureDurationThresholds: lectures.map((l) => l.getLectureDurationThresholds())
    }

    const saveLectures = (formValues: LectureFormValues) => {
        // Maybe save event group
        const eventGroupTransforms = []
        if (useGroupLevelFormValues) {
            eventGroupTransforms.push(
                IScheduleTransform.eventGroupTransform({
                    newEventGroup: {
                        ...firstEg.getConjureObject(),
                        dayAndTime: formValues.dayAndTimeWithPin.dayAndTime,
                        timeslotPinned: formValues.dayAndTimeWithPin.pinned
                    }
                })
            )
        }

        // Save lectures
        const lectureTransforms = formValues.lectureIds.map((lectureId, index) =>
            IScheduleTransform.lectureTransform({
                newLecture: {
                    ...lectures[index].getConjureLecture(),
                    courseRoundId: formValues.courseRoundIds[index],
                    teacherIds: formValues.teacherIds[index],
                    roomAssignments: formValues.roomAssignments[index],
                    weekSelection: formValues.weekSelections[index],
                    durationInMinutes: formValues.durationsInMinutes[index],
                    labels: formValues.labels.get(lectureId)!,
                    minBreakThresholds: formValues.minBreakThresholds[index],
                    lectureDurationThresholds: formValues.lectureDurationThresholds[index]
                }
            })
        )
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform([...eventGroupTransforms, ...lectureTransforms])
            )
        )

        message.success(lectures.length === 1 ? t('LectureSaved') : t('LecturesSaved'))
    }

    return (
        <div ref={container}>
            <Form form={form} initialValues={initialValues} onFinish={saveLectures} {...antFormProps}>
                <Form.Item name="dayAndTimeWithPin" label={t('DayAndTime')}>
                    {useGroupLevelFormValues ? (
                        <DayAndTimeWithPinInput />
                    ) : singleEventGroup ? (
                        <SinglePartialBlockInputMessage
                            eventGroupId={firstEg.getEventGroupId()}
                            numLecturesSelected={lectures.length}
                        />
                    ) : (
                        <NonInput>{t('SelectLecturesFromSameBlockToEditDayAndTime')}</NonInput>
                    )}
                </Form.Item>
                <Form.List name="lectureIds">
                    {(fields) =>
                        fields.map((field) => (
                            <Form.Item hidden {...field}>
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.Item name="courseRoundIds" label={t('CourseOffering')}>
                    <BulkSingleSelect
                        originalValue={lectures.map((l) => l.getCourseRound().getCourseRoundId())}
                        options={schedule
                            .getCourseRounds()
                            .map((cr) => ({
                                value: cr.getCourseRoundId(),
                                label: cr.getDisplayName()
                            }))
                            .toSorted(comparing((opt) => opt.label))}
                    />
                </Form.Item>
                <Form.Item name="teacherIds" label={t('Teachers')}>
                    <BulkGeneralInput<TeacherOverrideValue>
                        renderInput={(value, onChange) => (
                            <TeacherOverrideInput
                                inheritedTeacherIds={lectures[0]
                                    .getCourseRound()
                                    .getTeachers()
                                    .map((t) => t.getTeacherId())}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        multiplicityMessage={t('SelectSingleLectureToEditTeachers')}
                    />
                </Form.Item>
                <Form.Item name="roomAssignments" label={t('Rooms')}>
                    <BulkGeneralInput<IRoomAssignment[]>
                        renderInput={(value, onChange) => <RoomAssignmentListInput {...{ value, onChange }} />}
                        multiplicityMessage={t('SelectSingleLectureToEditRooms')}
                    />
                </Form.Item>
                <Form.Item name="weekSelections" label={t('Weeks')}>
                    <BulkGeneralInput<IWeekSelection>
                        renderInput={(value, onChange) => <WeekSelectionInput2 value={value} onChange={onChange} />}
                        multiplicityMessage={t('SelectSingleLectureToEditWeekSettings')}
                    />
                </Form.Item>
                <Form.Item name="durationsInMinutes" label={t('Duration')}>
                    <BulkGeneralInput<number>
                        renderInput={(value, onChange) => <MinutesInput value={value} onChange={onChange} />}
                        multiplicityMessage={t('SelectSingleLectureToEditDuration')}
                    />
                </Form.Item>
                <Form.Item label={t('Labels')} name="labels">
                    <LectureLabelSelector />
                </Form.Item>
                <Form.Item name="minBreakThresholds" label={t('BreakSettings')}>
                    <BulkGeneralInput<IMinBreakThresholds | undefined>
                        renderInput={(value, onChange) => <MinBreakThresholdsInput value={value} onChange={onChange} />}
                        multiplicityMessage={t('SelectSingleLectureToEditBreakSettings')}
                    />
                </Form.Item>
                <Form.Item name="lectureDurationThresholds" label={t('LectureDuration')}>
                    <BulkGeneralInput<ILectureDurationThresholds | undefined>
                        renderInput={(value, onChange) => (
                            <LectureDurationThresholdsInput
                                value={value}
                                onChange={onChange}
                                inheritLabels={{
                                    currentEntityTypeName: t('LectureSmall'),
                                    inheritEntityTypeName: t('CourseOffering')
                                }}
                                inheritedValues={lectures[0].getLectureDurationThresholds(true)}
                            />
                        )}
                        multiplicityMessage={t('SelectSingleLectureToEditDurationSettings')}
                    />
                </Form.Item>
                <Form.Item {...buttonProps}>
                    <Button type="submit" variant="primary" size="md" fullWidth>
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
