import styled from '@emotion/styled'
import { DayOfWeek, ITimeOfDay } from 'common-api'

import type { LayoutSpec } from './components/util'
import { positioningHeightRaw, positioningLeft, positioningTop, positioningWidth } from './components/util'

const PageContent = styled('article')`
    display: flex;
`

const Blocked = styled('div')`
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-size: 8px 8px;
    border: 1px solid red;
    background-image: repeating-linear-gradient(-45deg, red 0, red 1px, white 0, white 50%);
    overflow: hidden;
    padding: 0em 0.5em;
    padding-top: 1.5em;
`

interface PositioningProps {
    startOfDay: ITimeOfDay
    endOfDay: ITimeOfDay
    transition: string
    opacity: number
    zIndex: number | string
    layoutSpec: LayoutSpec
    duration: number
    selectedWeekDays: DayOfWeek[]
}

export const Positioned = styled('div')<PositioningProps>`
    transition: ${(props) => props.transition};
    opacity: ${(props) => props.opacity};
    position: absolute;
    z-index: ${(props) => props.zIndex};
    top: ${(props) => positioningTop(props.startOfDay, props.endOfDay, props.layoutSpec)}%;
    left: ${(props) => positioningLeft(props.layoutSpec, props.selectedWeekDays)}%;
    width: ${(props) => positioningWidth(props.layoutSpec, props.selectedWeekDays.length)}%;
    height: ${(props) => positioningHeightRaw(props.startOfDay, props.endOfDay, props.duration)}%;
    padding-right: 1px;
    padding-left: 1px;
`

export { Blocked, PageContent }
