import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space, type DropdownProps, type MenuProps } from 'antd'
import type { Key } from 'antd/es/table/interface'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ValueSelectionCheckbox } from '../ValueSelectionCheckbox'

import type { VisibleColumnsSelectorProps } from './types'

export const VisibleColumnsSelector = <ColumnsKeyType extends Key, ColType>({
    columns,
    disabled,
    setVisibleColumns
}: VisibleColumnsSelectorProps<ColumnsKeyType, ColType>) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const { t } = useTranslation()
    const visibleColumnKeys = columns.filter((c) => !c.hidden).map((c) => c.key as ColumnsKeyType)

    const items: MenuProps['items'] = columns.map((c) => ({
        label: (
            <ValueSelectionCheckbox
                disabled={visibleColumnKeys.length === 1 && visibleColumnKeys.includes(c.key as ColumnsKeyType)}
                value={c.key as ColumnsKeyType}
                selectedValues={visibleColumnKeys}
                setSelectedValues={setVisibleColumns}
            >
                {c.title as string}
            </ValueSelectionCheckbox>
        ),
        key: c.key as number
    }))

    const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setMenuOpen(nextOpen)
        }
    }

    return (
        <Dropdown menu={{ items }} open={menuOpen} onOpenChange={handleOpenChange} disabled={disabled}>
            <Button>
                <Space>
                    {t('Columns')}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}
