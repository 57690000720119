import { ScheduleType } from 'common-api'
import { useTranslation } from 'react-i18next'

import { useLocalSchedule } from '../../store/schedule/hooks'
import Sidebar from '../Sidebar'
import { SidebarMenuItem } from '../Sidebar/types'

const MenuSider = () => {
    const { t } = useTranslation()

    const schedule = useLocalSchedule()

    const showCourses = schedule.getSettings().scheduleType === ScheduleType.GY

    const menuItems: SidebarMenuItem[] = [
        {
            label: t('MainMenu.Schedule'),
            href: '/schedule',
            iconName: 'icon-calendar-24'
        },
        {
            label: t('MainMenu.Lectures'),
            href: '/lectures',
            iconName: 'icon-list-bullet-24'
        },
        {
            label: t('MainMenu.ServiceDistribution'),
            href: '/course-rounds',
            iconName: 'icon-document-lines-24',
            subItems: [
                {
                    label: 'Kursomgångar',
                    href: '/course-rounds'
                },
                {
                    label: t('GraphicalView'),
                    href: '/teaching-assignments'
                }
            ]
        },
        {
            label: t('Settings'),
            href: '/schedule-parameters',
            iconName: 'icon-settings-24'
        },
        {
            label: t('Staff'),
            href: '/teachers',
            iconName: 'icon-users-24'
        },
        {
            label: t('MainMenu.Rooms'),
            href: '/rooms',
            iconName: 'icon-building-24'
        },
        {
            label: t('MainMenu.StudentGroups'),
            href: '/student-groups',
            iconName: 'icon-users-24'
        },
        {
            label: t('MainMenu.Periods'),
            href: '/periods',
            iconName: 'icon-clock-circle-outline-24'
        },
        {
            label: t('MainMenu.Courses'),
            href: '/courses',
            iconName: 'icon-document-lines-24',
            visible: showCourses
        },
        {
            label: t('Subjects'),
            href: '/subjects',
            iconName: 'icon-document-lines-24'
        },
        {
            label: t('MainMenu.Reports'),
            href: '/reports',
            iconName: 'icon-charts-24',
            subItems: [
                {
                    label: t('MainMenu.CanteenLoad'),
                    href: '/reports/canteen-load'
                }
            ]
        },
        {
            label: t('Transfer'),
            href: '/import-export',
            iconName: 'icon-tray-arrow-up-24'
        },
        {
            label: t('Import'),
            href: '/rs-import',
            iconName: 'icon-tray-arrow-down-24'
        }
    ]

    return <Sidebar menuItems={menuItems} />
}

export default MenuSider
