import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'

import { useLocalSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const StudentGroupFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const allStudentGroupFilters = schedule.getStudentGroups().map((sg) => ({
        value: sg.getStudentGroupId(),
        text: sg.getDisplayName()
    }))

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allStudentGroupFilters}
            noKeysAvailableDescription={t('NoStudentGroups')}
        />
    )
}
