import { maxSeverity, severityColor } from '../../../utils/problems'

import { ProblemListPopover } from './ProblemListPopover'
import { ProblemListProblem } from './ProblemsList/types'

type ProblemCornerProps = {
    problems: ProblemListProblem[]
    roundedCorner?: boolean
}

export const ProblemCorner = ({ problems, roundedCorner = true }: ProblemCornerProps) => {
    if (problems.length === 0) {
        return null
    }

    return (
        <ProblemListPopover problems={problems}>
            <svg
                viewBox="0 0 10 10"
                style={{
                    zIndex: 4,
                    width: '17%',
                    maxWidth: '1em',
                    minWidth: '1rem',
                    right: 0,
                    top: 0,
                    position: 'absolute',
                    borderTopRightRadius: roundedCorner ? '3px' : '0px'
                }}
            >
                <polygon points="0,0 10,0 10,10" fill={severityColor(maxSeverity(problems))} />
            </svg>
        </ProblemListPopover>
    )
}
