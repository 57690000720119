import classNames from 'classnames'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import classes from '../style.module.css'
import type { DropdownLinkItemProps } from '../types'

const DropdownLinkItem = forwardRef<HTMLAnchorElement, DropdownLinkItemProps>(
    ({ children, selected, destructive, focused, size = 'sm', ...props }, ref) => {
        return (
            <Link
                ref={ref}
                {...props}
                to={props.to}
                className={classNames(classes.dropdown__item, [classes[`dropdown__item--size-${size}`]], {
                    [classes['dropdown__item--selected']]: selected,
                    [classes['dropdown__item--destructive']]: destructive,
                    [classes['dropdown__item--focused']]: focused
                })}
            >
                {children}
            </Link>
        )
    }
)

export default DropdownLinkItem
