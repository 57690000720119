import { FormLayout } from 'antd/es/form/Form'
import { useLayoutEffect, useState } from 'react'

import useResizeObserver from './useResizeObserver'

function useFormProps() {
    const [layout, setLayout] = useState<FormLayout>('vertical')
    const container = useResizeObserver((entry) => {
        setLayout(entry.contentRect.width > 400 ? 'horizontal' : 'vertical')
    })

    // For making the initial choice between horizontal / vertical
    useLayoutEffect(() => {
        if (container.current === null) {
            return
        }

        const { width } = container.current.getBoundingClientRect()
        setLayout(width > 400 ? 'horizontal' : 'vertical')
    }, [container])

    const isHorizontal = layout === 'horizontal'

    return {
        antFormProps: {
            layout,
            style: { width: '100%' },
            labelWrap: true,
            colon: false,
            wrapperCol: isHorizontal ? { span: 16 } : undefined,
            labelCol: isHorizontal ? { span: 8 } : undefined
        },
        buttonProps: {
            wrapperCol: isHorizontal ? { offset: 8 } : undefined
        },
        container
    }
}

export default useFormProps
