import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'

import { FilterableFilterDropdown } from '../../../../components/FilterableFilterDropdown'
import { useSchedule } from '../../../../store/schedule/hooks'

export const RoomAttributeFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()
    const allAttributes = schedule.getRoomAttributes().map((attrib) => ({ value: attrib, text: attrib }))

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allAttributes}
            noKeysAvailableDescription={t('NoRoomAttributes')}
        />
    )
}
