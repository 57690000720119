import { ChecklistStep, IScheduleTransform, ScheduleType } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
    ChecklistStepUiItem,
    isNonVirtualStep,
    VirtualChecklistStep
} from '../../components/schedule-checklist/ScheduleChecklistPanel/types'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { togglePresence } from '../../utils/collections'

import { DataImportHelpPanel } from './ScheduleChecklistPanel/help-panels/DataImportHelpPanel'
import { CourseRoundsImportHelpPanel } from './ScheduleChecklistPanel/help-panels/DataImportHelpPanel/CourseRoundsImportHelpPanel'
import { CoursesImportHelpPanel } from './ScheduleChecklistPanel/help-panels/DataImportHelpPanel/CoursesImportHelpPanel'
import { RoomsImportHelpPanel } from './ScheduleChecklistPanel/help-panels/DataImportHelpPanel/RoomsImportHelpPanel'
import { SubjectsImportHelpPanel } from './ScheduleChecklistPanel/help-panels/DataImportHelpPanel/SubjectsImportHelpPanel'
import { TeacherImportHelpPanel } from './ScheduleChecklistPanel/help-panels/DataImportHelpPanel/TeacherImportHelpPanel'
import { GenerateLecturesHelpPanel } from './ScheduleChecklistPanel/help-panels/GenerateLecturesHelpPanel'
import { PeriodsHelpPanel } from './ScheduleChecklistPanel/help-panels/PeriodsHelpPanel'
import { PublishHelpPanel } from './ScheduleChecklistPanel/help-panels/PublishHelpPanel'
import { ScheduleLecturesHelpPanel } from './ScheduleChecklistPanel/help-panels/ScheduleLecturesHelpPanel'
import { SettingsHelpPanel } from './ScheduleChecklistPanel/help-panels/SettingsHelpPanel'
import { TeacherAssignmentsHelpPanel } from './ScheduleChecklistPanel/help-panels/TeacherAssignmentsHelpPanel'

export const useChecklistUiItems = (onNavigateAway: () => void): ChecklistStepUiItem[] => {
    const schedule = useLocalSchedule()
    const completedSteps = schedule.getChecklistStepsCompleted()
    const { t } = useTranslation()

    const isTeachersImportStarted = schedule.getTeachers().length > 0
    const isRoomsImportStarted = schedule.getRooms().length > 0
    const isSubjectsImportStarted = schedule.getSubjects().length > 0
    const isCoursesImportStarted = schedule.getCourses().length > 0
    const isCourseRoundsImportStarted = schedule.getCourseRounds().length > 0

    const dataImportSubsteps: ChecklistStepUiItem[] = [
        {
            step: ChecklistStep.IMPORT_TEACHERS,
            title: t('Checklist.Staff'),
            isStartDetected: isTeachersImportStarted,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.IMPORT_TEACHERS),
            helpContent: <TeacherImportHelpPanel />
        },
        {
            step: ChecklistStep.IMPORT_ROOMS,
            title: t('Checklist.RoomsAndEquipment'),
            isStartDetected: isRoomsImportStarted,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.IMPORT_ROOMS),
            helpContent: <RoomsImportHelpPanel />
        },
        {
            step: ChecklistStep.IMPORT_SUBJECTS,
            title: t('Checklist.Subjects'),
            isStartDetected: isSubjectsImportStarted,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.IMPORT_SUBJECTS),
            helpContent: <SubjectsImportHelpPanel />
        },
        ...(schedule.getSettings().scheduleType === ScheduleType.GY
            ? [
                  {
                      step: ChecklistStep.IMPORT_COURSES,
                      title: t('Checklist.Courses'),
                      isStartDetected: isCoursesImportStarted,
                      isMarkedAsCompleted: completedSteps.includes(ChecklistStep.IMPORT_COURSES),
                      helpContent: <CoursesImportHelpPanel />
                  }
              ]
            : []),
        {
            step: ChecklistStep.IMPORT_COURSE_ROUNDS,
            title: t('Checklist.CourseOfferings'),
            isStartDetected: isCourseRoundsImportStarted,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.IMPORT_COURSE_ROUNDS),
            helpContent: <CourseRoundsImportHelpPanel />
        }
    ]

    const uiSteps: ChecklistStepUiItem[] = [
        {
            step: ChecklistStep.REVIEW_SETTINGS,
            title: t('Checklist.SelectBaseSettings'),
            desc: t('Checklist.SelectBaseSettingsDesc'),
            isStartDetected: false,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.REVIEW_SETTINGS),
            helpContent: <SettingsHelpPanel onNavigateAway={onNavigateAway} />
        },
        {
            step: ChecklistStep.CREATE_PERIODS,
            title: t('Checklist.AdjustYearDataAndPeriods'),
            desc: t('Checklist.AdjustYearDataAndPeriodsDesc'),
            isStartDetected: false,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.CREATE_PERIODS),
            helpContent: <PeriodsHelpPanel onNavigateAway={onNavigateAway} />
        },
        {
            step: VirtualChecklistStep.IMPORT_DATA,
            title: t('Checklist.ImportData'),
            desc: t('Checklist.ImportDataDesc'),
            helpContent: <DataImportHelpPanel dataImportSubsteps={dataImportSubsteps} />,
            isStartDetected: dataImportSubsteps.some((substep) => substep.isStartDetected),
            isMarkedAsCompleted: dataImportSubsteps.every((substep) => substep.isMarkedAsCompleted),
            substeps: dataImportSubsteps
        },
        {
            step: ChecklistStep.ASSIGN_TEACHERS,
            title: t('Checklist.PerformTeacherAssignments'),
            desc: t('Checklist.PerformTeacherAssignmentsDesc'),
            isStartDetected: schedule.getCourseRounds().some((cr) => cr.getTeachers().length > 0),
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.ASSIGN_TEACHERS),
            helpContent: <TeacherAssignmentsHelpPanel onNavigateAway={onNavigateAway} />
        },
        {
            step: ChecklistStep.GENERATE_LECTURES,
            title: t('Checklist.GenerateLectures'),
            desc: t('Checklist.GenerateLecturesDesc'),
            isStartDetected: schedule.getLectures().length > 0,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.GENERATE_LECTURES),
            helpContent: <GenerateLecturesHelpPanel />
        },
        {
            step: ChecklistStep.SCHEDULE_LECTURES,
            title: t('Checklist.ScheduleLectures'),
            desc: t('Checklist.ScheduleLecturesDesc'),
            isStartDetected: schedule.getEventGroups().some((eg) => eg.getDayAndTime() !== undefined),
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.SCHEDULE_LECTURES),
            helpContent: <ScheduleLecturesHelpPanel onNavigateAway={onNavigateAway} />
        },
        {
            step: ChecklistStep.PUBLISH,
            title: t('Checklist.PublishSchedule'),
            desc: t('Checklist.PublishScheduleDesc'),
            isStartDetected: false,
            isMarkedAsCompleted: completedSteps.includes(ChecklistStep.PUBLISH),
            helpContent: <PublishHelpPanel onNavigateAway={onNavigateAway} />
        }
    ]

    return uiSteps
}

export const useCompletionStateToggler = (step: ChecklistStep | VirtualChecklistStep) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    return () => {
        if (isNonVirtualStep(step)) {
            dispatch(
                locallyTriggeredScheduleTransform(
                    IScheduleTransform.checklistStepsCompleted({
                        newChecklistStepsCompleted: togglePresence(schedule.getChecklistStepsCompleted(), step)
                    })
                )
            )
        }
    }
}
