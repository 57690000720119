import type { IDayAndTime } from 'common-api'

import type {
    CourseRoundAccessor,
    LectureAccessor,
    ReservedTimeAccessor,
    RoomAccessor,
    StudentGroupAccessor,
    TeacherAccessor,
    WeekSelectionAccessor
} from '../../../schedule-access/scheduleAccessWrappers'

type ScheduleEventVisitor<T> = {
    visitLecture(lectureEvent: LectureEvent): T
    visitReservedTime(reservedTimeEvent: ReservedTimeEvent): T
}

export type UnAllocatedEvent = {
    lecture: LectureEvent
    scheduleSelectorIndex: number
}

export abstract class ScheduleEvent {
    abstract isRelatedToStudentGroup(studentGroup: StudentGroupAccessor): boolean
    abstract isRelatedToTeacher(teacher: TeacherAccessor): boolean
    abstract isRelatedToCourseRound(cr: CourseRoundAccessor): boolean
    abstract isRelatedToRoom(room: RoomAccessor): boolean
    abstract getDayAndTime(): IDayAndTime | undefined
    abstract getWeekSelection(): WeekSelectionAccessor
    abstract getDurationInMinutes(): number
    abstract getUniqueId(): string
    abstract accept<T>(visitor: ScheduleEventVisitor<T>): T
}

export const isLectureEvent = (event: ScheduleEvent): event is LectureEvent =>
    event.accept({
        visitLecture: () => true,
        visitReservedTime: () => false
    })

export class LectureEvent extends ScheduleEvent {
    constructor(private readonly lecture: LectureAccessor) {
        super()
    }

    getWeekSelection() {
        return this.lecture.getWeekSelection()
    }

    isRelatedToStudentGroup(studentGroup: StudentGroupAccessor): boolean {
        return this.lecture.getCourseRound().getStudentGroup().doesOverlapWith(studentGroup)
    }

    isRelatedToTeacher(teacher: TeacherAccessor): boolean {
        return this.lecture.getEffectiveTeachers().some((t) => t.getTeacherId() === teacher.getTeacherId())
    }

    isRelatedToRoom(room: RoomAccessor): boolean {
        return this.lecture.getAssignedRooms().some((r) => r.getRoomId() === room.getRoomId())
    }

    isRelatedToCourseRound(cr: CourseRoundAccessor): boolean {
        return this.lecture.getCourseRound().getCourseRoundId() === cr.getCourseRoundId()
    }

    getDayAndTime() {
        return this.lecture.getDayAndTime()
    }

    getDurationInMinutes(): number {
        return this.lecture.getDurationInMinutes()
    }

    getUniqueId(): string {
        return `lecture-${this.lecture.getLectureId()}`
    }

    getLecture(): LectureAccessor {
        return this.lecture
    }

    accept<T>(visitor: ScheduleEventVisitor<T>): T {
        return visitor.visitLecture(this)
    }
}

export class ReservedTimeEvent extends ScheduleEvent {
    constructor(private readonly reservedTime: ReservedTimeAccessor) {
        super()
    }

    getWeekSelection() {
        return this.reservedTime.getWeekSelection()
    }

    isRelatedToStudentGroup(studentGroup: StudentGroupAccessor): boolean {
        return this.reservedTime.getStudentGroups().some((sg) => sg.doesOverlapWith(studentGroup))
    }

    isRelatedToTeacher(teacher: TeacherAccessor): boolean {
        return this.reservedTime.getTeachers().some((t) => t.getTeacherId() === teacher.getTeacherId())
    }

    isRelatedToCourseRound(cr: CourseRoundAccessor): boolean {
        return false // TODO?
    }

    isRelatedToRoom(room: RoomAccessor): boolean {
        return this.reservedTime
            .getRooms()
            .map((r) => r.getRoomId())
            .includes(room.getRoomId())
    }

    getDayAndTime(): IDayAndTime {
        return this.reservedTime.getDayAndTime()
    }

    getDurationInMinutes(): number {
        return this.reservedTime.getDurationInMinutes()
    }

    getUniqueId(): string {
        return `reserved-time-${this.reservedTime.getReservedTimeId()}`
    }

    getReservedTime(): ReservedTimeAccessor {
        return this.reservedTime
    }

    accept<T>(visitor: ScheduleEventVisitor<T>): T {
        return visitor.visitReservedTime(this)
    }
}
