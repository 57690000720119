import classNames from 'classnames'
import { NavLink as RouterNavLink } from 'react-router-dom'

import type { NavLinkProps } from './types'

function NavLink({ children, to, replace, className, ...props }: NavLinkProps) {
    return (
        <RouterNavLink
            {...props}
            to={to}
            replace={replace}
            className={({ isActive }) =>
                classNames(className, {
                    active: isActive
                })
            }
        >
            {children}
        </RouterNavLink>
    )
}

export default NavLink
