import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'

import type * as authActions from './actions'
import { AuthActionTypes } from './types'

export const initLogin = () => action(AuthActionTypes.INIT_LOGIN)
export const userAuthenticated = (activeOrgId: string) => action(AuthActionTypes.USER_AUTHENTICATED, { activeOrgId })
export const userAuthenticatedWithoutOrganization = () =>
    action(AuthActionTypes.USER_AUTHENTICATED_WITHOUT_ORGANIZATION)
export const userAuthenticatedWithoutSchedulerRole = () =>
    action(AuthActionTypes.USER_AUTHENTICATED_WITHOUT_SCHEDULER_ROLE)
export const unauthorizedApiRequest = () => action(AuthActionTypes.UNAUTHORIZED_API_REQUEST)

export type AuthAction = ActionType<typeof authActions>
