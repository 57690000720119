import { PlusOutlined } from '@ant-design/icons'
import { Input, Modal, Space } from 'antd'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

type AddPeriodDialogProps = {
    onNewPeriod: (periodName: string) => void
}

const AddPeriodDialog = (props: AddPeriodDialogProps) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [periodName, setPeriodName] = useState('')
    const { t } = useTranslation()

    const onOk = () => {
        setDialogOpen(false)
        props.onNewPeriod(periodName.trim())
        setPeriodName('')
    }

    const onCancel = () => {
        setDialogOpen(false)
        setPeriodName('')
    }

    return (
        <>
            <Button size="sm" variant="primary" startIcon={<PlusOutlined />} onClick={() => setDialogOpen(true)}>
                {t('AddPeriod')}
            </Button>
            <Modal
                open={dialogOpen}
                closable={false}
                onCancel={() => setDialogOpen(false)}
                footer={
                    <Space>
                        <Button onClick={onCancel} variant="secondary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={onOk} variant="primary" disabled={periodName.trim().length === 0}>
                            {t('Ok')}
                        </Button>
                    </Space>
                }
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: '.5em',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ flexShrink: 0 }}>{t('PeriodName')}</div>
                    <Input
                        style={{ flexGrow: 1 }}
                        value={periodName}
                        onChange={(event) => setPeriodName(event.target.value)}
                    />
                </div>
            </Modal>
        </>
    )
}

export default memo(AddPeriodDialog)
