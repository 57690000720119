import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useColumnWidth } from '../../../../EditTable/hooks'
import { RoomAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { useLocalSchedule } from '../../../../store/schedule/hooks'

import {
    ALL_CONTENT_COLUMNS,
    RoomGridColumnSpec,
    RoomGridContentColumn,
    RoomGridMetaDataColumn,
    RoomGridRow
} from './types'
import { sortedRoomsFromSchedule } from './utils'


const useSortedRoomsFromSchedule = () => {
    const schedule = useLocalSchedule()

    return sortedRoomsFromSchedule(schedule)
}

const roomToRoomRow = (room: RoomAccessor, rowIndex: number): RoomGridRow => ({
    [RoomGridMetaDataColumn.RowIndex]: rowIndex,
    [RoomGridContentColumn.Name]: room.getName(),
    [RoomGridContentColumn.Attributes]: room.getAttributes().join(', ')
})

export const useLiveRoomTableData = () => {
    const rooms = useSortedRoomsFromSchedule()

    return rooms.map((room, index) => roomToRoomRow(room, index))
}

export const useRoomTableDataState = () => {
    const roomTableData = useLiveRoomTableData()
    const [roomGridData, setRooms] = useState<RoomGridRow[]>(roomTableData)
    const resetRooms = () => {
        setRooms(roomTableData)
    }

    return { roomGridData, setRooms, resetRooms }
}

export const useRoomGridColumns = (target: RefObject<HTMLDivElement> | null): RoomGridColumnSpec[] => {
    const { t } = useTranslation()
    const columnWidth = useColumnWidth(target, ALL_CONTENT_COLUMNS.length)

    return [
        {
            title: '',
            gridColumn: RoomGridMetaDataColumn.RowIndex,
            colIndex: 0,
            width: 60,
            minWidth: 60
        },
        {
            title: t('Name'),
            gridColumn: RoomGridContentColumn.Name,
            colIndex: 1,
            width: columnWidth,
            minWidth: columnWidth
        },
        {
            title: t('Attribute'),
            gridColumn: RoomGridContentColumn.Attributes,
            colIndex: 2,
            width: columnWidth,
            minWidth: columnWidth
        }
    ]
}
