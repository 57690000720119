import { CloseOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CourseRoundDetails } from '../../components/course-rounds/CourseRoundDetails'
import IconButton from '../../components/IconButton'
import { CourseRoundAccessor } from '../../schedule-access/scheduleAccessWrappers'
import TypeScale from '../../styles/TypeScale'

import classes from './style.module.css'

type SelectedCourseRoundPanelProps = {
    selectedCourseRounds: CourseRoundAccessor[]
    onRemove?: () => void
}

function SelectedCourseRoundPanel({ selectedCourseRounds, onRemove }: SelectedCourseRoundPanelProps) {
    const [expanded, setExpanded] = useState<string[]>(['1', '2'])
    const { t } = useTranslation()
    if (selectedCourseRounds.length === 0) {
        return null
    }

    return (
        <Collapse
            defaultActiveKey={expanded}
            style={{ height: '100%', flexDirection: 'column', overflow: 'auto', borderRadius: 0, padding: 16 }}
            onChange={(keys) => {
                if (Array.isArray(keys)) {
                    setExpanded(keys)
                }
            }}
        >
            <div className={classes.panelSlider__header}>
                <h2 className={TypeScale.Heading_MD}>{t('EditCourseRound')}</h2>
                <IconButton aria-label="close" pill variant="tertiary" onClick={onRemove}>
                    <CloseOutlined />
                </IconButton>
            </div>
            <CourseRoundDetails
                key={selectedCourseRounds.map((cr) => cr.getCourseRoundId()).join(',')}
                courseRounds={selectedCourseRounds}
            />
        </Collapse>
    )
}

export default SelectedCourseRoundPanel
