import { Switch, Typography } from 'antd'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './style.module.css'
import { TableEditModeSwitchProps } from './types'
import { useEditModeSwitch } from './useEditModeSwitch'

export const TableEditModeSwitch = ({ disabled = false, onChange }: TableEditModeSwitchProps) => {
    const { isEditModeToggled, toggleEditMode } = useEditModeSwitch()
    const id = useId()
    const { t } = useTranslation()

    return (
        <div className={classes.modeSwitch}>
            <Switch
                id={id}
                value={isEditModeToggled}
                disabled={disabled}
                onChange={(isChecked) => {
                    onChange()
                    toggleEditMode(isChecked)
                }}
            />
            <label htmlFor={id}>
                <Typography.Text strong={false}>{t('TableEditing')}</Typography.Text>
            </label>
        </div>
    )
}
