import { useTranslation } from 'react-i18next'

import type { ReservedTimeId, TeacherId } from '../../../commonTypes'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { ManyToManySelector } from '../../many-to-many-selector/ManyToManySelector'
import { ReservedTimesListTooltip } from '../ReservedTimesListTooltip'

import type { ReservedTimeTeacherSelectorProps } from './types'

export const ReservedTimeTeacherSelector = ({ value, onChange }: ReservedTimeTeacherSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const schoolIdsByTeacherId = new Map(schedule.getTeachers().map((t) => [t.getTeacherId(), t.getTeacherSchoolId()]))

    return (
        <ManyToManySelector<ReservedTimeId, TeacherId>
            value={value}
            onChange={onChange}
            mode="multiple"
            preexistingOptionValues={schedule.getTeachers().map((t) => t.getTeacherId())}
            optionLabelFn={(tid) => schoolIdsByTeacherId.get(tid)!}
            partialCoverSuffix={(reservedTimeIds) => (
                <ReservedTimesListTooltip
                    title={t('TeacherAppliesOnlyToTheseReservedTimes')}
                    reservedTimes={reservedTimeIds.map((reservedTimeId) => schedule.findReservedTime(reservedTimeId))}
                />
            )}
        />
    )
}
