import { Flex } from 'antd'

import { ToggleExplicitlyDoneButton } from '../../../ToggleExplicitlyDoneButton'

import { InlineToggleExplicitlyDoneButtonProps } from './types'

export const InlineToggleExplicitlyDoneButton = (props: InlineToggleExplicitlyDoneButtonProps) => (
    <Flex justify="space-around" style={{ margin: '2em' }}>
        <ToggleExplicitlyDoneButton stepUiItem={props.stepUiItem} />
    </Flex>
)
