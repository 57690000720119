import { ICourse } from 'common-api'

import { CourseAccessor, ScheduleAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { combinedCmpFn, comparing } from '../../../../../utils/compareUtil'

import { CourseGridContentColumn, CourseGridMetaDataColumn, CourseGridRow } from './types'

export const sortedCoursesFromSchedule = (schedule: ScheduleAccessor) => {
    return schedule.getCourses().toSorted(combinedCmpFn(comparing((r) => r.getName())))
}

export const conjureCourseFromCourses = (courses: CourseAccessor[]): ICourse[] => {
    return courses.map((course) => course.getConjureObject())
}

export const isEmptyCoursesRow = (courseRow: CourseGridRow) =>
    [CourseGridContentColumn.Name, CourseGridContentColumn.Code, CourseGridContentColumn.Subject].every(
        (col) => courseRow[col].trim() === ''
    )

export const generateEmptyCourseRow = (rowIndex: number): CourseGridRow => {
    return {
        [CourseGridMetaDataColumn.RowIndex]: rowIndex,
        [CourseGridContentColumn.Name]: '',
        [CourseGridContentColumn.Code]: '',
        [CourseGridContentColumn.Subject]: ''
    }
}
