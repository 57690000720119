import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import type { CourseId } from '../../commonTypes'
import { CourseDetails } from '../../components/courses/CourseDetails'
import TablePage from '../../components/PageContent'
import { useLocalSchedule } from '../../store/schedule/hooks'

import { CourseActionsPanel } from './components/CourseActionsPanel'
import { CoursesTable } from './components/CoursesTable'

type LocationState = { selectedCourseIds?: CourseId[] } | undefined

const CoursesIndexPage = () => {
    const schedule = useLocalSchedule()
    const locationState = useLocation().state as LocationState
    const { t } = useTranslation()
    const [selectedRowKeys, setSelectedRowKeys] = useState<CourseId[]>(locationState?.selectedCourseIds ?? [])

    // Some selected courses may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((selectedCourseId) =>
        schedule.doesCourseIdExist(selectedCourseId)
    )

    const selectedCourses = validSelectedRowKeys.map((courseId) => schedule.findCourse(courseId))

    const detailsPanel = (
        <CourseDetails key={schedule.getVersion() + validSelectedRowKeys.join(',')} courses={selectedCourses} />
    )

    const actionsPanel = (
        <CourseActionsPanel
            courses={selectedCourses}
            onDelete={() => {
                setSelectedRowKeys([])
            }}
        />
    )

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => {
                setSelectedRowKeys([])
            }}
            table={<CoursesTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
        />
    )
}

export default CoursesIndexPage
