import { UndoOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

import type { ResetTableContentButtonProps } from './types'

export const ResetTableContentButton = ({ onReset }: ResetTableContentButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button icon={<UndoOutlined />} onClick={onReset}>
            {t('ResetContent')}
        </Button>
    )
}
