import { Cell, CellsLookup, Column, NumericalRange, ReactGrid, Row } from '@silevis/reactgrid'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { ClipboardEvent } from 'react'

import { AddMoreRowsPanel } from '../pages/teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/AddMoreRowsPanel'

import { ScrollGradientMaskWrapper } from './components/ScrollGradientMaskWrapper'
import { gridStyles } from './constants'
import classes from './style.module.css'

type EditTableProps = {
    cells: Cell[]
    columns: Column[] | undefined
    onAddMoreRows: (numRows: number) => void
    onCellFocused?: ((cellLocation: IndexedLocation) => void) | undefined
    onPaste: (e: ClipboardEvent<HTMLDivElement>) => void
    rows: Row[] | undefined
    onCopy: (event: ClipboardEvent<HTMLDivElement>, cellsRange: NumericalRange, cellsLookup: CellsLookup) => void
}

export default function EditTable({
    onAddMoreRows,
    onCellFocused,
    rows,
    cells,
    columns,
    onPaste,
    onCopy
}: EditTableProps) {
    return (
        <ScrollGradientMaskWrapper className={classes.reactGridWrapper} onPaste={onPaste}>
            <ReactGrid
                columns={columns}
                rows={rows}
                cells={cells}
                styles={gridStyles}
                onCopy={onCopy}
                onCellFocused={onCellFocused}
                enableRowSelectionOnFirstColumn
                enableColumnSelectionOnFirstRow
            />
            <AddMoreRowsPanel onAddMoreRows={onAddMoreRows} />
        </ScrollGradientMaskWrapper>
    )
}
