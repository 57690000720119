import { InputNumber } from 'antd'

import { AppError } from '../../utils/errorutil'

type BulkInputNumberProps = {
    value?: string[]
    onChange?: (newValue: (string | number)[]) => void
    multiplicityMessage: string
}

const BulkInputNumber = (props: BulkInputNumberProps) => {
    const onChangeWrapper = (newValues: (string | number)[]) => {
        if (typeof props.onChange === 'function') {
            props.onChange(newValues)
        }
    }

    if (props.value === undefined || props.value.length === 0) {
        throw new AppError('Expected at least one value to edit.')
    }

    if (props.value.length === 1) {
        return <InputNumber value={props.value[0]} onChange={(newValue) => onChangeWrapper([newValue || 0])} />
    }

    return <div style={{ paddingTop: '.3em', fontStyle: 'italic' }}>{props.multiplicityMessage}</div>
}

export default BulkInputNumber
