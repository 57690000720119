import { Button, Flex } from 'antd'
import { useTranslation } from 'react-i18next'

import { ValueSelectionCheckbox } from '../ValueSelectionCheckbox'

import type { FilterableFilterDropdownProps } from './types'

export const EnumFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters = () => {},
    allFilters
}: FilterableFilterDropdownProps) => {
    const { t } = useTranslation()

    const handleReset = () => {
        clearFilters()
        handleOk()
    }

    const handleOk = () => {
        confirm({ closeDropdown: true })
    }

    return (
        <Flex vertical gap={8} style={{ padding: 8 }}>
            <Flex vertical style={{ maxHeight: '75vh', overflow: 'scroll' }} gap={3} align="stretch">
                {allFilters.map((key) => (
                    <ValueSelectionCheckbox
                        key={key.value}
                        value={key.value}
                        selectedValues={selectedKeys}
                        setSelectedValues={setSelectedKeys}
                    >
                        {key.text}
                    </ValueSelectionCheckbox>
                ))}
            </Flex>
            <Flex gap={8}>
                <Button onClick={handleReset}>{t('ResetContent')}</Button>
                <Button onClick={handleOk} type="primary">
                    {t('Ok')}
                </Button>
            </Flex>
        </Flex>
    )
}
