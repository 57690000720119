import { message } from 'antd'
import { ILecture, IReservedTime, IScheduleTransform } from 'common-api'
import { without } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TeacherId } from '../../../../../commonTypes'
import Button from '../../../../../components/Button'
import type { TeacherAccessor } from '../../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import { hasNonEmptyIntersection } from '../../../../schedule/components/Schedule/utils'

import type { DeleteTeachersButtonProps } from './types'

const lectureWithoutTeachers = (l: ILecture, teacherIds: TeacherId[]) => ({
    ...l,
    teacherIds: l.teacherIds === undefined ? undefined : without(l.teacherIds, ...teacherIds)
})

const reservedTimeWithoutTeacher = (rt: IReservedTime, teacherIds: TeacherId[]) => ({
    ...rt,
    teacherIds: without(rt.teacherIds, ...teacherIds)
})

export const DeleteTeachersButton = ({ teacherIds, onDelete }: DeleteTeachersButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const teachers = teacherIds.map((tid) => schedule.findTeacher(tid))

    const getCourseRoundsReferencingTeacher = (t: TeacherAccessor) =>
        schedule
            .getCourseRounds()
            .filter((cr) => cr.getTeachers().findIndex((t2) => t.getTeacherId() === t2.getTeacherId()) !== -1)

    // Remove references to teachers from lectures
    const removeTeachersFromLectures = schedule
        .getLectures()
        .map((l) => l.getConjureLecture())
        .filter((l) => hasNonEmptyIntersection(l.teacherIds ?? [], teacherIds))
        .map((l) => IScheduleTransform.lectureTransform({ newLecture: lectureWithoutTeachers(l, teacherIds) }))

    // Remove references to teachers from reserved times
    const removeTeachersFromReservedTimes = schedule
        .getReservedTimes()
        .map((rt) => rt.getConjureObject())
        .filter((rt) => hasNonEmptyIntersection(rt.teacherIds, teacherIds))
        .map((rt) =>
            IScheduleTransform.reservedTimeTransform({
                newReservedTime: reservedTimeWithoutTeacher(rt, teacherIds)
            })
        )

    // Remove actual teachers
    const removeTeachers = teacherIds.map((teacherId) => IScheduleTransform.teacherDeleteTransform({ teacherId }))

    const handleDeleteTeacher = () => {
        const problematicCrs = teachers.flatMap(getCourseRoundsReferencingTeacher)
        if (problematicCrs.length > 0) {
            message.error(t('Errors.TeacherReferencedInDistribution', { name: problematicCrs[0].getDisplayName() }))

            return
        }

        onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform([
                    ...removeTeachersFromLectures,
                    ...removeTeachersFromReservedTimes,
                    ...removeTeachers
                ])
            )
        )
    }

    return (
        <Button variant="primary" destructive onClick={handleDeleteTeacher}>
            {t('RemoveTeacher')}
        </Button>
    )
}
