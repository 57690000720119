import { Cell, NonEditableCell } from '@silevis/reactgrid'
import { merge, range } from 'lodash'
import { Dispatch, SetStateAction } from 'react'

import { fullJoin } from '../pages/import-export/export-util'
import { CmpFn } from '../utils/compareUtil'

import { MIN_ROWS_TO_SHOW } from './constants'

export const DEFAULT_HEADER_CELL = {
    Template: NonEditableCell,
    isFocusable: false,
    isSelectable: false,
    props: {
        style: {
            backgroundColor: '#f5f5f5',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold'
        }
    }
}

export const generateEmptyRows = <T extends Record<number, string>>(
    fromRowIndex: number,
    rowsToAdd: number,
    generateEmptyRow: (rowIndex: number) => T
) => range(fromRowIndex, fromRowIndex + rowsToAdd).map(generateEmptyRow)

type DefaultCellOptions = {
    title: string
    colIndex: number
    rowIndex: number
    justify?: 'center' | 'start'
}

export const getDefaultCell = ({ title, colIndex, rowIndex, justify = 'start' }: DefaultCellOptions) => {
    return merge({}, DEFAULT_HEADER_CELL, {
        rowIndex,
        colIndex,
        props: {
            value: title,
            style: {
                justifyContent: justify
            }
        }
    })
}

type ClearTableContentOptions<T extends Record<number, string>> = {
    rows: T[]
    generateEmptyRow: (rowIndex: number) => T
    setData: Dispatch<SetStateAction<T[]>>
}

export const handleClearTableContent = <T extends Record<number, string>>({
    rows,
    generateEmptyRow,
    setData
}: ClearTableContentOptions<T>) => {
    const toIndex = Math.max(rows.length, MIN_ROWS_TO_SHOW)
    const withEmptyRows = generateEmptyRows(0, toIndex, generateEmptyRow)
    setData(withEmptyRows)
}

export type DiffResult<T> = {
    created: T[]
    updated: [T, T][]
    deleted: T[]
}

export const diff = <T>(orig: T[], niew: T[], idFn: (e: T) => string, cmpFn: CmpFn<T>): DiffResult<T> => {
    const created = []
    const updated: [T, T][] = []
    const deleted = []

    for (const [origE, newE] of fullJoin(orig, idFn, niew, idFn)) {
        if (origE === undefined && newE !== undefined) {
            created.push(newE)
        } else if (origE !== undefined && newE === undefined) {
            deleted.push(origE)
        } else if (origE !== undefined && newE !== undefined && cmpFn(origE, newE) !== 0) {
            updated.push([origE, newE])
        }
    }

    return { created, updated, deleted }
}

export const anyDiff = <T>(diffResult: DiffResult<T>) => {
    return diffResult.deleted.length > 0 || diffResult.created.length > 0 || diffResult.updated.length > 0
}

export const getIndexCell = (rowIndex: number): Cell => {
    return merge({}, DEFAULT_HEADER_CELL, {
        rowIndex,
        colIndex: 0,
        props: {
            value: `${rowIndex}`,
            style: {
                justifyContent: 'center'
            }
        }
    })
}
