import { Modal } from 'antd'
import { IScheduleTransform, IStudentGroup } from 'common-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import BulkUpdatePreview from '../../../../../EditTable/components/BulkUpdatePreview'
import { diff } from '../../../../../EditTable/utils'
import { locallyTriggeredScheduleTransform } from '../../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../../store/schedule/hooks'
import StudentGroupEditGrid from './StudentGroupsEditGrid'
import StudentGroupListDiff from './components/StudentGroupListDiff'
import { parseStudentGroupsRows, studentGroupCmp } from './data'
import { useStudentGroupsTableDataState } from './hooks'
import { conjureStudentGroupFromStudentGroups, sortedStudentGroupsFromSchedule } from './utils'
import { globalValidationErrors } from './validation'

const StudentGroupsEditTable = () => {
    const { t } = useTranslation()

    const schedule = useLocalSchedule()
    const liveStudentGroups = conjureStudentGroupFromStudentGroups(sortedStudentGroupsFromSchedule(schedule))
    const [studentGroupsWhenEditStarted, setStudentGroupsWhenEditStarted] = useState(liveStudentGroups)

    const { studentGroupsGridData, setStudentGroupsGridData, resetStudentGroups } = useStudentGroupsTableDataState()
    const dispatch = useDispatch()

    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const onSave = () => {
        const parsedStudentGroups = parseStudentGroupsRows(liveStudentGroups, studentGroupsGridData)

        const diffToSave = diff(
            liveStudentGroups,
            parsedStudentGroups,
            (studentGroup) => studentGroup.displayName,
            studentGroupCmp
        )

        const studentGroupTransforms = [
            ...diffToSave.deleted.map((studentGroup) =>
                IScheduleTransform.studentGroupDeleteTransform({ studentGroupId: studentGroup.studentGroupId })
            ),
            ...diffToSave.updated.map(([_, newStudentGroup]) =>
                IScheduleTransform.studentGroupTransform({ newStudentGroup })
            ),
            ...diffToSave.created.map((studentGroupToCreate) =>
                IScheduleTransform.studentGroupTransform({ newStudentGroup: studentGroupToCreate })
            )
        ]

        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(studentGroupTransforms)))
        setIsPreviewOpen(false)

        setStudentGroupsWhenEditStarted(parsedStudentGroups)
    }

    const newStudentGroups = parseStudentGroupsRows(liveStudentGroups, studentGroupsGridData)
    const diffPreview = diff(
        liveStudentGroups,
        newStudentGroups,
        (studentGroup) => studentGroup.displayName,
        studentGroupCmp
    )

    const validationErrors = globalValidationErrors(schedule, studentGroupsGridData, liveStudentGroups)

    return (
        <>
            <Modal
                open={isPreviewOpen}
                closeIcon={false}
                style={{ maxWidth: 'min(80em, calc(100% - 32px))' }}
                destroyOnClose
                width="auto"
                okText={t('Save')}
                onOk={onSave}
                cancelText={t('Cancel')}
                onCancel={() => {
                    setIsPreviewOpen(false)
                }}
                okButtonProps={{
                    disabled: validationErrors.length > 0
                }}
            >
                <BulkUpdatePreview<IStudentGroup>
                    diff={diffPreview}
                    validationErrors={validationErrors}
                    DiffComponent={StudentGroupListDiff}
                />
            </Modal>
            <StudentGroupEditGrid
                studentGroups={studentGroupsGridData}
                setStudentGroups={setStudentGroupsGridData}
                onReset={resetStudentGroups}
                onSave={() => {
                    setIsPreviewOpen(true)
                }}
                studentGroupsWhenEditingStarted={studentGroupsWhenEditStarted}
            />
        </>
    )
}

export default StudentGroupsEditTable
