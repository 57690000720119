import type { IRoom } from 'common-api'
import type { IScheduleServiceRoom } from 'meitner-api'

import { Endpoints } from '../../services/Endpoints'

export const listDstRooms = async (targetScheduleId: string) =>
    Endpoints.meitnerApi
        .scheduleServiceListRooms({
            schedule_id: targetScheduleId,
            pagination: { page: 0, size: 0 }
        })
        .then((response) => response.schedule_rooms)

export const createDstRoom = async (targetScheduleId: string, srcRoom: IRoom) =>
    Endpoints.meitnerApi
        .scheduleServiceCreateRoom({
            id: null,
            schedule_id: targetScheduleId,
            title: srcRoom.name,
            description: '',
            external_id: srcRoom.roomId,
            meitner_id: null
        })
        .then((response) => response.created.id!)

export const deleteDstRoom = async (roomId: string) =>
    Endpoints.meitnerApi.scheduleServiceDeleteRoom({
        id: roomId
    })

export const updateDstRoom = async (srcRoom: IRoom, dstRoom: IScheduleServiceRoom) =>
    Endpoints.meitnerApi
        .scheduleServiceUpdateRoom({
            ...dstRoom,
            title: srcRoom.name
        })
        .then((response) => response.updated.id!)
